export default () => ({
  triggerSendings: [],

  triggerSending: {
    id: null,
    name: '',
    recipientRoles: [],
    customFields: {},
    sendingDelay: null,
    school: null,
    status: null,
    trigger: null,
    templates: [],
  },

  triggerDetail: null,

  options: {
    channels: [],
    recipientRoles: [],
    schools: [],
    triggers: [],
    triggerStatuses: [],
  },

  pagination: null,
  filters: null,
})
