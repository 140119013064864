import { ApiOnlineSchoolOrderClientDocument } from 'ApiRest/Api/OnlineSchool/Orders/ClientDocument'
import { ApiOnlineSchoolGenerateInvoice } from 'ApiRest/Api/OnlineSchool/Orders/GenerateInvoice'
import { ApiOnlineSchoolOrderInvoices } from 'ApiRest/Api/OnlineSchool/Orders/Invoices'
import { ApiOnlineSchoolOrderLogs } from 'ApiRest/Api/OnlineSchool/Orders/Logs'
import { ApiOnlineSchoolOrderStatusOptions } from 'ApiRest/Api/OnlineSchool/Orders/Option/OrderStatusOptions'
import { ApiOnlineSchoolProductsOptions } from 'ApiRest/Api/OnlineSchool/Orders/Option/Products'
import { ApiOnlineSchoolOrder } from 'ApiRest/Api/OnlineSchool/Orders/Order'
import { ApiOnlineSchoolProducts } from 'ApiRest/Api/OnlineSchool/Orders/Products'

export default {
  async fetchOrder({ state, commit }) {
    const { data } = await ApiOnlineSchoolOrder.get(state.orderId)

    commit('setOrder', data)
  },

  async updateOrder({ commit, state }) {
    const { data } = await ApiOnlineSchoolOrder.patch(
      state.orderId,
      state.order,
    )

    commit('setOrder', data)
  },

  async fetchOrderStatusOptions({ commit }) {
    const { data } = await ApiOnlineSchoolOrderStatusOptions.get()

    commit('setOptionsList', {
      path: 'status',
      value: data,
    })
  },

  async fetchProductOptions({ state, getters, commit }) {
    const parameters = {
      grade: {
        id: state.order.grade?.id,
      },
      client: {
        id: state.order.customer.id,
      },
      student: {
        id: getters.consumer.id,
      },
    }

    const { data } = await ApiOnlineSchoolProductsOptions.get(parameters)

    commit('setOptionsList', {
      path: 'products',
      value: data,
    })
  },

  removeClientDocument(context, { orderId, fileId }) {
    return ApiOnlineSchoolOrderClientDocument.delete({
      orderId,
      fileId,
    })
  },

  async fetchProducts({ state, commit }) {
    const { data } = await ApiOnlineSchoolProducts.get(state.orderId)

    commit('setProducts', data)
  },

  async fetchInvoices({ state, commit }) {
    const { data } = await ApiOnlineSchoolOrderInvoices.get(state.orderId)

    commit('setInvoices', data)
  },

  async fetchLogs({ state, commit }) {
    const { data } = await ApiOnlineSchoolOrderLogs.get(state.orderId)

    commit('setLogs', data)
  },

  saveProducts({ state }) {
    const items = {
      items: state.products,
    }

    return ApiOnlineSchoolOrder.put(state.orderId, items)
  },

  generateInvoice({ state }) {
    return ApiOnlineSchoolGenerateInvoice.post(state.orderId)
  },
}
