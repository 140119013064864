export default () => ({
  sendings: [],
  sending: {
    id: null,
    name: '',
    mailBody: '',
    mailSubject: '',
    saveTemplate: false,
    sendingDate: '',
    status: null,
    senderEmail: '',
    senderName: '',
    users: [],
    filters: null,
    template: null,
  },
  analytics: {
    actualDateTime: '',
    sent: 0,
    delivered: 0,
    opened: 0,
    error: 0,
    cancelSubscription: 0,
  },
  isFormListNow: false,
  currentUsers: [],
  finalUsers: [],
  options: {},
  pagination: null,
  filters: null,
})
