import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url = `/user/student/extramural-ctp-statement/statement?${urlParams}`

    return this._GET(url)
  }

  downloadCertification(parameters = {}) {
    return this._GET(
      `/user/student/extramural-ctp-statement/download-cert?${UrlHelper.param(
        parameters,
      )}`,
      {
        responseType: 'blob',
      },
    )
  }
})()
