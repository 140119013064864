import ApiBase from 'ApiRest/ApiBase'

export const ApiSendingAnalytics = new (class extends ApiBase {
  /**
   * @param {number} sendingId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(sendingId, parameters = {}) {
    return this._GET(
      `/sending/${sendingId}/analytics?${this.getUrlParams(parameters)}`,
    )
  }
})()
