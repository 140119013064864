import ApiBase from 'ApiRest/ApiBase'

export const ApiAnalyticsOnlineLessonJournal = new (class extends ApiBase {
  /**
   * Скачивание журнала ДО
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const url = `/analytics/online-lesson/journal?${this.getUrlParams(params)}`

    return this._GET(url, {
      responseType: 'blob',
    })
  }
})()
