import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscriptionOptionsDaysBeforeBlock =
  new (class extends ApiBase {
    /**
     * Получаем список интервалов для оплаты счета
     * @returns {Promise}
     */
    get() {
      return this._GET('/subscription/options/days-before-block')
    }
  })()
