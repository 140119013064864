import triggerSendings from '@/routes/settings/triggerSendings'

const routes = [
  {
    path: '/settings/schools',
    name: 'admin-settings-schools',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () => import('@/pages/settings/Schools.vue'),
  },
  {
    path: '/settings/school/create',
    name: 'admin-settings-the-school-create',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () => import('@/pages/settings/TheSchool.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/school/:schoolId(\\d+)',
    name: 'admin-settings-the-school',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () => import('@/pages/settings/TheSchool.vue'),
  },
  {
    path: '/settings/school/:schoolId(\\d+)/legal-entity/create',
    name: 'admin-settings-the-legal-entity-create',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () => import('@/pages/settings/TheLegalEntity.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/school/:schoolId(\\d+)/legal-entity/:legalEntityId(\\d+)',
    name: 'admin-settings-the-legal-entity',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () => import('@/pages/settings/TheLegalEntity.vue'),
  },
  {
    path: '/settings/school/:schoolId(\\d+)/income-diagnostics-setting',
    name: 'admin-income-diagnostics-setting',
    meta: {
      accessControlGroup: 'settings-school',
    },
    component: () =>
      import('@/pages/incomeDiagnostics/TheIncomeDiagnosticsSetting.vue'),
  },

  {
    path: '/settings/family-education/schools',
    name: 'admin-family-educations',
    meta: {
      accessControlGroup: 'settings-family-education',
    },
    component: () => import('@/pages/settings/FamilyEducations.vue'),
  },
  {
    path: '/settings/family-education/school/:schoolId(\\d+)',
    name: 'admin-settings-the-family-education',
    meta: {
      accessControlGroup: 'settings-family-education',
    },
    component: () => import('@/pages/settings/TheFamilyEducation.vue'),
  },

  {
    path: '/settings/academic-years/schools',
    name: 'admin-settings-academic-years',
    meta: {
      accessControlGroup: 'settings-academic-year',
    },
    component: () => import('@/pages/settings/AcademicYears.vue'),
  },
  {
    path: '/settings/academic-years/schools/:schoolId(\\d+)',
    name: 'admin-settings-the-academic-year',
    meta: {
      accessControlGroup: 'settings-academic-year',
    },
    component: () => import('@/pages/settings/TheAcademicYear.vue'),
  },

  {
    path: '/settings/timetable/',
    name: 'admin-settings-timetables',
    meta: {
      accessControlGroup: 'settings-timetables',
    },
    component: () => import('@/pages/settings/Timetables.vue'),
  },
  {
    path: '/settings/timetable/:timetableId(\\d+)',
    name: 'admin-settings-the-timetable',
    meta: {
      accessControlGroup: 'settings-timetables',
    },
    component: () => import('@/pages/settings/TheTimetable.vue'),
  },
  {
    path: '/settings/timetable/create',
    name: 'admin-settings-the-timetable-create',
    meta: {
      accessControlGroup: 'settings-timetables',
    },
    component: () => import('@/pages/settings/TheTimetable.vue'),
    props: {
      isCreate: true,
    },
  },

  {
    path: '/settings/holidays',
    name: 'admin-settings-holidays',
    meta: {
      accessControlGroup: 'settings-holiday',
    },
    component: () => import('@/pages/settings/Holidays.vue'),
  },
  {
    path: '/settings/holiday/create',
    name: 'admin-settings-the-holiday-create',
    meta: {
      accessControlGroup: 'settings-holiday',
    },
    component: () => import('@/pages/settings/TheHoliday.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/holiday/:holidayId(\\d+)',
    name: 'admin-settings-the-holiday',
    meta: {
      accessControlGroup: 'settings-holiday',
    },
    component: () => import('@/pages/settings/TheHoliday.vue'),
  },

  {
    path: '/settings/partners',
    name: 'admin-settings-partners',
    meta: {
      accessControlGroup: 'settings-partner',
    },
    component: () => import('@/pages/settings/Partners.vue'),
  },
  {
    path: '/settings/partners/create',
    name: 'admin-settings-the-partner-create',
    meta: {
      accessControlGroup: 'settings-partner',
    },
    component: () => import('@/pages/settings/ThePartner.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/partners/:partnerId(\\d+)',
    name: 'admin-settings-the-partner',
    meta: {
      accessControlGroup: 'settings-partner',
    },
    component: () => import('@/pages/settings/ThePartner.vue'),
  },

  {
    path: '/settings/roles',
    name: 'admin-settings-roles',
    meta: {
      accessControlGroup: 'settings-role',
    },
    component: () => import('@/pages/settings/Roles.vue'),
  },
  {
    path: '/settings/roles/create',
    name: 'admin-settings-the-role-create',
    meta: {
      accessControlGroup: 'settings-role',
    },
    component: () => import('@/pages/settings/TheRole.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/roles/:roleName',
    name: 'admin-settings-the-role',
    meta: {
      accessControlGroup: 'settings-role',
    },
    component: () => import('@/pages/settings/TheRole.vue'),
  },

  {
    path: '/settings/users',
    name: 'admin-settings-users',
    meta: {
      accessControlGroup: 'settings-user',
    },
    component: () => import('@/pages/settings/Users.vue'),
  },
  {
    path: '/settings/users/create',
    name: 'admin-settings-the-user-create',
    meta: {
      accessControlGroup: 'settings-user',
    },
    component: () => import('@/pages/settings/TheUser.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/users/:userId(\\d+)',
    name: 'admin-settings-the-user',
    meta: {
      accessControlGroup: 'settings-user',
    },
    component: () => import('@/pages/settings/TheUser.vue'),
  },

  {
    path: '/settings/wiki',
    name: 'admin-settings-wiki',
    meta: {
      accessControlGroup: 'settings-wiki',
    },
    component: () => import('@/pages/settings/Wiki.vue'),
  },
  {
    path: '/settings/wiki/create',
    name: 'admin-settings-the-wiki-create',
    meta: {
      accessControlGroup: 'settings-wiki',
    },
    component: () => import('@/pages/settings/TheWiki.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/wiki/:wikiId(\\d+)',
    name: 'admin-settings-the-wiki',
    meta: {
      accessControlGroup: 'settings-wiki',
    },
    component: () => import('@/pages/settings/TheWiki.vue'),
  },

  ...triggerSendings,
]

export default routes
