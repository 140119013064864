import { ApiCourseManagementRequestsList } from 'ApiRest/Api/CourseManagement/Requests/List'

export default {
  async fetchRequests({ commit }, filters = {}) {
    const { data, headers } = await ApiCourseManagementRequestsList.get(filters)

    commit('setFilters', filters)
    commit('setRequests', data)
    commit('setPagination', headers)
  },
}
