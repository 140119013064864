import Constants from 'Constants'
import { isNull } from 'lodash'

export default {
  hasProgram(state) {
    return state.product?.program?.length > 0
  },

  schoolsSelected(state) {
    return state.product.visibility.schools.map((school) => school.school)
  },

  offersInArchive(state) {
    return state.product.offers.filter((offer) => offer.inArchive)
  },

  offersActual(state) {
    return state.product.offers.filter((offer) => !offer.inArchive)
  },

  hasProductId(state) {
    return !isNull(state.product.id)
  },

  hasCustomerRelationshipType(state) {
    return !isNull(state.product.customerRelationshipType)
  },

  isCustomerRelationshipTypeContract(state) {
    return (
      state.product.customerRelationshipType?.id ===
      Constants.customerRelationshipType.CONTRACT
    )
  },

  hasBuyers(state) {
    return state.product.users.length > 0
  },
}
