import ApiBase from 'ApiRest/ApiBase'

export const ApiProductCertificateIssue = new (class extends ApiBase {
  /**
   * Выдача сертификата
   * @param {Object} payload
   * @returns {Promise}
   */
  put(payload) {
    const url = `/product/certificate-issue/issue`

    return this._PUT(url, payload)
  }
})()
