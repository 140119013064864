import { isEmpty } from 'lodash'

export default {
  setUserSettings(state, payload) {
    state.userSettings = isEmpty(payload) ? {} : payload
  },

  setTypeList(state, payload) {
    state.typeList = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {number} payload.typeId
   * @param {Object[]} payload.data
   */
  setItemList(state, payload) {
    state.itemListMap = {
      ...state.itemListMap,
      [payload.typeId]: payload.data,
    }
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {number} payload.typeId
   * @param {string} payload.colId
   * @param {'asc'|'desc'} payload.sort
   */
  setItemListSort(state, payload) {
    const { itemListSortMap = {} } = state.userSettings

    state.userSettings.itemListSortMap = {
      ...itemListSortMap,
      [payload.typeId]: {
        colId: payload.colId,
        sort: payload.sort,
      },
    }
  },

  resetTypeList(state) {
    state.typeList = []
  },

  resetItemListMap(state) {
    state.itemListMap = {}
  },

  resetUserSettings(state) {
    state.userSettings = {}
  },
}
