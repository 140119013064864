export default [
  {
    path: '/payments/product/promocode-groups',
    name: 'admin-payment-product-promocode-groups',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/product/PromocodeGroups.vue'),
  },
  {
    path: '/payments/product/promocode-group/create',
    name: 'admin-payment-product-the-promocode-group-create',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/product/ThePromocodeGroup.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/product/promocode-group/:promocodeGroupId(\\d+)',
    name: 'admin-payment-product-the-promocode-group',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/product/ThePromocodeGroup.vue'),
  },
]
