import ApiBase from 'ApiRest/ApiBase'

export const ApiFileImageRotate = new (class extends ApiBase {
  /**
   * @param {number} fileId
   * @param {number} angle
   * @returns {Promise}
   */
  patch(fileId, angle) {
    const url = `/file/image/rotate/${fileId}/${angle}`

    return this._PATCH(url)
  }
})()
