import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCategories(state, payload) {
    state.categories = payload
  },

  setCategory(state, payload) {
    state.category = payload
  },

  setCategoryName(state, payload) {
    state.category.name = payload
  },

  setCategoryIsActive(state, payload) {
    state.category.isActive = payload
  },

  setCategoryParent(state, payload) {
    Vue.set(state.category, 'parent', payload)
  },

  setCategoryParentId(state, payload) {
    Vue.set(state.category, 'parentId', payload)
  },

  unsetCategoryParentId(state) {
    state.parentId = null
  },
}
