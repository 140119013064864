import ApiBase from 'ApiRest/ApiBase'

export default new (class PromocodeGroupList extends ApiBase {
  /**
   * Генерируется промокод
   * @returns {Promise}
   */
  get() {
    return this._GET(`/promocode-groups/generate-promocode`)
  }
})()
