import ApiBase from 'ApiRest/ApiBase'

export const ApiAnalyticsOnlineLessonChart = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const url = `/analytics/online-lesson/chart?${this.getUrlParams(params)}`

    return this._GET(url)
  }
})()
