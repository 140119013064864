import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLessonAnalytics = new (class extends ApiBase {
  /**
   * Аналитика по уроку
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  get(onlineLessonId) {
    return this._GET(`/showcase/online-lessons/${onlineLessonId}/analytics`)
  }
})()
