import ApiContentList from 'ApiRest/Api/Library/Section/Topic/Content/List/List'

export default {
  async fetchContentList({ commit }, params) {
    const { data } = await ApiContentList.get({
      ...params,
    })

    commit('setFilters', params)
    commit(
      'setContentList',
      data.sort((a, b) => a.position - b.position),
    )
  },
}
