import { omit } from 'lodash'

const FIRST_GRADE_ID = 1

export default {
  servicesWithoutPrice(state) {
    return state.options.services.map((service) =>
      omit(service, [
        'price',
      ]),
    )
  },

  grades(state) {
    return state.gradeSubjectSettings.map((setting) => setting.grade)
  },

  settingsFullTimeDiagnostics(state) {
    return state.subjectsFullTimeDiagnostics.subjectSettings
  },

  settingsExtramuralDiagnostics(state) {
    return state.subjectsExtramuralDiagnostics.subjectSettings
  },

  gradesFiltered(state) {
    return state.options.grades.filter(({ id }) => id !== FIRST_GRADE_ID)
  },
}
