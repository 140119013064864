import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setLibraryList(state, payload) {
    state.libraryList = payload
  },

  /**
   * @param {Object} state
   * @param {Object} headers
   */
  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    const { pagination } = state

    if (pagination !== null) {
      compositePagination.setRowsPerPage(pagination.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   * @param {number} payload.value
   */
  setPaginationRowsPerPage(state, { value }) {
    state.pagination.setRowsPerPage(value)
  },

  /**
   * @param {Object} state
   * @param {Object} filters
   */
  setFilters(state, filters) {
    state.filters = filters
  },
}
