import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} contractId
   * @returns {Promise}
   */
  get(contractId) {
    const url = `/enrollment/contract/${contractId}/last-request-to-confirm-log`

    return this._GET(url)
  }
})()
