import ApiBase from 'ApiRest/ApiBase'

export const ApiShowcaseOnlineLesson = new (class extends ApiBase {
  /**
   * Получение detail online-урока витрины
   * @param {number} onlineLessonId
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  get(onlineLessonId, parameters) {
    const url = `/showcase/online-lessons/${onlineLessonId}?${this.getUrlParams(
      parameters,
    )}`

    return this._GET(url)
  }
})()
