import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonWatchers = new (class extends ApiBase {
  /**
   * Просмотревшие урок в записи
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  get(onlineLessonId) {
    return this._GET(`/online-lessons/${onlineLessonId}/watchers`)
  }
})()
