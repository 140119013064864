export default () => ({
  chart: [],
  common: null,

  data: [],

  detail: [],

  users: [],

  detailPeriods: [
    {
      id: 1,
      value: 'day',
      label: 'по дням',
    },
    {
      id: 2,
      value: 'month',
      label: 'по месяцам',
    },
  ],
  detailPeriod: null,

  detailLevel: 1,
})
