import { userActions } from '@/accessControl/userActions'
import adminRoutes from '@/routes'
import { isArray } from 'lodash'

/**
 * @param {Object} args
 * @param {Function} args.can
 * @param {Function} args.cannot
 * @returns {Object}
 */
export const conditions = ({ can, cannot }) => ({
  /**
   * Метод, вызывающий CASL-функцию can для userAction
   * последовательно на весь список names
   * @param {string} userAction - разрешаемое действие способности CASL
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowActionOnRoutes(userAction, names) {
    names.forEach((name) => {
      can(userAction, name)
    })
  },

  /**
   * Метод, вызывающий CASL-функцию cannot для userAction
   * последовательно на весь список names
   * @param {string} userAction - запрещаемое действие способности CASL
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  disallowActionOnRoutes(userAction, names) {
    names.forEach((name) => {
      cannot(userAction, name)
    })
  },

  /**
   * Разрешено открывать страницу на чтение
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowReadRoutes(names) {
    this.allowActionOnRoutes(userActions.READ, names)
  },

  /**
   * Разрешено обновлять контент страницы
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowUpdateRoutes(names) {
    this.allowActionOnRoutes(userActions.UPDATE, names)
  },

  /**
   * Разрешено удалять
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowDeleteRoutes(names) {
    this.allowActionOnRoutes(userActions.DELETE, names)
  },

  /**
   * Разрешено отображать в главном меню ссылку
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowReadMenuItemForRoutes(names) {
    this.allowActionOnRoutes(userActions.READ_MENU_ITEM, names)
  },

  /**
   * Разрешено редактировать контент страницы
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowModifyRoutes(names) {
    this.allowActionOnRoutes(userActions.UPDATE, names)
    this.allowActionOnRoutes(userActions.CREATE, names)
    this.allowActionOnRoutes(userActions.DELETE, names)
  },

  /**
   * Разрешено редактировать контент страницы
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowCrudRoutes(names) {
    this.allowActionOnRoutes(userActions.CREATE, names)
    this.allowActionOnRoutes(userActions.READ, names)
    this.allowActionOnRoutes(userActions.UPDATE, names)
    this.allowActionOnRoutes(userActions.DELETE, names)
  },

  /**
   * Разрешен доступ к персональным данным клиента
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowAccessPDClientForRoutes(names) {
    this.allowActionOnRoutes(userActions.ACCESS_PD_CLIENT, names)
  },

  /**
   * Разрешен доступ к персональным данным ученика
   * (для перечисленных роутов)
   * @param {string[]} names - Имена роутов из роутера
   * @public
   */
  allowAccessPDStudentForRoutes(names) {
    this.allowActionOnRoutes(userActions.ACCESS_PD_STUDENT, names)
  },
})

/**
 * Собрать коллекцию `имя группы роутов` - `список имен роутов`
 * из всех доступных роутов
 * @returns {Map<string, string[]>}
 */
export const composeRouteGroups = () => {
  /**
   * Получить список всех роутов (в т.ч. вложенных), у которых
   * задана группа роутов
   * @param {{}[]} routes
   * @returns {{name: string, accessControlGroup: string}[]}
   */
  const getRoutesWithAccessControlGroup = function (routes) {
    let list = []

    routes.forEach((route) => {
      if (isArray(route.children)) {
        const children = getRoutesWithAccessControlGroup(route.children)

        if (children.length > 0) {
          list = [
            ...list,
            ...children,
          ]
        }
      } else {
        if (route.name && route.meta?.accessControlGroup) {
          list.push({
            name: route.name,
            accessControlGroup: route.meta.accessControlGroup,
          })
        }
      }
    })

    return list
  }

  const routes = new Map()

  // Преобразовать список объектов из роутера
  // (у которых задана группа роутов) в коллекцию
  getRoutesWithAccessControlGroup(adminRoutes.children).forEach((route) => {
    if (route.name && route.accessControlGroup) {
      if (!routes.has(route.accessControlGroup)) {
        routes.set(route.accessControlGroup, [])
      }

      const accessControlGroupList = routes.get(route.accessControlGroup)

      accessControlGroupList.push(route.name)
      routes.set(route.accessControlGroup, accessControlGroupList)
    }
  })

  return routes
}

/**
 * Собрать объект дополнительных данных для сложных условий
 * (например, если нужно проверить два пермишена одновременно)
 * @param {Object} args
 * @param {string[]} args.permissions
 * @returns {Object}
 */
export const composeExtraData = ({ permissions }) => ({
  hasAccessPDStudent: permissions.includes('access_student_personal_data'),
  hasAccessPDClient: permissions.includes('access_client_personal_data'),
})
