import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class News extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  getList(parameters = {}) {
    return this._GET(`/news?${UrlHelper.param(parameters)}`)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/news/${id}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/news/${id}`, data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/news/${id}`)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/news`, data)
  }
})()
