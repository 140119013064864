import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setPromocodeGroups(state, payload) {
    state.promocodeGroups = payload
  },

  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    const paginationState = state.pagination

    if (paginationState !== null) {
      compositePagination.setRowsPerPage(paginationState.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  setPaginationRowsPerPage(state, { value }) {
    state.pagination.setRowsPerPage(value)
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  setPromocodeGroup(state, payload) {
    state.promocodeGroup = payload
  },

  setPromocodeGroupId(state, payload) {
    state.promocodeGroupId = payload
  },

  setPromocodeGroupValue(state, { key, value }) {
    Vue.set(state.promocodeGroup, key, value)
  },

  setServices(state, payload) {
    state.services = payload
  },

  setOptions(state, { path, value }) {
    Vue.set(state.options, path, value)
  },
}
