export const courseManagementRoutes = [
  {
    path: 'course-management/products',
    name: 'admin-course-management-products',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () => import('@/pages/courseManagement/product/Products.vue'),
  },
  {
    path: 'course-management/products/:productId(\\d+)',
    name: 'admin-course-management-the-product',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () => import('@/pages/courseManagement/product/TheProduct.vue'),
  },
  {
    path: 'course-management/products/create',
    name: 'admin-course-management-product-create',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () =>
      import('@/pages/courseManagement/product/CreateProduct.vue'),
  },
  {
    path: 'course-management/analytics',
    name: 'admin-course-management-analytics',
    meta: {
      accessControlGroup: 'course-management-analytics',
    },
    component: () => import('@/pages/courseManagement/Analytics.vue'),
  },
  {
    path: 'course-management/orders',
    name: 'admin-course-management-orders',
    meta: {
      accessControlGroup: 'course-management-order',
    },
    component: () => import('@/pages/courseManagement/order/Orders.vue'),
  },
  {
    path: 'course-management/order/:orderId(\\d+)',
    name: 'admin-course-management-the-order',
    meta: {
      accessControlGroup: 'course-management-order',
    },
    component: () => import('@/pages/courseManagement/order/TheOrder.vue'),
  },
  {
    path: 'course-management/banners',
    name: 'admin-course-management-banners',
    meta: {
      accessControlGroup: 'course-management-banner',
    },
    component: () => import('@/pages/courseManagement/banner/Banners.vue'),
  },
  {
    path: 'course-management/banner/create',
    name: 'admin-course-management-banner-create',
    meta: {
      accessControlGroup: 'course-management-banner',
    },
    component: () => import('@/pages/courseManagement/banner/TheBanner.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'course-management/banner/:bannerId(\\d+)',
    name: 'admin-course-management-the-banner',
    meta: {
      accessControlGroup: 'course-management-banner',
    },
    component: () => import('@/pages/courseManagement/banner/TheBanner.vue'),
  },
  {
    path: 'course-management/categories',
    name: 'admin-course-management-categories',
    meta: {
      accessControlGroup: 'course-management-category',
    },
    component: () => import('@/pages/courseManagement/category/Categories.vue'),
  },
  {
    path: 'course-management/categories/create',
    name: 'admin-course-management-category-create',
    meta: {
      accessControlGroup: 'course-management-category',
    },
    component: () =>
      import('@/pages/courseManagement/category/TheCategory.vue'),
  },
  {
    path: 'course-management/categories/:categoryId(\\d+)',
    name: 'admin-course-management-the-category',
    meta: {
      accessControlGroup: 'course-management-category',
    },
    component: () =>
      import('@/pages/courseManagement/category/TheCategory.vue'),
  },
  {
    path: 'course-management/groups',
    name: 'admin-course-management-groups',
    meta: {
      accessControlGroup: 'course-management-product',
    },
    component: () => import('@/pages/group/Groups.vue'),
  },
  {
    path: 'course-management/parameters',
    name: 'admin-course-management-parameters',
    meta: {
      accessControlGroup: 'course-management-parameter',
    },
    component: () =>
      import('@/pages/courseManagement/parameter/Parameters.vue'),
  },
  {
    path: 'course-management/parameter/create',
    name: 'admin-course-management-the-parameter-create',
    meta: {
      accessControlGroup: 'course-management-parameter',
    },
    component: () =>
      import('@/pages/courseManagement/parameter/TheParameter.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'course-management/parameter/:parameterId(\\d+)',
    name: 'admin-course-management-the-parameter',
    meta: {
      accessControlGroup: 'course-management-parameter',
    },
    component: () =>
      import('@/pages/courseManagement/parameter/TheParameter.vue'),
  },
  {
    path: 'course-management/requests',
    name: 'admin-course-management-requests',
    meta: {
      accessControlGroup: 'course-management-request',
    },
    component: () => import('@/pages/courseManagement/requests/Requests.vue'),
  },
  {
    path: 'course-management/requests/:requestId(\\d+)',
    name: 'admin-course-management-the-request',
    meta: {
      accessControlGroup: 'course-management-request',
    },

    component: () => import('@/pages/courseManagement/requests/TheRequest.vue'),
  },
]
