export default () => ({
  request: {
    date: '',
    applicant: {
      email: '',
      fullName: '',
      id: null,
      phone: '',
      role: '',
    },

    consumer: {
      email: '',
      fullName: '',
      id: null,
      phone: '',
      role: '',
    },

    product: {
      id: null,
      name: '',
    },

    offer: {
      id: null,
      parameters: [],
    },
  },

  requestId: null,

  options: {
    roles: [],
  },
})
