<template>
  <div class="d-flex align-items-center mb-4">
    <div class="w-100">
      <UiKitFormField :label="t('icon')" class="mb-2">
        <UiKitUploadSmallImage v-model="icon" />
      </UiKitFormField>

      <UiKitFormField :label="t('text')">
        <UiKitInput v-model="text" />
      </UiKitFormField>
    </div>

    <div class="p-3 cursor-pointer" @click="$emit('remove')">
      <i class="fas fa-times"></i>
    </div>
  </div>
</template>

<script>
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitInput from '@/ui/UiKitInput.vue'
import UiKitUploadSmallImage from '@/ui/UiKitUploadSmallImage.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IconItem',

  components: {
    UiKitFormField,
    UiKitInput,
    UiKitUploadSmallImage,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    text: {
      get() {
        return this.item.text
      },

      set(value) {
        this.$emit('change', {
          ...this.item,
          text: value,
        })
      },
    },

    icon: {
      get() {
        return this.item.icon
      },

      set(value) {
        this.$emit('change', {
          ...this.item,
          icon: value,
        })
      },
    },
  },

  methods: {
    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.text.modal_add_icons.${name}`,
      )
    },
  },
})
</script>
