import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementRequest = new (class extends ApiBase {
  /**
   * @param {number} requestID
   * @returns {Promise}
   */
  get(requestID) {
    return this._GET(`/courses/product/requests/${requestID}`)
  }
})()
