export const weekDays = [
  {
    id: 'monday',
    // TODO: i18n
    name: 'Понедельник',
  },
  {
    id: 'tuesday',
    // TODO: i18n
    name: 'Вторник',
  },
  {
    id: 'wednesday',
    // TODO: i18n
    name: 'Среда',
  },
  {
    id: 'thursday',
    // TODO: i18n
    name: 'Четверг',
  },
  {
    id: 'friday',
    // TODO: i18n
    name: 'Пятница',
  },
  {
    id: 'saturday',
    // TODO: i18n
    name: 'Суббота',
  },
  {
    id: 'sunday',
    // TODO: i18n
    name: 'Воскресенье',
  },
]
