import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementOptionsProducts = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/product/options/products')
  }
})()
