export default [
  {
    path: '/payments/subscriptions',
    name: 'admin-payment-subscriptions',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/subscriptions/Subscriptions.vue'),
  },
  {
    path: '/payments/subscriptions/:subscriptionId(\\d+)',
    name: 'admin-payment-the-subscription',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import('@/pages/payment/subscriptions/TheSubscription.vue'),
  },
  {
    path: '/payments/subscriptions/create',
    name: 'admin-payment-create-subscription',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () =>
      import('@/pages/payment/subscriptions/CreateSubscription.vue'),
  },
]
