import ApiFamilyEducationSetting from 'ApiRest/Api/FamilyEducation/Settings'
import ApiOption from 'ApiRest/Api/Option'
import ApiGrades from 'ApiRest/Api/School/Grades'
import ApiSchoolList from 'ApiRest/Api/School/List'
import ApiFamilyEducationApplication from 'ApiRest/Api/School/Template/FamilyEducationApplication'
import ApiFamilyEducationApplicationList from 'ApiRest/Api/School/Template/FamilyEducationApplication/List'
import ApiFamilyEducationContract from 'ApiRest/Api/School/Template/FamilyEducationContract'
import ApiFamilyEducationContractList from 'ApiRest/Api/School/Template/FamilyEducationContract/List'
import ApiFamilyEducationOffer from 'ApiRest/Api/School/Template/FamilyEducationOffer'
import ApiFamilyEducationOfferList from 'ApiRest/Api/School/Template/FamilyEducationOffer/List'
import Constants from 'Constants'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchSchools({ commit }) {
    const options = {
      educationTypes: [
        {
          id: Constants.educationType.FAMILY,
        },
      ],
    }

    const { data } = await ApiSchoolList.get(options)

    commit('setSchools', data)
  },

  async fetchSettings({ commit }, id) {
    const { data } = await ApiFamilyEducationSetting.get(id)

    commit('setSettings', data)
  },

  async updateSettings({ commit }, { id, payload }) {
    const { data } = await ApiFamilyEducationSetting.put(id, payload)

    commit('setSchools', data)
  },

  async fetchGrades({ commit }, id) {
    const { data } = await ApiGrades.get(id)

    commit('setGrades', data)
  },

  async fetchServices({ commit }) {
    const { data } = await ApiOption.getInvoiceServices()

    commit('setServices', data)
  },

  async fetchStreams({ commit }, options) {
    const { data } = await ApiOption.getStreams(options)

    commit('setStreams', data)
  },

  async uploadTemplateOffer(context, payload) {
    await ApiFamilyEducationOffer.post(payload)
  },

  async fetchTemplateOffers({ commit }, schoolId) {
    const { data } = await ApiFamilyEducationOfferList.get(schoolId)

    commit('setTemplateOffers', data)
  },

  async uploadTemplateApplication(context, payload) {
    await ApiFamilyEducationApplication.post(payload)
  },

  async fetchTemplateApplications({ commit }, schoolId) {
    const { data } = await ApiFamilyEducationApplicationList.get(schoolId)

    commit('setTemplateApplications', data)
  },

  async uploadTemplateContract(context, payload) {
    await ApiFamilyEducationContract.post(payload)
  },

  async fetchTemplateContracts({ commit }, schoolId) {
    const { data } = await ApiFamilyEducationContractList.get(schoolId)

    commit('setTemplateContracts', data)
  },
}
