import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductTypes = new (class extends ApiBase {
  /**
   * Виды продукта
   * @returns {Promise}
   */
  get() {
    return this._GET(`/online-school/product/options/types`)
  }
})()
