import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductOptionsOnlineLessons =
  new (class extends ApiBase {
    /**
     * Список онлайн уроков продукта
     * @param {number} productId
     * @returns {Promise}
     */
    get(productId) {
      return this._GET(
        `/online-school/product/${productId}/options/online-lessons`,
      )
    }
  })()
