import ApiBase from 'ApiRest/ApiBase'

export const ApiTemplateDiagnostics = new (class extends ApiBase {
  /**
   * Скачивания пустого шаблона договора/заявления/доп.соглашения
   * @param {number} schoolId
   * @param {number} documentId
   * @returns {Promise}
   */
  get(schoolId, documentId) {
    const url = `/school/${schoolId}/template/diagnostics/${documentId}`
    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }

  /**
   * Удаления шаблона договора/заявления/доп.соглашения
   * @param {number} schoolId
   * @param {number} documentId
   * @returns {Promise}
   */
  delete(schoolId, documentId) {
    const url = `/school/${schoolId}/template/diagnostics/${documentId}`

    return this._DELETE(url)
  }
})()
