const routes = [
  {
    path: '/sendings',
    name: 'admin-sendings',
    meta: {
      accessControlGroup: 'sendings',
    },
    component: () => import('@/pages/sending/Sendings.vue'),
  },
  {
    path: '/sending/create',
    name: 'admin-the-sending-create',
    meta: {
      accessControlGroup: 'sendings',
    },
    component: () => import('@/pages/sending/TheSending.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/sending/:sendingId(\\d+)',
    name: 'admin-the-sending',
    meta: {
      accessControlGroup: 'sendings',
    },
    component: () => import('@/pages/sending/TheSending.vue'),
  },
]

export default routes
