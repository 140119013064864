import { ApiTriggerSendingAnalytics } from 'ApiRest/Api/TriggerSending/Analytics'
import { isNil } from 'lodash'

export default {
  async fetchAnalytics({ rootState, commit }, filters = {}) {
    if (isNil(rootState.admin.triggerSending.triggerSending.id)) {
      return
    }

    const { data, headers } = await ApiTriggerSendingAnalytics.get(
      rootState.admin.triggerSending.triggerSending.id,
      filters,
    )

    commit('setAnalytics', data)
    commit('setFilters', filters)
    commit('setPagination', headers)
  },
}
