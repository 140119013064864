export default () => ({
  banner: {
    id: null,
    image: null,
    index: '',
    name: '',
    position: null,
    status: null,
    url: '',
  },

  bannerId: null,

  options: {
    positions: [],
    statuses: [],
  },
})
