import ApiEnrollmentPartner from 'ApiRest/Api/Enrollment/Partner'
import ApiEnrollmentPartnerComment from 'ApiRest/Api/Enrollment/Partner/Comment'
import ApiOption from 'ApiRest/Api/Option'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} partnerId
   * @returns {Promise<object>}
   */
  async fetchThePartner({ commit }, partnerId) {
    const { data } = await ApiEnrollmentPartner.get(partnerId)

    commit('setThePartner', data)
  },

  /**
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @returns {Promise<object>}
   */
  async createThePartner({ state, commit }) {
    const { data } = await ApiEnrollmentPartner.post(state.partner)

    commit('setThePartner', data)
  },

  /**
   * @param {Object} context
   * @param {Object} context.state
   * @param {Function} context.commit
   * @param {number} partnerId
   * @returns {Promise<object>}
   */
  async updateThePartner({ state, commit }, partnerId) {
    const { data } = await ApiEnrollmentPartner.patch(partnerId, state.partner)

    commit('setThePartner', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} partnerId
   * @returns {Promise<object>}
   */
  async fetchComments({ commit }, partnerId) {
    const { data } = await ApiEnrollmentPartnerComment.get(partnerId)

    commit('setComments', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.dispatch
   * @param {Object} payload
   * @param {number} payload.partnerId
   * @param {Object} payload.comment
   */
  async sendComment({ dispatch }, { partnerId, comment }) {
    await ApiEnrollmentPartnerComment.post(partnerId, comment)

    await dispatch('fetchComments', partnerId)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @returns {Promise<void>}
   */
  async getOptions({ commit }) {
    await Promise.all([
      ApiOption.getEducationTypes().then(({ data }) => {
        commit('setOptions', {
          key: 'educationTypes',
          data,
        })
      }),
      ApiOption.getGrades().then(({ data }) => {
        commit('setOptions', {
          key: 'grades',
          data,
        })
      }),
      ApiOption.getSchools().then(({ data }) => {
        commit('setOptions', {
          key: 'schools',
          data,
        })
      }),
    ])
  },
}
