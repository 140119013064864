import { ApiProductQuestionList } from 'ApiRest/Api/Products/Question/List'
import { ApiProductQuestion } from 'ApiRest/Api/Products/Question/Question'
import { ApiProductQuestionStatuses } from 'ApiRest/Api/Products/Question/Statuses'

export default {
  async fetchQuestions({ commit }, params) {
    const { data, headers } = await ApiProductQuestionList.get(params)

    commit('setFilters', params)
    commit('setQuestions', data)
    commit('setPagination', headers)
  },

  async fetchQuestion({ commit }, questionId) {
    const { data } = await ApiProductQuestion.get(questionId)

    commit('setQuestion', data)
  },

  async updateQuestion({ state, commit }) {
    const { data } = await ApiProductQuestion.patch(
      state.question.id,
      state.question,
    )

    commit('setQuestion', data)
  },

  async fetchQuestionOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchQuestionStatuses'),
    ])
  },

  async fetchQuestionStatuses({ commit }) {
    const { data } = await ApiProductQuestionStatuses.get()

    commit('setOptionsList', {
      path: 'statuses',
      value: data,
    })
  },
}
