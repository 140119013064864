import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportFAQItem = new (class extends ApiBase {
  /**
   * @param {number} topicId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(topicId, payload, parameters) {
    const url = `/support/faq/topics/${topicId}/items?${UrlHelper.param(
      parameters,
    )}`

    return this._POST(url, payload)
  }

  /**
   * @param {number} topicId
   * @param {number} itemId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(topicId, itemId, parameters) {
    const url = `/support/faq/topics/${topicId}/items/${itemId}?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {number} topicId
   * @param {number} itemId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(topicId, itemId, payload, parameters) {
    const url = `/support/faq/topics/${topicId}/items/${itemId}?${UrlHelper.param(
      parameters,
    )}`

    return this._PATCH(url, payload)
  }

  /**
   * @param {number} topicId
   * @param {number} itemId
   * @param {Object} parameters
   * @returns {Promise}
   */
  delete(topicId, itemId, parameters) {
    const url = `/support/faq/topics/${topicId}/items/${itemId}?${UrlHelper.param(
      parameters,
    )}`

    return this._DELETE(url)
  }
})()
