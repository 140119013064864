import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerSending = new (class extends ApiBase {
  /**
   * Детальная информация рассылки
   * @param {number} triggerSendingId
   * @returns {Promise}
   */
  get(triggerSendingId) {
    return this._GET(`/trigger-sending/${triggerSendingId}`)
  }

  /**
   * Изменение данных рассылки
   * @param {number} triggerSendingId
   * @param {Object} parameters
   * @returns {Promise}
   */
  put(triggerSendingId, parameters = {}) {
    return this._PUT(`/trigger-sending/${triggerSendingId}`, parameters)
  }

  /**
   * Создание рассылки
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(parameters = {}) {
    return this._POST('/trigger-sending', parameters)
  }
})()
