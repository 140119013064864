const wiki = [
  {
    path: '/wiki',
    name: 'admin-wiki',
    meta: {
      accessControlGroup: 'wiki',
    },
    component: () => import('@/pages/wiki/Wiki.vue'),
  },
  {
    path: '/wiki/:schoolId(\\d+)/:wikiId(\\d+)?',
    name: 'admin-the-wiki',
    meta: {
      accessControlGroup: 'wiki',
    },
    component: () => import('@/pages/wiki/TheWiki.vue'),
  },
]

export default wiki
