<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header> {{ t('header') }} </template>

    <template #body>
      <div>
        <UiKitFormField :label="t('columns')" class="mb-4">
          <UiKitSelect
            v-model="model.columns"
            :options="options.columns"
            :clearable="false"
          />
        </UiKitFormField>
      </div>
      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_add')"
          @click="onClickBtnAdd"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitModal from '@/ui/UiKitModal.vue'
import UiKitSelect from '@/ui/UiKitSelect.vue'
import { cloneDeep } from 'lodash'

import { defineComponent } from 'vue'

const createModel = () => ({
  columns: 2,
})

export default defineComponent({
  name: 'ModalAddTextsWithTitle',

  components: {
    UiKitFormField,
    UiKitSelect,
    UiKitModal,
    UiKitButton,
  },

  data() {
    return {
      model: createModel(),
      options: {
        columns: [
          1,
          2,
          3,
          4,
        ],
      },
    }
  },

  methods: {
    onClickBtnAdd() {
      this.$emit('add', cloneDeep(this.model))
      this.hide()
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$refs.modal.show()
    },

    hide() {
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
    },

    resetData() {
      this.model = createModel()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.text.modal_add_text_with_title.${name}`,
      )
    },
  },
})
</script>
