import ApiBase from 'ApiRest/ApiBase'

export const ApiDashboardItemList = new (class extends ApiBase {
  /**
   * @param {number} typeId
   * @returns {Promise<Object>}
   */
  get(typeId) {
    const url = `/widget/${typeId}`

    return this._GET(url)
  }
})()
