<template>
  <UiKitModal2 :name="name" :click-to-close="false" width="70%">
    <div class="p-4">
      <div class="d-flex justify-content-end">
        <UiKitButton
          class="btn-secondary btn-lg ml-4"
          @click="onClickBtnCloseModal"
        >
          <i class="fa fa-times"></i>
        </UiKitButton>
      </div>

      <div>
        <ContentWithPreloader :is-loading="isLoading">
          <img :class="$style.image" :src="originalUrl" :alt="fileName" />
        </ContentWithPreloader>
      </div>
    </div>
  </UiKitModal2>
</template>

<script>
import ContentWithPreloader from '@/ui/ContentWithPreloader.vue'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import { uniqueId } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalViewImage',

  components: {
    UiKitButton,
    UiKitModal2,
    ContentWithPreloader,
  },

  props: {
    file: {
      type: Object,
      default: null,
    },

    originalUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      name: uniqueId('ModalViewImage'),
    }
  },

  computed: {
    fileName() {
      return this.file?.name ?? null
    },

    isLoading() {
      return !this.originalUrl
    },
  },

  methods: {
    onClickBtnCloseModal() {
      this.$emit('cancel')

      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    hide() {
      this.$modal.hide(this.name)
    },
  },
})
</script>

<style lang="scss" module>
.image {
  width: 100%;
  height: 90vh;
  object-fit: contain;
}
</style>
