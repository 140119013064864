import { ApiProductManagementSettings } from 'ApiRest/Api/ProductManagement/Settings/Settings'

export default {
  async fetchSettings({ commit }) {
    const { data } = await ApiProductManagementSettings.get()

    commit('setSettings', data)
  },

  async updateSettings({ commit, state }) {
    const { data } = await ApiProductManagementSettings.patch(state.settings)

    commit('setSettings', data)
  },
}
