export default () => ({
  topicsWithItems: [],
  theTopic: {
    id: null,
    title: '',
    schools: [],
    educationTypes: [],
    status: null,
    items: [],
  },
  theQuestion: {
    id: null,
    topicId: null,
    question: '',
    status: null,
    materials: [],
  },
  option: {
    schools: [],
    materialTypes: [],
    educationTypes: [],
    statuses: [],
    questions: [],
  },
})
