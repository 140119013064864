import ApiBase from 'ApiRest/ApiBase'

export const ApiSendingStudents = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(`/sending/students?${this.getUrlParams(parameters)}`)
  }
})()
