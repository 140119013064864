import ApiOption from 'ApiRest/Api/Option'
import { ApiProductsAdvertisement } from 'ApiRest/Api/Products/Advertisement/Advertisement'
import { ApiProductsAdvertisementList } from 'ApiRest/Api/Products/Advertisement/AdvertisementList'
import { ApiProductsListCourses } from 'ApiRest/Api/Products/Advertisement/ListCourses'
import { ApiProductsListShowcase } from 'ApiRest/Api/Products/Advertisement/ListShowcase'

const addIdToName = (item) => ({
  ...item,
  name: `${item.name} (ID: ${item.id})`,
})

export default {
  async fetchAdvertisements({ commit }, filters = {}) {
    const { data, headers } = await ApiProductsAdvertisementList.get(filters)

    commit('setAdvertisements', data)
    commit('setPagination', headers)
    commit('setFilters', filters)
  },

  async fetchAdvertisement({ commit }, advertisementId) {
    const { data } = await ApiProductsAdvertisement.get(advertisementId)

    commit('setAdvertisement', data)
  },

  async createAdvertisement({ commit, state }) {
    const { data } = await ApiProductsAdvertisement.post(state.advertisement)

    commit('setAdvertisement', data)
  },

  async updateAdvertisement({ state, commit }) {
    const { data } = await ApiProductsAdvertisement.put(
      state.advertisement.id,
      state.advertisement,
    )

    commit('setAdvertisement', data)
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptions', {
      path: 'schools',
      value: data,
    })
  },

  async fetchGrades({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOptions', {
      path: 'grades',
      value: data,
    })
  },

  async fetchEducationTypes({ commit }) {
    const { data } = await ApiOption.getEducationTypes()

    commit('setOptions', {
      path: 'educationTypes',
      value: data,
    })
  },

  async fetchProductsOfShowcase({ commit }) {
    const { data } = await ApiProductsListShowcase.get()

    commit('setOptions', {
      path: 'products',
      value: data.map(addIdToName),
    })
  },

  async fetchProductsOfCourses({ commit }) {
    const { data } = await ApiProductsListCourses.get()

    commit('setOptions', {
      path: 'products',
      value: data.map(addIdToName),
    })
  },

  async fetchOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchSchools'),
      dispatch('fetchGrades'),
      dispatch('fetchEducationTypes'),
    ])
  },

  async removeAdvertisement(context, advertisementId) {
    await ApiProductsAdvertisement.delete(advertisementId)
  },
}
