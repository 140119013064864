import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonRoom = new (class extends ApiBase {
  /**
   * Получение комнаты
   * @param {number} roomId
   * @returns {Promise<Object>}
   */
  get(roomId) {
    return this._GET(`/online-lessons/online-rooms/${roomId}`)
  }

  /**
   * Создание комнаты
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  post(payload) {
    return this._POST(`/online-lessons/online-rooms`, payload)
  }

  /**
   * Редактирование комнаты
   * @param {number} roomId
   * @param {Object} payload
   * @returns {Promise<Object>}
   */
  patch(roomId, payload) {
    return this._PATCH(`/online-lessons/online-rooms/${roomId}`, payload)
  }

  /**
   * Удаление комнаты
   * @param {number} roomId
   * @returns {Promise}
   */
  delete(roomId) {
    return this._DELETE(`/online-lessons/online-rooms/${roomId}`)
  }
})()
