import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setQuestionsCounter(state, { path, value }) {
    Vue.set(state.questionCounters, path, value)
  },
}
