import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonShowcaseList = new (class extends ApiBase {
  /**
   * Получение списка online-уроков витрины
   * @param {Object} parameters
   * @returns {Promise<Object>}
   */
  get(parameters) {
    const url = `/showcase/online-lessons?${this.getUrlParams(parameters)}`

    return this._GET(url)
  }
})()
