import { RESET_STATE } from '@/helpers/ResetState'

import { set } from 'lodash'
import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOptionsList(state, { path, value }) {
    set(state.options, path, value)
  },

  setStudentId(state, id) {
    state.studentId = id
  },

  setOnlineSchoolStatement(state, value) {
    state.statementOnlineSchool = value
  },

  setShowcaseStatement(state, value) {
    state.statementShowcase = value
  },

  setStudent(state, value) {
    state.student = value
  },

  setStatementFullTimeEducation(state, value) {
    state.statementFullTimeEducation = value
  },

  setStatementExtramuralEducation(state, value) {
    state.statementExtramuralEducation = value
  },

  setCurrentGroupsOnly(state, value) {
    state.currentGroupsOnly = value
  },

  setAnalytics(state, value) {
    state.analytics = value
  },

  setAnalyticsStudentComment(state, value) {
    state.analytics.student.comment = value
  },

  setStatementPeriod(state, value) {
    state.statementPeriod = value
  },
}
