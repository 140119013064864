export default () => ({
  certifications: [],

  certification: {
    id: null,
    name: '',
    school: null,
    grade: null,
    status: null,
    tests: [],
    hasStartedRequests: false,
  },

  familyStatement: null,
  familyCertificationSchools: [],
  familyCertificationGrades: [],
  familyCertificationStatuses: [],

  grades: [],
  subjects: [],

  statementScheduleList: [],
  statementSchedule: null,

  statementStreamList: [],
  statementStream: null,

  statementShowOnlyCompleted: false,
  statementSearchLastName: null,
  statementSortField: null,

  tests: [],
  testMarkSettings: [],

  testOptions: {
    whenShowAnswerTypes: [],
    showAnswerTypes: [],
    ctpTestExaminationLevels: [],
    attemptsMarkTypes: [],
    checkers: [],
  },
})
