import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportAdditionalContacts = new (class extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    const url = `/support/contacts/${schoolId}`

    return this._GET(url)
  }
})()
