const routes = [
  {
    path: '/price/education/extramural/subsidy',
    name: 'admin-price-extramural-subsidy',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/Subsidy.vue'),
  },
  {
    path: '/price/education/extramural/subsidy/create',
    name: 'admin-price-extramural-the-subsidy-create',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/TheSubsidy.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/price/education/extramural/subsidy/:subsidyId(\\d+)',
    name: 'admin-price-extramural-the-subsidy',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/TheSubsidy.vue'),
  },

  {
    path: '/price/education/extramural/paid',
    name: 'admin-price-extramural-paid',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/Paid.vue'),
  },
  {
    path: '/price/education/extramural/paid/create',
    name: 'admin-price-extramural-the-paid-create',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/ThePaid.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/price/education/extramural/paid/:paidId(\\d+)',
    name: 'admin-price-extramural-the-paid',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/ThePaid.vue'),
  },

  {
    path: '/price/education/extramural/individual-subsidy',
    name: 'admin-price-extramural-individual-subsidy',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/IndividualSubsidy.vue'),
  },
  {
    path: '/price/education/extramural/individual-subsidy/create',
    name: 'admin-price-extramural-the-individual-subsidy-create',
    meta: {
      accessControlGroup: 'price',
    },
    component: () =>
      import('@/pages/price/extramural/TheIndividualSubsidy.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/price/education/extramural/individual-subsidy/:subsidyId(\\d+)',
    name: 'admin-price-extramural-the-individual-subsidy',
    meta: {
      accessControlGroup: 'price',
    },
    component: () =>
      import('@/pages/price/extramural/TheIndividualSubsidy.vue'),
  },

  {
    path: '/price/education/extramural/individual-paid',
    name: 'admin-price-extramural-individual-paid',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/IndividualPaid.vue'),
  },
  {
    path: '/price/education/extramural/individual-paid/create',
    name: 'admin-price-extramural-the-individual-paid-create',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/TheIndividualPaid.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/price/education/extramural/individual-paid/:paidId(\\d+)',
    name: 'admin-price-extramural-the-individual-paid',
    meta: {
      accessControlGroup: 'price',
    },
    component: () => import('@/pages/price/extramural/TheIndividualPaid.vue'),
  },
]

export default routes
