import ApiBase from 'ApiRest/ApiBase'

export default new (class FamilyEducationApplication extends ApiBase {
  /**
   * @param {number} schoolId
   * @param {number} documentId
   * @returns {Promise}
   */
  get(schoolId, documentId) {
    return this._GET(
      `/school/${schoolId}/template/family-education-application/${documentId}`,
    )
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(
      `/schools/template/family-education-application/upload`,
      data,
    )
  }
})()
