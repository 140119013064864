import ApiBase from 'ApiRest/ApiBase'

export default new (class Subsidy extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(
      `/price/extramural-education-individual-subsidy-price/${id}`,
    )
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(
      `/price/extramural-education-individual-subsidy-price`,
      data,
    )
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(
      `/price/extramural-education-individual-subsidy-price/${id}`,
      data,
    )
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/price/extramural-education-subsidy-price/${id}`)
  }
})()
