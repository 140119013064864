import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} partnerId
   * @returns {Promise}
   */
  get(partnerId) {
    const url = `/enrollment/partners/${partnerId}/comment`

    return this._GET(url)
  }

  /**
   * @param {number} partnerId
   * @param {Object} payload
   * @returns {Promise}
   */
  post(partnerId, payload) {
    const url = `/enrollment/partners/${partnerId}/comment`

    return this._POST(url, payload)
  }
})()
