import { userActions } from '@/accessControl/userActions'
import { getActiveModule } from '@/helpers/accessControl/module'

/**
 * Чат
 * @param {Object} data
 * @param {Object[]} data.activeModules
 * @param {Object} data.helpers
 * @returns {boolean}
 */
export const setAbilityByModuleChat = ({ activeModules, helpers }) => {
  const module = getActiveModule(activeModules, 'chat')

  if (module !== undefined) {
    helpers.allowReadMenuItemForRoutes([
      'admin-messages',
    ])

    helpers.allowCrudRoutes([
      'admin-messages',
    ])

    helpers.allowActionOnRoutes(userActions.ENABLE_CHAT, [
      'admin-main',
      'admin-messages',
    ])

    return true
  }

  return false
}
