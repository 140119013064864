import storage from '@/helpers/storage'
import ApiUserSettings from 'ApiRest/Api/UserSettings'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async setTheme({ commit }, payload) {
    commit('setTheme', payload)

    await ApiUserSettings.put({
      name: 'theme-setting',
      data: {
        theme: {
          className: payload,
        },
      },
    })
  },

  async getTheme({ commit }) {
    const localSavedTheme = storage.get('adminSavedTheme')

    if (localSavedTheme) {
      return commit('setTheme', localSavedTheme)
    }

    commit('setIsLoading', true)

    const { data } = await ApiUserSettings.get({
      name: 'theme-setting',
    })

    const settingsSavedTheme = data?.data?.theme?.className ?? null

    if (settingsSavedTheme) {
      commit('setTheme', settingsSavedTheme)
    } else {
      commit('setTheme')
    }

    return commit('setIsLoading', false)
  },
}
