// TODO: cleanup
export default () => ({
  options: {
    managers: [],
    services: [],
    agreements: [],
    customerRelationshipType: [],
  },

  settings: {},
})
