import { ApiCourseManagementOrderList } from 'ApiRest/Api/CourseManagement/Order/OrderList'

export default {
  async fetchOrders({ commit }, filters = {}) {
    const { data, headers } = await ApiCourseManagementOrderList.get(filters)

    commit('setFilters', filters)
    commit('setOrders', data)
    commit('setPagination', headers)
  },
}
