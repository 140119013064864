import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductCategories =
  new (class extends ApiBase {
    /**
     * Виды продукта
     * @returns {Promise}
     */
    get() {
      return this._GET(`/product/options/categories`)
    }
  })()
