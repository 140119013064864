import { ApiOnlineLessonOption } from 'ApiRest/Api/OnlineLesson/Option'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchOptionSchools({ commit }) {
    const { data } = await ApiOnlineLessonOption.getSchools()

    commit('setOption', {
      key: 'schools',
      value: data,
    })
  },

  async fetchOptionStreams({ commit }, params) {
    const { data } = await ApiOption.getStreams(params)

    commit('setOption', {
      key: 'streams',
      value: data,
    })
  },

  async fetchOptionEducationTypes({ commit }, params) {
    const { data } = await ApiOption.getEducationTypes(params)

    commit('setOption', {
      key: 'educationTypes',
      value: data,
    })
  },

  async fetchOptionGrades({ commit }, params) {
    const { data } = await ApiOption.getGrades(params)

    commit('setOption', {
      key: 'grades',
      value: data,
    })
  },

  async fetchOptionStudents({ commit }, params) {
    const { data } = await ApiOnlineLessonOption.getStudents(params)

    commit('setOption', {
      key: 'students',
      value: data,
    })
  },
}
