import ApiOption from 'ApiRest/Api/Option'
import { ApiProductManagementRequest } from 'ApiRest/Api/ProductManagement/Requests/Request'

export default {
  async fetchRequest({ commit, state }) {
    const { data } = await ApiProductManagementRequest.get(state.requestId)

    commit('setRequest', data)
  },

  async fetchRoles({ commit }) {
    const { data } = await ApiOption.getPublicRoles()

    commit('setOptions', {
      path: 'roles',
      value: data,
    })
  },
}
