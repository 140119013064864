import ApiOption from 'ApiRest/Api/Option'
import { ApiSupportFAQItem } from 'ApiRest/Api/Support/FAQ/Item'
import { ApiSupportFAQList } from 'ApiRest/Api/Support/FAQ/List'
import { ApiSupportFAQTopic } from 'ApiRest/Api/Support/FAQ/Topic'
import { ApiSupportFaqTreeSort } from 'ApiRest/Api/Support/FAQ/TreeSort'

export default {
  async resetState({ commit }) {
    commit('resetState')
  },

  async fetchFAQList({ commit }, parameters) {
    const params = {
      expand: 'items, status, items.status, educationType',
    }

    const { data } = await ApiSupportFAQList.get({
      ...parameters,
      ...params,
    })

    commit('setTopicWithItems', data)
  },

  async updateFAQList(context, payload) {
    await ApiSupportFaqTreeSort.post(payload)
  },

  async fetchTheTopic({ commit }, topicId) {
    const params = {
      expand: 'items, schools, status, educationTypes',
    }

    const { data } = await ApiSupportFAQTopic.get(topicId, params)

    commit('setTheTopic', data)
  },

  async createTheTopic({ state, commit }) {
    const { data } = await ApiSupportFAQTopic.post(state.theTopic)

    commit('setTheTopic', data)
  },

  async updateTheTopic({ commit }, { topicId, payload }) {
    const { data } = await ApiSupportFAQTopic.patch(topicId, payload)

    commit('setTheTopic', data)
  },

  async removeTheTopic(context, topicId) {
    await ApiSupportFAQTopic.delete(topicId)
  },

  async fetchTheQuestion({ commit }, { topicId, itemId }) {
    const params = {
      expand: 'status',
    }

    const { data } = await ApiSupportFAQItem.get(topicId, itemId, params)

    commit('setTheQuestion', data)
  },

  async createQuestion({ state, commit }, topicId) {
    const { data } = await ApiSupportFAQItem.post(topicId, state.theQuestion)

    commit('setTheQuestion', data)
  },

  async updateTheQuestion({ commit }, { topicId, itemId, payload }) {
    const { data } = await ApiSupportFAQItem.patch(topicId, itemId, payload)

    commit('setTheQuestion', data)
  },

  async removeTheQuestion(context, { topicId, itemId }) {
    await ApiSupportFAQItem.delete(topicId, itemId)
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      path: 'schools',
      value: data,
    })
  },

  async fetchMaterialTypes({ commit }) {
    const { data } = await ApiOption.getMaterialTypes()

    commit('setOption', {
      path: 'materialTypes',
      value: data,
    })
  },

  async fetchEducationTypes({ commit }) {
    const { data } = await ApiOption.getEducationTypes()

    commit('setOption', {
      path: 'educationTypes',
      value: data,
    })
  },

  async fetchStatuses({ commit }) {
    const { data } = await ApiOption.getWikiGroupStatuses()

    commit('setOption', {
      path: 'statuses',
      value: data,
    })
  },
}
