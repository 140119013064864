import Constants from 'Constants'

export default {
  customer(state) {
    return state.order.customer
  },

  citizenship(state, getters) {
    return getters.customer.citizenship
  },

  passport(state, getters) {
    return getters.customer.passport
  },

  isCitizenshipRussia(state, getters) {
    return getters.citizenship?.id === Constants.citizenship.RUSSIAN_FEDERATION
  },

  isCitizenshipOtherCountry(state, getters) {
    return getters.citizenship?.id === Constants.citizenship.OTHER_COUNTRY
  },

  hasClientDocuments(state) {
    return state.order.clientDocuments.length > 0
  },
}
