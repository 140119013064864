const routes = [
  {
    path: '/online-lessons',
    name: 'admin-online-lessons',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () => import('@/pages/onlineLesson/OnlineLessons.vue'),
  },
  {
    path: '/online-lesson/create',
    name: 'admin-the-online-lesson-create',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () => import('@/pages/onlineLesson/TheOnlineLesson.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/online-lesson/:onlineLessonId(\\d+)',
    name: 'admin-the-online-lesson',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () => import('@/pages/onlineLesson/TheOnlineLesson.vue'),
  },

  {
    path: '/online-lesson-rooms',
    name: 'admin-online-lesson-rooms',
    meta: {
      accessControlGroup: 'online-room',
    },
    component: () => import('@/pages/onlineLessonRoom/OnlineLessonRooms.vue'),
  },
  {
    path: '/online-lesson-room/create',
    name: 'admin-the-online-lesson-room-create',
    meta: {
      accessControlGroup: 'online-room',
    },
    component: () => import('@/pages/onlineLessonRoom/TheOnlineLessonRoom.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/online-lesson-room/:onlineLessonRoomId(\\d+)',
    name: 'admin-the-online-lesson-room',
    meta: {
      accessControlGroup: 'online-room',
    },
    component: () => import('@/pages/onlineLessonRoom/TheOnlineLessonRoom.vue'),
  },
  {
    path: '/online-lesson/update-recordings/:onlineLessonId(\\d+)',
    name: 'admin-the-online-lesson-update-recordings',
    meta: {
      accessControlGroup: 'online-lesson',
    },
    component: () => import('@/pages/onlineLesson/Recordings.vue'),
  },
  {
    path: '/online-lessons/:onlineLessonId(\\d+)/video/:videoId(\\d+)',
    name: 'admin-the-online-lesson-preview',
    component: () => import('@/pages/onlineLesson/TheOnlineLessonPreview.vue'),
  },
]

export default routes
