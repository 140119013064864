import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementOptionSubscriptions =
  new (class extends ApiBase {
    /**
     * Список подписок
     * @returns {Promise}
     */
    get() {
      return this._GET('/product/options/subscriptions')
    }
  })()
