import convertEmptyDataToDefaultValue from '@/helpers/ConvertEmptyDataToDefaultValue'
import ApiBase from 'ApiRest/ApiBase'
import { cloneDeep } from 'lodash'

export default new (class extends ApiBase {
  /**
   * @private
   * @param {Object} data
   * @returns {Object}
   */
  normalizeData(data) {
    const isDataEmpty = Object.keys(data).length === 0

    if (isDataEmpty) {
      return data
    }

    const normalizedData = cloneDeep(data)

    convertEmptyDataToDefaultValue(normalizedData)

    return normalizedData
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    const url = `/enrollment/partners/${id}`

    return this._GET(url)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    const url = `/enrollment/partners/${id}`

    return this._PATCH(url, this.normalizeData(data))
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    const url = `/enrollment/partners/${id}`

    return this._DELETE(url)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    const url = `/enrollment/partners`

    return this._POST(url, this.normalizeData(data))
  }
})()
