export default () => ({
  question: {
    id: null,
    name: null,
    description: null,
    maxPointsNumber: null,
    assessmentCondition: null,
    checkPrice: null,
    topic: null,
    status: {
      id: null,
      name: null,
    },
    type: null,
    checkType: null,
    content: null,
    materials: null,
    commentModeration: null,
    commentApproved: null,
    commentFixRequired: null,
    usedInTests: [],
    isDeleted: false,
    aICheckType: null,
  },
})
