import ApiBase from 'ApiRest/ApiBase'

export default new (class Group extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/wiki/groups`, data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/wiki/groups/${id}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/wiki/groups/${id}`, data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/wiki/groups/${id}`)
  }
})()
