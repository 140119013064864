import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setHomeworks(state, value) {
    state.homeworks = value
  },

  setTheHomework(state, value) {
    state.homework = value
  },

  setHomeworkValue(state, { key, value }) {
    Vue.set(state.homework, key, value)
  },

  setOptions(state, { key, value }) {
    Vue.set(state.options, key, value)
  },

  addAttachment(state, payload = null) {
    if (!state.homework?.managerCommentAttachments) {
      Vue.set(state.homework, 'managerCommentAttachments', [])
    }

    state.homework.managerCommentAttachments = [
      ...state.homework.managerCommentAttachments,
      payload,
    ]
  },

  setAttachment(state, { index, file }) {
    Vue.set(state.homework.managerCommentAttachments, index, file)
  },

  removeAttachment(state, index) {
    state.homework.managerCommentAttachments.splice(index, 1)
  },
}
