import ApiBase from 'ApiRest/ApiBase'

export const ApiSendingOptionsTemplates = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/sending/option/templates')
  }

  /**
   *
   * @param {number} templateId
   * @returns {Promise}
   */
  delete(templateId) {
    return this._DELETE(`/sending/option/templates/${templateId}`)
  }
})()
