import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementNavigationBannerFormatOptions =
  new (class extends ApiBase {
    /**
     * @returns {Promise}
     */
    get() {
      return this._GET(`/navigation-banner/get-format-options`)
    }
  })()
