import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementSettings = new (class extends ApiBase {
  /**
   * Получение настроек витрины курсов
   * @returns {Promise}
   */
  get() {
    return this._GET('/products/settings')
  }

  /**
   * Изменение настроек витрины курсов
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH('/products/settings', data)
  }
})()
