export default () => ({
  partner: {
    name: null,
    shortName: null,
    legalName: null,
    city: null,
    educationTypes: [],
    grades: [],
    schools: [],
    issuedDatePartner: null,
    beginDatePartner: null,

    hasMontessori: false,
    hasAlternativeTest: false,
    hideOnlineLessons: false,
    restrictEmails: false,

    inn: null,
    kpp: null,
    ogrn: null,

    directorFirstName: null,
    directorLastName: null,
    directorMiddleName: null,

    coordinators: [],

    address: null,
    legalAddress: null,
    phone: null,
    email: null,
    site: null,
    licenseInfo: null,
  },

  comments: [],

  options: {
    educationTypes: [],
    grades: [],
    schools: [],
  },
})
