import ApiOption from 'ApiRest/Api/Option'
import ApiTheContent from 'ApiRest/Api/Wiki/Content'
import ApiContent from 'ApiRest/Api/Wiki/Content/List'
import ApiTheGroup from 'ApiRest/Api/Wiki/Group'
import ApiGroups from 'ApiRest/Api/Wiki/Group/List'
import ApiTreeSort from 'ApiRest/Api/Wiki/Group/TreeSort'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchWiki({ commit }, params) {
    const { data } = await ApiGroups.get(params)

    commit('setWiki', data)
  },

  async updateWikiOrder(context, payload) {
    await ApiTreeSort.patch(payload)
  },

  async fetchTheWiki({ commit }, wikiId) {
    const { data } = await ApiTheGroup.get(wikiId)

    commit('setTheWiki', data)
  },

  async createTheWiki({ commit }, payload) {
    const { data } = await ApiTheGroup.post(payload)

    commit('setTheWiki', data)
  },

  async updateTheWiki({ commit }, { wikiId, payload }) {
    const { data } = await ApiTheGroup.patch(wikiId, payload)

    commit('setTheWiki', data)
  },

  async deleteTheWiki(context, wikiId) {
    await ApiTheGroup.delete(wikiId)
  },

  async fetchOptionWikiStatuses({ commit }) {
    const { data } = await ApiOption.getWikiGroupStatuses()

    commit('setOption', {
      key: 'statuses',
      value: data,
    })
  },

  async fetchOptionMaterialTypes({ commit }) {
    const { data } = await ApiOption.getMaterialTypes()

    commit('setOption', {
      key: 'materialTypes',
      value: data,
    })
  },

  async fetchOptionSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      key: 'schools',
      value: data,
    })
  },

  async fetchOptionWikiContent({ commit }) {
    const { data } = await ApiContent.get()

    commit('setOption', {
      key: 'content',
      value: data,
    })
  },

  async fetchTheWikiContent({ commit }, contentId) {
    const { data } = await ApiTheContent.get(contentId)

    commit('setTheWikiContent', data)
  },
}
