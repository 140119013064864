import advertisements from './advertisements/store'
import analytics from './analytics/store'
import banner from './banner/store'
import banners from './banners/store'
import categories from './categories/store'
import navigationBanner from './navigationBanner/store'
import order from './order/store'
import orders from './orders/store'
import parameter from './parameter/store'
import parameters from './parameters/store'
import product from './product/store'
import productList from './productList/store'
import question from './question/store'
import request from './request/store'
import requests from './requests/store'
import settings from './settings/store'

export default {
  namespaced: true,

  modules: {
    advertisements,
    analytics,
    banner,
    banners,
    categories,
    navigationBanner,
    order,
    orders,
    parameter,
    parameters,
    product,
    productList,
    request,
    requests,
    settings,
    question,
  },
}
