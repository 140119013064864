export default () => ({
  subscriptions: [],

  subscription: {
    id: null,
    name: '',
    expirationDate: null,
    daysBeforePeriodToCreateInvoice: null,
    firstInvoiceDaysToPay: null,
    firstPeriodStartDate: null,
    furtherInvoicesDaysToPay: null,
    canBeEdited: true,
    paymentInterval: null,
    paymentIntervalDays: null,
    daysBeforeBlock: null,
    description: '',
  },

  subscribers: [],

  options: {
    daysBeforeBlock: [],
    paymentIntervals: [],
  },

  pagination: null,
  filters: null,
})
