import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiAnalyticsStudentEnrolledStudents = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `/analytic-student/student-enrolled/students-by-periods?${urlParams}`

    return this._GET(url)
  }

  /**
   * @param {Object} params
   * @returns {string}
   */
  downloadUrl(params = {}) {
    const urlParams = UrlHelper.param(params)
    const url = `${this.BASE_URL}/analytic-student/student-enrolled/download-report?`

    return `${url}${urlParams}`
  }
})()
