import { ApiBanner } from 'ApiRest/Api/Banners/Banner'
import { ApiBannerList } from 'ApiRest/Api/Banners/BannerList'

export default {
  async fetchBanners({ commit }, filters = {}) {
    const { data, headers } = await ApiBannerList.get(filters)

    commit('setFilters', filters)
    commit('setBanners', data)
    commit('setPagination', headers)
  },

  removeBanner(context, bannerId) {
    return ApiBanner.delete(bannerId)
  },
}
