import { ApiCourseManagementOrderClientDocument } from 'ApiRest/Api/CourseManagement/Order/ClientDocument'
import { ApiCourseManagementOrderStatusOptions } from 'ApiRest/Api/CourseManagement/Order/Option/OrderStatusOptions'
import { ApiCourseManagementOrder } from 'ApiRest/Api/CourseManagement/Order/Order'

export default {
  async fetchOrder({ commit, state }) {
    const { data } = await ApiCourseManagementOrder.get(state.orderId)

    commit('setOrder', data)
  },

  async updateOrder({ commit, state }) {
    const { data } = await ApiCourseManagementOrder.patch(
      state.orderId,
      state.order,
    )

    commit('setOrder', data)
  },

  async fetchOrderStatusOptions({ commit }) {
    const { data } = await ApiCourseManagementOrderStatusOptions.get()

    commit('setOptionsList', {
      path: 'status',
      value: data,
    })
  },

  removeClientDocument(context, { orderId, fileId }) {
    return ApiCourseManagementOrderClientDocument.delete({
      orderId,
      fileId,
    })
  },
}
