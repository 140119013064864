export default {
  /**
   * @param {Object} state
   * @param {number | null} pollingId
   */
  setPollingId(state, pollingId) {
    state.pollingId = pollingId
  },

  unsetPollingId(state) {
    state.pollingId = null
  },
}
