import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolOrder = new (class extends ApiBase {
  /**
   * Получение данных об определённом заказе
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/online-school/order/${id}`)
  }

  /**
   * Изменение заказа
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/online-school/order/${id}`, data)
  }

  /**
   * Изменение продуктов в заказе
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/online-school/order/${id}/update-content`, data)
  }
})()
