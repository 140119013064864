export default () => ({
  category: {
    isActive: false,
    name: '',
    parent: null,
    parentId: null,

    seo: {
      prettyLink: '',
      title: '',
      description: '',
      keywords: '',
    },
  },

  categories: [],
})
