/**
 *
 * @param {Array} statements
 * @returns {Array}
 */
const getStatementsIntramural = (statements) => {
  const groupedItems = []

  statements.forEach((statementItem) => {
    const groupIndex = groupedItems.findIndex(
      (item) =>
        item.type.id === statementItem.type.id &&
        item.academicYearType.id === statementItem.academicYearType.id,
    )

    if (groupIndex !== -1) {
      groupedItems[groupIndex].items.push(statementItem)
    } else {
      const item = {
        group: statementItem.group,
        type: statementItem.type,
        academicYearType: statementItem.academicYearType,
        items: [
          statementItem,
        ],
      }

      groupedItems.push(item)
    }
  })

  return groupedItems
}

export default {
  /**
   * @param {Object} state
   * @returns {Array}
   */
  statementsOnlineSchoolExtramural(state) {
    return state.statementOnlineSchool.extramural
  },

  /**
   * @param {Object} state
   * @returns {Array}
   */
  statementsShowcaseExtramural(state) {
    return state.statementShowcase.extramural
  },

  /**
   * @param {Object} state
   * @returns {Array}
   */
  statementsOnlineSchoolIntramural(state) {
    return getStatementsIntramural(state.statementOnlineSchool.intramural)
  },

  /**
   * @param {Object} state
   * @returns {Array}
   */
  statementsShowcaseIntramural(state) {
    return getStatementsIntramural(state.statementShowcase.intramural)
  },

  statementFullTimeEducationByPeriod(state) {
    return state.statementFullTimeEducation?.statementsByPeriod ?? []
  },

  getStatementParameters(state) {
    return {
      student: {
        id: state.studentId,
      },

      academicYearPreset: {
        id: state.statementPeriod?.id,
      },
    }
  },

  getStatementParametersWithCurrentGroupsOnly(state, getters) {
    return {
      ...getters.getStatementParameters,

      currentGroupsOnly: state.currentGroupsOnly ? 1 : 0,
    }
  },
}
