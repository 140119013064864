import { ApiCourseManagementProductCustomerRelationshipTypes } from 'ApiRest/Api/CourseManagement/Products/Option/ProductCustomerRelationshipTypes'
import { ApiOnlineSchoolSettings } from 'ApiRest/Api/OnlineSchool/Settings/Tariffs'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchOptionAgreements({ commit }) {
    const { data } = await ApiOption.getAgreements()

    commit('setOption', {
      key: 'agreements',
      value: data,
    })
  },

  async fetchOptionManagers({ commit }) {
    const params = {
      school: 1,
    }

    const { data } = await ApiOption.getManagers(params)

    commit('setOption', {
      key: 'managers',
      value: data,
    })
  },

  async fetchOptionInvoiceServices({ commit }) {
    const params = {
      school: 1,
    }

    const { data } = await ApiOption.getInvoiceServices(params)

    commit('setOption', {
      key: 'services',
      value: data,
    })
  },

  async fetchCustomerRelationshipTypeOptions({ commit }) {
    const { data } =
      await ApiCourseManagementProductCustomerRelationshipTypes.get()

    commit('setOption', {
      key: 'customerRelationshipType',
      value: data,
    })
  },

  async fetchSettings({ commit }) {
    const { data } = await ApiOnlineSchoolSettings.get()

    commit('setSettings', data)
  },

  async saveSettings({ state, commit }) {
    const { data } = await ApiOnlineSchoolSettings.patch(state.settings)

    commit('setSettings', data)
  },

  async fetchOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchOptionAgreements'),
      dispatch('fetchOptionManagers'),
      dispatch('fetchOptionInvoiceServices'),
      dispatch('fetchCustomerRelationshipTypeOptions'),
    ])
  },
}
