import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductAgreements = new (class extends ApiBase {
  /**
   * Список сохраненных соглашений
   * @returns {Promise}
   */
  get() {
    return this._GET(`/online-school/product/options/agreements`)
  }
})()
