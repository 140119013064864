import ApiBase from 'ApiRest/ApiBase'

export const ApiCoursesLiveEventApplication = new (class extends ApiBase {
  /**
   * @param {number} productId
   * @returns {Promise}
   */
  get(productId) {
    const url = `/courses/product/${productId}/download-live-event-application`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
