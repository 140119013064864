import ApiBase from 'ApiRest/ApiBase'

export const ApiProductsAdvertisement = new (class extends ApiBase {
  /**
   * Получение данных об определённой рекламе
   * @param {number} advertisementId
   * @returns {Promise}
   */
  get(advertisementId) {
    return this._GET(`/product/ads/${advertisementId}`)
  }

  /**
   * Создание рекламы
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/product/ads`, data)
  }

  /**
   * Изменение рекламы
   * @param {number} advertisementId
   * @param {Object} data
   * @returns {Promise}
   */
  put(advertisementId, data) {
    return this._PUT(`/product/ads/${advertisementId}`, data)
  }

  /**
   * Удаление рекламы
   * @param {number} advertisementId
   * @returns {Promise}
   */
  delete(advertisementId) {
    return this._DELETE(`/product/ads/${advertisementId}`)
  }
})()
