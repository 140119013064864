import { RESET_STATE } from '@/helpers/ResetState'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setOnlineLessons(state, payload) {
    state.onlineLessons = payload
  },

  /**
   * @param {Object} state
   * @param {Object} payload
   */
  setTheOnlineLesson(state, payload) {
    Vue.set(state, 'theOnlineLesson', payload)

    Vue.set(state, 'theOnlineLessonInitial', cloneDeep(payload))
    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = false
  },

  /**
   * @param {Object} state
   * @param {boolean} payload
   */
  setTheOnlineLessonIsRunning(state, payload) {
    state.isOnlineLessonRunning = payload
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.key
   * @param {number|string|boolean|Array|null} args.value
   */
  setTheOnlineLessonValue(state, { key, value }) {
    Vue.set(state.theOnlineLesson, key, value)

    if (
      ![
        'repeatType',
        'repeatBeginDate',
        'repeatEndDate',
      ].includes(key)
    ) {
      state.isModifiedTheOnlineLessonExceptRepeatTypeFields = true
    }
  },

  /**
   * @param {Object} state
   * @param {Object} args
   * @param {string} args.key
   * @param {Array} args.value
   */
  setOption(state, { key, value }) {
    Vue.set(state.option, key, value)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setTheOnlineLessonAnalytics(state, payload) {
    state.analytics = payload
  },

  setTheOnlineLessonBigBlueButtonIsDisabled(state, value) {
    state.isBigBlueButtonLessonDisabled = value
  },

  /**
   * @param {Object} state
   * @param {number} payload
   */
  setTheOnlineLessonCountByRepeatBeginEndDate(state, payload) {
    state.newRepeatBeginEndDateCount = payload
  },

  /**
   * @param {Object} state
   * @param {boolean} payload
   */
  setTheOnlineLessonIsModifiedExceptRepeatEndDate(state, payload) {
    state.isModifiedTheOnlineLessonExceptRepeatTypeFields = payload
  },
}
