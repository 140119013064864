import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductCertificateUserList =
  new (class extends ApiBase {
    /**
     * Список пользователей с сертификатами
     * @param {Object} params
     * @returns {Promise}
     */
    get(params) {
      const urlParams = UrlHelper.param(params)
      const url = `/courses/product/certificate-issue/users?${urlParams}`

      return this._GET(url)
    }
  })()
