export default () => ({
  orderId: null,
  order: null,
  products: [],
  logs: [],
  invoices: [],
  options: {
    status: [],
    products: [],
  },
})
