import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductCertificateRevoke =
  new (class extends ApiBase {
    /**
     * Отзыв сертификата
     * @param {Object} parameters
     * @returns {Promise}
     */
    delete(parameters) {
      const url = `/courses/product/certificate-issue/revoke?${this.getUrlParams(
        parameters,
      )}`

      return this._DELETE(url)
    }
  })()
