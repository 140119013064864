import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonRecordings = new (class extends ApiBase {
  /**
   * Записи уроков
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  get(onlineLessonId) {
    return this._GET(`/online-lessons/${onlineLessonId}/video`)
  }

  patch(onlineLessonId, data) {
    return this._PATCH(`/online-lessons/${onlineLessonId}/video`, data)
  }
})()
