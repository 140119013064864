<template>
  <UiKitModal2 :name="name" :click-to-close="false" @before-open="onBeforeOpen">
    <div class="p-4">
      <!-- TODO: i18n -->
      <h5 class="modal-title mb-4">Внимание</h5>

      <div>
        <!-- TODO: i18n -->
        <div class="mb-4">
          Вы действительно хотите удалить файл <b>{{ fileName }}</b
          >?
        </div>

        <div class="d-flex justify-content-lg-end justify-content-between">
          <!-- TODO: i18n -->
          <UiKitButton
            class="btn-danger btn-lg"
            label="Да, удалить"
            @click="onClickBtnRemoveFile"
          />

          <!-- TODO: i18n -->
          <UiKitButton
            class="btn-secondary btn-lg ml-4"
            label="Отмена"
            @click="onClickBtnCloseModal"
          />
        </div>
      </div>
    </div>
  </UiKitModal2>
</template>

<script>
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import { uniqueId } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalConfirmRemoveFileUploadMany',

  components: {
    UiKitButton,
    UiKitModal2,
  },

  data() {
    return {
      name: uniqueId('ModalConfirmRemoveFileUploadMany'),
      file: null,
    }
  },

  computed: {
    fileName() {
      return this.file?.name ?? null
    },
  },

  methods: {
    onBeforeOpen({ params }) {
      this.file = params
    },

    onClickBtnCloseModal() {
      this.$emit('cancel')

      this.hide()
    },

    onClickBtnRemoveFile() {
      this.$emit('confirm')
      this.hide()
    },

    /**
     * @public
     * @param {Object} file
     */
    show(file) {
      this.$modal.show(this.name, file)
    },

    hide() {
      this.$modal.hide(this.name)
    },
  },
})
</script>
