import { ApiOnlineSchoolOrders } from 'ApiRest/Api/OnlineSchool/Orders/OrdersList'

export default {
  async fetchOrders({ commit }, filters = {}) {
    const { data, headers } = await ApiOnlineSchoolOrders.get(filters)

    commit('setFilters', filters)
    commit('setOrders', data)
    commit('setPagination', headers)
  },
}
