import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolCallRequests = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    return this._GET(`/call-request?${this.getUrlParams(parameters)}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/call-request/${id}`, {
      status: data,
    })
  }
})()
