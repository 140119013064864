import Constants from 'Constants'

export default {
  isUserInFinalSelection(state) {
    return (userId) =>
      state.finalUsers.some((user) => Number(user.id) === userId)
  },

  isSendingSent(state) {
    return state.sending.status?.id === Constants.sendingStatus.SENT
  },
}
