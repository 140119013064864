<template>
  <div
    class="d-flex"
    :class="`justify-content-${alignX} align-items-${alignY}`"
  >
    <div
      class="spinner-border spinner-border-lg text-center"
      :class="$style[`spinner-border-size-${size}`]"
      :style="{ color }"
      role="status"
    >
      <span class="sr-only">{{ $t('ui.loading') }}</span>
    </div>

    <span v-if="canShowText" class="ml-3">{{ loaderText }}</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingElement',

  props: {
    color: {
      type: String,
      default: '#ffc107',
    },

    alignX: {
      type: String,
      default: 'center',
    },

    alignY: {
      type: String,
      default: 'center',
    },

    size: {
      type: String,
      default: 'large',
      validator(value) {
        return [
          'small',
          'middle',
          'large',
        ].includes(value)
      },
    },

    canShowText: {
      type: Boolean,
      default: true,
    },

    text: {
      type: String,
      default: '',
    },
  },

  computed: {
    loaderText() {
      return this.text || this.$t('ui.loading')
    },
  },
}
</script>

<style module lang="scss">
.spinner-border-size {
  &-small {
    width: 1rem !important;
    height: 1rem !important;
  }

  &-middle {
    width: 2rem !important;
    height: 2rem !important;
  }

  &-large {
    width: 3rem !important;
    height: 3rem !important;
  }
}
</style>
