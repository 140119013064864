export default () => ({
  rooms: [],
  roomsPagination: null,

  theRoom: {
    name: null,
    lessonUrl: null,
    additionalInfo: null,
  },
})
