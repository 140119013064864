<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHideModal">
    <template #header>
      {{ t('header') }}
    </template>

    <template #body>
      <FormEditLeader v-if="hasLeaderBlockValue" v-model="leaderBlock" />

      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_save')"
          @click="onClickBtnSave"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import FormEditLeader from '@/components/productManagement/product/content/items/leaders/FormEditLeader.vue'
import { checkForErrorsMessage } from '@/helpers/ValidationHelper'
import { showToast } from '@/helpers/toast'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal from '@/ui/UiKitModal.vue'
import { useVuelidate } from '@vuelidate/core'
import { isNull } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalEditLeader',

  components: {
    UiKitModal,
    UiKitButton,
    FormEditLeader,
  },

  setup() {
    return {
      v$: useVuelidate({
        $stopPropagation: true,
      }),
    }
  },

  data() {
    return {
      leaderBlock: null,
    }
  },

  computed: {
    hasLeaderBlockValue() {
      return !isNull(this.leaderBlock)
    },
  },

  methods: {
    async onClickBtnSave() {
      if (!(await this.v$.$validate())) {
        showToast(checkForErrorsMessage, 'error')

        return false
      }

      this.$emit('change', this.leaderBlock)
      this.hide()

      return true
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     * @param {Object} value
     */
    show(value) {
      this.leaderBlock = value
      this.$refs.modal.show()
    },

    hide() {
      this.emitHide()
      this.$refs.modal.hide()
    },

    onHideModal() {
      this.emitHide()
    },

    emitHide() {
      this.$emit('hide')
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.leaders.modal_edit.${name}`,
      )
    },
  },
})
</script>
