import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const urlParams = UrlHelper.param(parameters)
    const url = `/user/student/analytics?${urlParams}`

    return this._GET(url)
  }
})()
