import { ApiCourseManagementProductCategory } from 'ApiRest/Api/CourseManagement/Products/Category/ProductCategory'
import { ApiCourseManagementProductCategorySort } from 'ApiRest/Api/CourseManagement/Products/Category/ProductCategorySort'
import { ApiCourseManagementProductCategories } from 'ApiRest/Api/CourseManagement/Products/Option/ProductCategories'

export default {
  async fetchCategories({ commit }) {
    const { data } = await ApiCourseManagementProductCategories.get()

    commit('setCategories', data)
  },

  async fetchCategory({ commit }, categoryId) {
    const { data } = await ApiCourseManagementProductCategory.get(categoryId)

    commit('setCategory', data)
    commit('setCategoryParentId', data.parent?.id)
  },

  async createTheCategory({ state }) {
    await ApiCourseManagementProductCategory.post(state.category)
  },

  async updateTheCategory({ state }) {
    await ApiCourseManagementProductCategory.patch(
      state.category,
      state.category.id,
    )
  },

  async removeTheCategory({ state }) {
    await ApiCourseManagementProductCategory.delete(state.category.id)
  },

  async sortCategories({ state }) {
    await ApiCourseManagementProductCategorySort.patch(state.categories)
  },
}
