import ApiBase from 'ApiRest/ApiBase'

export const ApiAssingDiagnosticsExtramural = new (class extends ApiBase {
  /**
   * Назначение заочной диагностики
   * @param {number} applicationId
   * @param {Object} data
   * @returns {Promise}
   */
  post(applicationId, data) {
    const url = `/diagnostics/applications/${applicationId}/assign-extramural-diagnostics`

    return this._POST(url, data)
  }
})()
