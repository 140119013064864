import { ApiAnalyticsStudentEnrolledSchools } from 'ApiRest/Api/Analytics/StudentEnrolledSchools'
import { ApiAnalyticsStudentEnrolledSchoolsCost } from 'ApiRest/Api/Analytics/StudentEnrolledSchoolsCost'
import { ApiAnalyticsStudentEnrolledStudents } from 'ApiRest/Api/Analytics/StudentEnrolledStudents'

export default {
  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @param {Object} filters
   * @returns {Promise}
   */
  async fetchStudentEnrolledAnalyticsSchools({ commit }, filters) {
    const { data } = await ApiAnalyticsStudentEnrolledSchools.get(filters)

    commit('setStudentEnrolledAnalyticsSchools', data)
  },

  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @param {Object} filters
   * @returns {Promise}
   */
  async fetchStudentEnrolledAnalyticsCost({ commit }, filters) {
    const { data } = await ApiAnalyticsStudentEnrolledSchoolsCost.get(filters)

    commit('setStudentEnrolledAnalyticsCost', data.costTotal)
  },

  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @param {Object} filters
   * @returns {Promise}
   */
  async fetchStudentEnrolledAnalyticsStudents({ commit }, filters) {
    const response = await ApiAnalyticsStudentEnrolledStudents.get(filters)

    commit('setStudentEnrolledAnalyticsStudents', response.data)

    commit('setStudentEnrolledFilters', {
      students: response.data,
      filters,
    })

    commit('setStudentEnrolledPagination', response)
  },
}
