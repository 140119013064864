import ApiBase from 'ApiRest/ApiBase'

export const ApiSendingTemplate = new (class extends ApiBase {
  /**
   * @param {number} templateId
   * @returns {Promise}
   */
  delete(templateId) {
    return this._DELETE(`/sending-template/${templateId}`)
  }
})()
