export default () => ({
  banners: [],
  pagination: null,
  filters: null,

  bannerId: null,
  banner: {
    name: '',
    index: null,
    image: null,
    url: '',
    position: null,
    status: null,
  },
  options: {
    position: [],
    status: [],
  },
})
