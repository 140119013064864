import { ApiCourseManagementProductTypes } from 'ApiRest/Api/CourseManagement/Products/Option/ProductTypes'
import { ApiCourseManagementProductList } from 'ApiRest/Api/CourseManagement/Products/ProductList'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchProductList({ commit }, params) {
    const expandParams = {
      expand: 'extraCategories, holding, mainCategory, type, visibility',
    }

    const { data, headers } = await ApiCourseManagementProductList.get({
      ...params,
      ...expandParams,
    })

    commit('setFilters', params)
    commit('setProductList', data)
    commit('setPagination', headers)
  },

  async fetchSchoolOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schoolOptionList',
      value: data,
    })
  },

  async fetchTypeOptions({ commit }) {
    const { data } = await ApiCourseManagementProductTypes.get()

    commit('setOptionsList', {
      path: 'kindOptionList',
      value: data,
    })
  },

  async fetchProductOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchSchoolOptions'),
      dispatch('fetchTypeOptions'),
    ])
  },
}
