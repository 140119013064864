export default () => ({
  settings: {
    seo: {
      main: {
        title: '',
        description: '',
        keywords: '',
        projectDescription: '',
      },
      catalog: {
        title: '',
        description: '',
        keywords: '',
      },
    },
  },
})
