export default () => ({
  wiki: [],
  wikiWithChildren: [],
  wikiWithoutChildren: [],
  theWiki: {
    id: null,
    name: '',
    parent: null,
    school: [],
    status: null,
    wikiContent: [],
    children: [],
  },
  theWikiContent: {},
  option: {
    schools: [],
    statuses: [],
    materialTypes: [],
    content: [],
  },
})
