import ApiMessages from 'ApiRest/Api/User/Messages'

export default {
  async fetchMessages({ commit }) {
    const { data } = await ApiMessages.get()

    commit(
      'admin/navigation/setNotifications',
      {
        target: 'admin-messages',
        count: data.countUnreadMessages,
      },
      {
        root: true,
      },
    )
  },

  startPollingMessages({ dispatch, commit }) {
    dispatch('fetchMessages')

    const pollingId = setInterval(() => {
      dispatch('fetchMessages')
    }, 5000)

    commit('setPollingId', pollingId)
  },

  stopPollingMessages({ state, commit }) {
    clearInterval(state.pollingId)
    commit('unsetPollingId')
  },
}
