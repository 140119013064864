<template>
  <UiKitModal2
    :name="name"
    :click-to-close="false"
    :min-width="600"
    :min-height="400"
    width="80%"
    height="90%"
  >
    <div class="h-100 w-100 p-4 d-flex flex-column">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <!-- TODO: i18n -->
        <h1 class="modal-title">Редактирование</h1>

        <UiKitButton class="btn-lg btn-secondary" @click="onClickBtnCloseModal">
          <i class="fa fa-times"></i>
        </UiKitButton>
      </div>

      <div class="flex-1">
        <ContentWithPreloader :is-loading="isLoading">
          <ImageEditor
            :image="file"
            :original-url="originalUrl"
            @save-edited-image="onSaveEditedImage"
          />
        </ContentWithPreloader>
      </div>
    </div>
  </UiKitModal2>
</template>

<script>
import ImageEditor from '@/components/ImageEditor.vue'
import { showToast } from '@/helpers/toast'
import ContentWithPreloader from '@/ui/ContentWithPreloader.vue'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal2 from '@/ui/UiKitModal2.vue'
import ApiUpload from 'Api/Upload'
import { uniqueId } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ModalEditFileUpload',

  components: {
    ImageEditor,
    UiKitButton,
    UiKitModal2,
    ContentWithPreloader,
  },

  props: {
    file: {
      type: Object,
      default: null,
    },

    originalUrl: {
      type: String,
      default: null,
    },

    isPrivate: {
      type: Boolean,
      default: true,
    },
  },

  emits: [
    'upload-file',
  ],

  data() {
    return {
      name: uniqueId('ModalEditFileUpload'),
    }
  },

  computed: {
    originalFileId() {
      if (this.file.originalFile === 'undefined') {
        return this.file.id
      }

      return this.file.originalFile?.id ?? null
    },

    isLoading() {
      return !this.originalUrl
    },
  },

  methods: {
    onClickBtnCloseModal() {
      this.$emit('cancel')

      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$modal.show(this.name)
    },

    hide() {
      this.$modal.hide(this.name)
    },

    onSaveEditedImage(file) {
      ApiUpload.sendFile(
        file,
        () => {},
        this.isPrivate,
        this.originalFileId,
      ).then(({ data }) => {
        this.$emit('upload-file', data)
        this.hide()

        return showToast('Файл добавлен в поле с ответом')
      })
    },
  },
})
</script>
