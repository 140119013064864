export default {
  itemListSortMap(state) {
    return state.userSettings.itemListSortMap ?? {}
  },

  getItemList(state) {
    return (typeId) => state.itemListMap[typeId] ?? null
  },

  getItemListSort(state, getters) {
    return (typeId) => getters.itemListSortMap[typeId] ?? null
  },
}
