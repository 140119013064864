import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    const url = `/user/student/analytics-comment`

    return this._POST(url, data)
  }
})()
