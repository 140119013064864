import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerSendingTriggerOptions = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    return this._GET(
      `/trigger-sending/option/triggers?${this.getUrlParams(parameters)}`,
    )
  }
})()
