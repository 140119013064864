import ApiBase from 'ApiRest/ApiBase'

export const ApiProductQuestionStatuses = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/product/questions/options/statuses')
  }
})()
