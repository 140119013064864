export default () => ({
  analytics: {
    barChart: [],
    educationTypePieChart: [],
    gradePieChart: [],
    productPieChart: [],
    productTable: [],
    schoolPieChart: [],
  },

  pagination: null,
  filters: null,
})
