import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementRequest = new (class extends ApiBase {
  /**
   * @param {number} requestID
   * @returns {Promise}
   */
  get(requestID) {
    return this._GET(`/showcase/product/requests/${requestID}`)
  }
})()
