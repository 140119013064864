export default () => ({
  options: {
    schools: [],
    grades: [],
  },
  certificationsSchedule: [],

  certificationSchedule: {
    id: null,
    name: null,
    dateBegin: null,
    dateEnd: null,
    school: null,
    grades: null,
  },
})
