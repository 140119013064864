export default [
  {
    path: '/settings/trigger-sendings',
    name: 'admin-settings-trigger-sendings',
    meta: {
      accessControlGroup: 'settings-trigger-sendings',
    },
    component: () => import('@/pages/settings/TriggerSendings.vue'),
  },
  {
    path: '/settings/trigger-sending-create',
    name: 'admin-settings-create-trigger-sending',
    meta: {
      accessControlGroup: 'settings-trigger-sendings',
    },
    component: () => import('@/pages/settings/TheTriggerSending.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/settings/trigger-sendings/:triggerSendingId(\\d+)',
    name: 'admin-settings-the-trigger-sending',
    meta: {
      accessControlGroup: 'settings-trigger-sendings',
    },
    component: () => import('@/pages/settings/TheTriggerSending.vue'),
  },
]
