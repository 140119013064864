import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonEndConference = new (class extends ApiBase {
  /**
   * Закончить конференцию
   * @param {number} onlineLessonId
   * @returns {Promise<boolean>}
   */
  post(onlineLessonId) {
    return this._POST(`/online-lessons/${onlineLessonId}/end-conference`)
  }
})()
