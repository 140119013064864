import ApiBase from 'ApiRest/ApiBase'

export const ApiUserStudentAccountEducationalProgramInactive =
  new (class extends ApiBase {
    /**
     * @param {number} studentId
     * @param {Object} data
     * @returns {Promise}
     */
    put(studentId, data = {}) {
      const url = `/user/student/${studentId}/block-educational-program`

      return this._PUT(url, data)
    }
  })()
