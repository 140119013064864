export default () => ({
  newsArticle: {
    id: null,
    title: '',
    publicationDateTime: '',
    previewText: '',
    materials: [],
    status: null,
    tags: [],
    files: [],
    image: null,
    streams: [],
  },

  options: {
    streams: [],
    materialTypes: [],
    statuses: [
      {
        id: 1,
        name: 'pages.news.the_news_article.draft',
      },
      {
        id: 2,
        name: 'pages.news.the_news_article.published',
      },
    ],
  },
})
