import ApiOption from 'ApiRest/Api/Option'
import ApiRoleOptions from 'ApiRest/Api/Rbac/Option/Role'
import { ApiSendingAnalytics } from 'ApiRest/Api/Sending/Analytics'
import { ApiSendingClients } from 'ApiRest/Api/Sending/Clients'
import { ApiSendingList } from 'ApiRest/Api/Sending/List'
import { ApiSendingManagers } from 'ApiRest/Api/Sending/Managers'
import { ApiSendingOptionsDetailStatuses } from 'ApiRest/Api/Sending/Options/DetailStatuses'
import { ApiSendingOptionsTemplates } from 'ApiRest/Api/Sending/Options/Templates'
import { ApiSendingSearchClients } from 'ApiRest/Api/Sending/Search/Client'
import { ApiSendingSearchManagers } from 'ApiRest/Api/Sending/Search/Manager'
import { ApiSendingSearchStudents } from 'ApiRest/Api/Sending/Search/Student'
import { ApiSending } from 'ApiRest/Api/Sending/Sending'
import { ApiSendingStudents } from 'ApiRest/Api/Sending/Students'
import { ApiSendingTemplate } from 'ApiRest/Api/Sending/Template'

export default {
  async fetchSendings({ commit }, params) {
    const { data, headers } = await ApiSendingList.get(params)

    commit('setFilters', params)
    commit('setSendings', data)
    commit('setPagination', headers)
  },

  async fetchSending({ commit, state }, sendingId) {
    const { data } = await ApiSending.get(sendingId)

    commit('setSending', data)

    commit('setFormListValue', state.sending.filters === null)
  },

  async updateSending({ state, commit, dispatch }) {
    const { data } = await ApiSending.put(state.sending.id, state.sending)

    commit('setSending', data)

    await dispatch('fetchTemplates')
  },

  async fetchAnalytics({ state, commit }, isRefresh) {
    const parameters = {
      refresh: isRefresh,
    }
    const { data } = await ApiSendingAnalytics.get(state.sending.id, parameters)

    commit('setAnalytics', data)
  },

  async createSending({ state, commit }) {
    const { data } = await ApiSending.post(state.sending)

    commit('setSending', data)
  },

  async fetchOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchStatuses'),
      dispatch('fetchTemplates'),
      dispatch('fetchSchoolsOptions'),
      dispatch('fetchGradesOptions'),
      dispatch('getStreamsOptions'),
      dispatch('fetchEducationTypesOptions'),
      dispatch('fetchContractTypesOptions'),
      dispatch('fetchContractStatusesOptions'),
      dispatch('fetchAcademicYearsOptions'),
      dispatch('fetchAccountStatusesOptions'),
      dispatch('fetchEnrollmentStatusesOptions'),
      dispatch('fetchRolesOptions'),
      dispatch('fetchSubjectsOptions'),
    ])
  },

  async fetchStatuses({ commit }) {
    const { data } = await ApiSendingOptionsDetailStatuses.get()

    commit('setOptionsList', {
      path: 'statuses',
      value: data,
    })
  },

  async fetchTemplates({ commit }) {
    const { data } = await ApiSendingOptionsTemplates.get()

    commit('setOptionsList', {
      path: 'templates',
      value: data,
    })
  },

  async removeTemplate({ dispatch, commit }, templateId) {
    await ApiSendingTemplate.delete(templateId)
    await dispatch('fetchTemplates')
    commit('updateTemplate')
  },

  async fetchSchoolsOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schools',
      value: data,
    })
  },

  async fetchGradesOptions({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOptionsList', {
      path: 'grades',
      value: data,
    })
  },

  async fetchStreamsOptions(context, parameters = {}) {
    const { data } = await ApiOption.getStreams(parameters)

    return data
  },

  async getStreamsOptions({ dispatch, commit }) {
    const value = await dispatch('fetchStreamsOptions')

    commit('setOptionsList', {
      path: 'streams',
      value,
    })
  },

  async fetchEducationTypesOptions({ commit }) {
    const { data } = await ApiOption.getEducationTypes()

    commit('setOptionsList', {
      path: 'educationTypes',
      value: data,
    })
  },

  async fetchContractTypesOptions({ commit }) {
    const { data } = await ApiOption.getEnrollmentContractTypes()

    commit('setOptionsList', {
      path: 'contractTypes',
      value: data,
    })
  },

  async fetchContractStatusesOptions({ commit }) {
    const { data } = await ApiOption.getEnrollmentContractStatuses()

    commit('setOptionsList', {
      path: 'contractStatuses',
      value: data,
    })
  },

  async fetchAcademicYearsOptions({ commit }) {
    const { data } = await ApiOption.getAcademicYearPreset()

    commit('setOptionsList', {
      path: 'academicYears',
      value: data,
    })
  },

  async fetchAccountStatusesOptions({ commit }) {
    const { data } = await ApiOption.getStudentAccountStatuses()

    commit('setOptionsList', {
      path: 'accountStatuses',
      value: data,
    })
  },

  async fetchEnrollmentStatusesOptions({ commit }) {
    const { data } = await ApiOption.getStudentEnrollmentStatuses()

    commit('setOptionsList', {
      path: 'enrollmentStatuses',
      value: data,
    })
  },

  async fetchRolesOptions({ commit }) {
    const { data } = await ApiRoleOptions.get()

    commit('setOptionsList', {
      path: 'roles',
      value: data,
    })
  },

  async fetchSubjectsOptions({ commit }) {
    const { data } = await ApiOption.getSubjects()

    commit('setOptionsList', {
      path: 'subjects',
      value: data,
    })
  },

  async fetchFilteredStudents(context, parameters) {
    const { data } = await ApiSendingStudents.get(parameters)

    return data
  },

  async fetchFilteredClients(context, parameters) {
    const { data } = await ApiSendingClients.get(parameters)

    return data
  },

  async fetchFilteredManagers(context, parameters) {
    const { data } = await ApiSendingManagers.get(parameters)

    return data
  },

  async fetchSearchStudents(context, parameters) {
    const { data } = await ApiSendingSearchStudents.get(parameters)

    return data.map((user) => ({
      ...user,
      role: 'student',
    }))
  },

  async fetchSearchClients(context, parameters) {
    const { data } = await ApiSendingSearchClients.get(parameters)

    return data.map((user) => ({
      ...user,
      role: 'client',
    }))
  },

  async fetchSearchManagers(context, parameters) {
    const { data } = await ApiSendingSearchManagers.get(parameters)

    return data.map((user) => ({
      ...user,
      role: 'manager',
    }))
  },
}
