import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductCategorySort =
  new (class extends ApiBase {
    /**
     * @param {Object} data
     * @returns {Promise}
     */
    patch(data) {
      return this._PATCH('/courses/product/category/sort', data)
    }
  })()
