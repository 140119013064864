import ApiBase from 'ApiRest/ApiBase'

export default new (class PromocodeGroupCode extends ApiBase {
  /**
   * @param {number} promocodeGroupId
   * @param {number} promocodeGroupCodeId
   * @returns {Promise}
   */
  delete(promocodeGroupId, promocodeGroupCodeId) {
    return this._DELETE(
      `/promocode-groups/${promocodeGroupId}/code/${promocodeGroupCodeId}`,
    )
  }
})()
