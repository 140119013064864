import ApiOption from 'ApiRest/Api/Option'
import { ApiTimetable } from 'ApiRest/Api/Timetable'

export default {
  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} timetableId
   * @returns {Promise<void>}
   */
  async fetchTheTimetable({ commit }, timetableId) {
    const { data } = await ApiTimetable.get(timetableId)

    commit('setTheTimetable', data)
  },

  async createTheTimetable({ state, commit }) {
    const { data } = await ApiTimetable.post(state.timetable)

    commit('setTheTimetable', data)
  },

  async updateTheTimetable({ state, commit }) {
    const { data } = await ApiTimetable.patch(state.timetable)

    commit('setTheTimetable', data)
  },

  async getOptions({ dispatch }) {
    await Promise.all([
      dispatch('getOptionsSchools'),
      dispatch('getOptionsGrades'),
      dispatch('getOptionsEducationTypes'),
      dispatch('getOptionsStreams'),
    ])
  },

  async getOptionsSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptions', {
      key: 'schools',
      data,
    })
  },

  async getOptionsGrades({ state, commit }) {
    const params = {
      school: {
        id: state.timetable.school?.id,
      },
    }

    const { data } = await ApiOption.getGrades(params)

    commit('setOptions', {
      key: 'grades',
      data,
    })
  },

  async getOptionsEducationTypes({ state, commit }) {
    const params = {
      school: {
        id: state.timetable.school?.id,
      },
    }

    const { data } = await ApiOption.getEducationTypes(params)

    commit('setOptions', {
      key: 'educationTypes',
      data,
    })
  },

  async getOptionsStreams({ state, commit }) {
    if (!state.timetable.school?.id) {
      return false
    }

    const params = {
      school: {
        id: state.timetable.school?.id,
      },
      educationType: {
        id: state.timetable.educationType?.id,
      },
      grade: {
        id: state.timetable.grade?.id,
      },
    }

    if (state.timetable.id) {
      params.timetable = {
        id: state.timetable.id,
      }
    } else {
      params.hasTimeTable = 0
    }

    const { data } = await ApiOption.getStreams(params)

    commit('setOptions', {
      key: 'streams',
      data,
    })

    return true
  },
}
