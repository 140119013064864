<template>
  <div>
    <UiKitFormFieldError
      v-for="error in errors"
      :key="error.$uid"
      :text="error.$message"
    />
  </div>
</template>

<script>
import UiKitFormFieldError from '@/ui/UiKitFormFieldError.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiKitFormFieldErrors',

  components: {
    UiKitFormFieldError,
  },

  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
})
</script>
