const routes = [
  {
    path: '/group/create',
    name: 'admin-the-group-create',
    component: () => import('@/pages/group/TheGroup.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/group/:groupId(\\d+)',
    name: 'admin-the-group',
    component: () => import('@/pages/group/TheGroup.vue'),
  },
]

export default routes
