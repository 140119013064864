import { testAvailabilityTime } from '@/helpers/TestAvailabilityTime'

export default () => ({
  services: [],

  request: {
    school: null,
    grade: null,
  },

  extramuralDiagnostics: {
    subjects: null,
    service: null,
  },

  fullTimeDiagnostics: {
    subjects: null,
    service: null,
  },

  requestId: null,

  isCheckedEachExtramuralDiagnostics: false,
  isCheckedEachFullTimeDiagnostics: false,

  testAvailabilityTime,
})
