export default () => ({
  promocodeGroups: [],
  pagination: null,
  filters: null,

  promocodeGroupId: null,
  promocodeGroup: {
    id: null,
    name: '',
    description: '',
    saleProducts: [],
    saleServices: [],
    isApplicableToAnyService: false,
    bestBefore: '',
    effectiveDate: '',
    initialGeneratedCount: null,
    discount: null,
    applyOnlyToProductConsumer: false,
    newPromocodesCount: null,
    usedPromocodesCount: null,
    expiredPromocodesCount: null,
    discountType: null,
    invoices: [],
    customerPromocodes: [],
    sendingPromocodes: [],
    code: null,
  },
  options: {
    discountTypes: [],
    saleProducts: [],
    saleServices: [],
  },
})
