import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportFAQTopic = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(payload, parameters) {
    const url = `/support/faq/topics?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }

  /**
   * @param {number} topicId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(topicId, parameters) {
    const url = `/support/faq/topics/${topicId}?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }

  /**
   * @param {number} topicId
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  patch(topicId, payload, parameters) {
    const url = `/support/faq/topics/${topicId}?${UrlHelper.param(parameters)}`

    return this._PATCH(url, payload)
  }

  /**
   * @param {number} topicId
   * @param {Object} parameters
   * @returns {Promise}
   */
  delete(topicId, parameters) {
    const url = `/support/faq/topics/${topicId}?${UrlHelper.param(parameters)}`

    return this._DELETE(url)
  }
})()
