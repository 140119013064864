import ApiOption from 'ApiRest/Api/Option'
import { ApiProductManagementProductList } from 'ApiRest/Api/Products/ProductList'
import { ApiProductManagementProductTypes } from 'ApiRest/Api/Products/ProductTypes'

export default {
  async fetchProductList({ commit }, params) {
    const expandParams = {
      expand: 'extraCategories, holding, mainCategory, type, visibility',
    }

    const { data, headers } = await ApiProductManagementProductList.get({
      ...params,
      ...expandParams,
    })

    commit('setFilters', params)
    commit('setProductList', data)
    commit('setPagination', headers)
  },

  async fetchSchoolOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schoolOptionList',
      value: data,
    })
  },

  async fetchTypeOptions({ commit }) {
    const { data } = await ApiProductManagementProductTypes.get()

    commit('setOptionsList', {
      path: 'kindOptionList',
      value: data,
    })
  },

  async fetchProductOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchSchoolOptions'),
      dispatch('fetchTypeOptions'),
    ])
  },
}
