import ApiBase from 'ApiRest/ApiBase'

export default new (class StudentDemo extends ApiBase {
  /**
   * @param {number} studentDemoId
   * @returns {Promise}
   */
  get(studentDemoId) {
    return this._GET(`/user/demo-students/${studentDemoId}`)
  }

  /**
   * @param {number} studentDemoId
   * @param {Object} data
   * @returns {Promise}
   */
  patch(studentDemoId, data) {
    return this._PATCH(`/user/demo-students/${studentDemoId}`, data)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/user/demo-students`, data)
  }
})()
