import ApiOption from 'ApiRest/Api/Option'
import ApiTheGroup from 'ApiRest/Api/Wiki/Group'
import ApiGroups from 'ApiRest/Api/Wiki/Group/List'
import Constants from 'Constants'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchWiki({ commit }, schoolId) {
    const params = {
      status: {
        id: Constants.wikiStatus.PUBLISHED,
      },
      school: {
        id: schoolId,
      },
    }

    const { data } = await ApiGroups.get(params)

    commit('setWiki', data)
  },

  async fetchTheWiki({ commit }, wikiId) {
    const { data } = await ApiTheGroup.get(wikiId)

    commit('setTheWiki', data)
  },

  async fetchOptionSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      key: 'schools',
      value: data,
    })
  },
}
