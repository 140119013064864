import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductOptionsGroups = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/online-school/product/options/groups')
  }
})()
