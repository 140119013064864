import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} requestId
   * @returns {Promise}
   */
  get(requestId) {
    const url = `/enrollment/request/${requestId}/logs`

    return this._GET(url)
  }
})()
