import { ApiCourseManagementRequest } from 'ApiRest/Api/CourseManagement/Requests/Request'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchRequest({ commit, state }) {
    const { data } = await ApiCourseManagementRequest.get(state.requestId)

    commit('setRequest', data)
  },

  async fetchRoles({ commit }) {
    const { data } = await ApiOption.getPublicRoles()

    commit('setOptions', {
      path: 'roles',
      value: data,
    })
  },
}
