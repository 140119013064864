import { ApiOnlineSchoolCallRequests } from 'ApiRest/Api/OnlineSchool/CallRequests/Requests'

export default {
  async fetchCallRequests({ commit }, filters = {}) {
    const { data, headers } = await ApiOnlineSchoolCallRequests.get(filters)

    commit('setFilters', filters)
    commit('setCallRequests', data)
    commit('setPagination', headers)
  },

  async changeStatusCallRequests(context, { id, status }) {
    await ApiOnlineSchoolCallRequests.put(id, status)
  },
}
