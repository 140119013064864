import ApiBase from 'ApiRest/ApiBase'

export default new (class FamilyEducationCertificationGrades extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/schools/${schoolId}/family-certification-grades/`)
  }
})()
