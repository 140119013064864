import callRequests from './callRequests/store'
import order from './order/store'
import orders from './orders/store'
import productRequests from './productRequests/store'
import products from './products/store'
import settings from './settings/store'

export default {
  namespaced: true,

  modules: {
    products,
    settings,
    callRequests,
    productRequests,
    orders,
    order,
  },
}
