import ApiBase from 'ApiRest/ApiBase'

export const ApiClientsExport = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    return this._GET(`/user/clients/export?${this.getUrlParams(parameters)}`, {
      responseType: 'blob',
    })
  }
})()
