import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportContact = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(payload) {
    const url = `/support/contacts`

    return this._PATCH(url, payload)
  }

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    const url = `/support/contacts`

    return this._POST(url, payload)
  }

  /**
   * @param {number} contactId
   * @returns {Promise}
   */

  delete(contactId) {
    const url = `/support/contacts/${contactId}`

    return this._DELETE(url)
  }
})()
