/**
 * Разрешения на "Отображение пунктов главного меню"
 * @param {Object} data
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @returns {boolean}
 */
export const setAbilityByMenuPermission = ({ helpers, permission }) => {
  const routeNamesPerMenuPermission = new Map()

  // Тесты: Тесты
  routeNamesPerMenuPermission.set('menu_tests', [
    'admin-tests',
  ])
  // Тесты: Проверка Тестов
  routeNamesPerMenuPermission.set('menu_tests_check', [
    'admin-tests-check',
  ])
  // Тесты: Проверка домашних заданий
  routeNamesPerMenuPermission.set('menu_homeworks_check', [
    'admin-homeworks',
  ])
  // Тесты: Оплата Тестов
  routeNamesPerMenuPermission.set('menu_tests_payment', [
    'admin-tests-payment',
  ])

  // КТП
  routeNamesPerMenuPermission.set('menu_ctp', [
    'admin-ctps',
  ])

  // Аттестация
  routeNamesPerMenuPermission.set('menu_certification', [
    'admin-certifications',
  ])
  // Аттестация: Добавить
  routeNamesPerMenuPermission.set('menu_certification_add', [
    'admin-the-certification-create',
  ])

  // Аттестации СО: Аттестации
  routeNamesPerMenuPermission.set('menu_certification_family', [
    'admin-family-certifications',
  ])
  // Аттестации СО: Расписание Аттестаций
  routeNamesPerMenuPermission.set('menu_certification_family_schedule', [
    'admin-family-certifications-schedule',
  ])

  // Аттестации СО: Ведомость
  routeNamesPerMenuPermission.set('menu_certification_family_statement', [
    'admin-family-certification-statement',
  ])

  // Ведомость
  routeNamesPerMenuPermission.set('menu_statement', [
    'admin-the-statement',
  ])

  // Ведомость ОО
  routeNamesPerMenuPermission.set('menu_fulltime_statement', [
    'admin-the-full-time-statement',
  ])

  // Спецведомость
  routeNamesPerMenuPermission.set('menu_special_statement', [
    'admin-special-statement',
  ])

  // Аналитика
  routeNamesPerMenuPermission.set('menu_analytic', [
    'admin-analytics',
  ])

  // Аналитика: Отчёт по зачисленным
  routeNamesPerMenuPermission.set('menu_analytic_student_enrolled', [
    'admin-analytics-student-enrolled',
  ])

  // Аналитика по КТП
  routeNamesPerMenuPermission.set('menu_analytics_ctp', [
    'admin-analytics-ctp',
  ])

  // Аналитика по библиотеке
  routeNamesPerMenuPermission.set('menu_analytics_library', [
    'admin-analytics-library',
  ])

  // Ученики
  routeNamesPerMenuPermission.set('menu_students', [
    'admin-students',
  ])
  // Ученики: Демо-ученики
  routeNamesPerMenuPermission.set('menu_students_demo', [
    'admin-students-demo',
  ])
  // Ученики: Распределение по группам
  routeNamesPerMenuPermission.set('menu_students_group_distribution', [
    'admin-student-group-distribution',
  ])
  // Ученики: Перевод из потока в поток
  routeNamesPerMenuPermission.set('menu_students_stream_transfer', [
    'admin-student-transfer',
  ])
  // Ученики: Потоки
  routeNamesPerMenuPermission.set('menu_streams', [
    'admin-student-streams',
  ])
  // Ученики: Группы
  routeNamesPerMenuPermission.set('menu_groups', [
    'admin-student-groups',
  ])
  // Ученики: Корзина
  routeNamesPerMenuPermission.set('menu_students_basket', [
    'admin-students-trash',
  ])

  // Новости
  routeNamesPerMenuPermission.set('menu_news', [
    'admin-news',
  ])

  // События
  routeNamesPerMenuPermission.set('menu_events', [
    'admin-events',
  ])

  // Рассылки
  routeNamesPerMenuPermission.set('menu_sendings', [
    'admin-sendings',
  ])

  // Рейтинг
  routeNamesPerMenuPermission.set('menu_rating', [
    'admin-rating',
  ])

  // Кредиты
  routeNamesPerMenuPermission.set('menu_credits', [
    'admin-credits',
  ])

  // Онлайн-уроки: Управление online-комнатами
  routeNamesPerMenuPermission.set('menu_online_rooms', [
    'admin-online-lesson-rooms',
  ])

  // Заявки
  routeNamesPerMenuPermission.set('menu_enrollment_requests', [
    'admin-enrollment-requests',
  ])
  // Заявки: Черновики
  routeNamesPerMenuPermission.set('menu_enrollment_requests_drafts', [
    'admin-enrollment-requests-draft',
  ])
  // Заявки: Корзина
  routeNamesPerMenuPermission.set('menu_enrollment_requests_basket', [
    'admin-enrollment-requests-trash',
  ])

  // Договоры
  routeNamesPerMenuPermission.set('menu_enrollment_contracts', [
    'admin-enrollment-contracts',
  ])

  // Заявки на аттестацию СО
  routeNamesPerMenuPermission.set('menu_certification_family_requests', [
    'admin-family-certification-requests',
  ])

  // Клиенты
  routeNamesPerMenuPermission.set('menu_clients', [
    'admin-clients',
  ])
  // Клиенты: Лиды
  routeNamesPerMenuPermission.set('menu_leads', [
    'admin-leads',
  ])
  // Клиенты: Корзина
  routeNamesPerMenuPermission.set('menu_clients_basket', [
    'admin-clients-trash',
  ])

  // Управление продуктами: Продукты
  routeNamesPerMenuPermission.set('menu_products', [
    'admin-product-management-products',
  ])
  // Управление продуктами: Аналитика
  routeNamesPerMenuPermission.set('menu_products_analytics', [
    'admin-product-management-analytics',
  ])
  // Управление продуктами: Заказы
  routeNamesPerMenuPermission.set('menu_products_orders', [
    'admin-product-management-orders',
  ])
  // Управление продуктами: Настройка баннеров
  routeNamesPerMenuPermission.set('menu_products_banners', [
    'admin-product-management-banners',
  ])
  // Управление продуктами: Навигация
  routeNamesPerMenuPermission.set('menu_products_navigation', [
    'admin-product-management-navigation',
  ])
  // Управление продуктами: Категории
  routeNamesPerMenuPermission.set('menu_products_categories', [
    'admin-product-management-categories',
  ])
  // Управление продуктами: Настройка параметров
  routeNamesPerMenuPermission.set('menu_products_parameters', [
    'admin-product-management-parameters',
  ])
  // Управление продуктами: Заявки
  routeNamesPerMenuPermission.set('menu_products_requests', [
    'admin-product-management-requests',
  ])
  // Управление продуктами: Настройки
  routeNamesPerMenuPermission.set('menu_products_settings', [
    'admin-product-management-settings',
  ])
  // Управление продуктами: Реклама
  routeNamesPerMenuPermission.set('menu_products_ads', [
    'admin-product-management-advertisements',
  ])
  // Управление продуктами: Вопросы
  routeNamesPerMenuPermission.set('menu_products_questions', [
    'admin-product-management-questions',
  ])

  // Платежи: Счета
  routeNamesPerMenuPermission.set('menu_invoices', [
    'admin-payment-invoices',
  ])
  // Платежи: Промокоды
  routeNamesPerMenuPermission.set('menu_promocodes', [
    'admin-payment-promocode-groups',
  ])
  // Платежи: Промокоды продуктов
  routeNamesPerMenuPermission.set('menu_product_promocodes', [
    'admin-payment-product-promocode-groups',
  ])

  // Платежи: Услуги
  routeNamesPerMenuPermission.set('menu_services', [
    'admin-payment-services',
  ])
  // Платежи: Подписки
  routeNamesPerMenuPermission.set('menu_subscriptions', [
    'admin-payment-subscriptions',
  ])

  // Цены: Обучение: Заочное
  routeNamesPerMenuPermission.set('menu_prices_study_extramural', [
    'admin-price-extramural-subsidy',
    'admin-price-extramural-paid',
    'admin-price-extramural-individual-subsidy',
    'admin-price-extramural-individual-paid',
  ])

  // Настройки: Школы
  routeNamesPerMenuPermission.set('menu_schools', [
    'admin-settings-schools',
  ])
  // Настройки: Семейное обучение
  routeNamesPerMenuPermission.set('menu_family_education', [
    'admin-family-educations',
  ])
  // Настройки: Учебный год
  routeNamesPerMenuPermission.set('menu_academic_year', [
    'admin-settings-academic-years',
  ])
  // Настройки: Расписания
  routeNamesPerMenuPermission.set('menu_timetables', [
    'admin-settings-timetables',
  ])
  // Настройки: Праздники
  routeNamesPerMenuPermission.set('menu_holidays', [
    'admin-settings-holidays',
  ])
  // Настройки: Партнеры
  routeNamesPerMenuPermission.set('menu_partners', [
    'admin-settings-partners',
  ])
  // Настройки: Роли сотрудников
  routeNamesPerMenuPermission.set('menu_roles', [
    'admin-settings-roles',
  ])
  // Настройки: Сотрудники
  routeNamesPerMenuPermission.set('menu_managers', [
    'admin-settings-users',
  ])
  // Настройки: Настройки справки
  routeNamesPerMenuPermission.set('menu_wiki', [
    'admin-settings-wiki',
  ])

  // Администрирование: Zoom
  routeNamesPerMenuPermission.set('menu_admin_zoom', [
    'admin-administrating-zoom',
  ])

  // Администрирование: Лог ЭДУКОНТ
  // TODO: заменить на реальный пермишен
  routeNamesPerMenuPermission.set('menu_admin_educont_log', [
    'admin-administrating-educont-log',
  ])

  // Сообщения
  routeNamesPerMenuPermission.set('menu_messages', [
    'admin-messages',
  ])

  // Группы продукта витрины
  routeNamesPerMenuPermission.set('menu_product_management_groups', [
    'admin-product-management-groups',
    'admin-the-group',
  ])

  // Группы продукта моих курсов
  routeNamesPerMenuPermission.set('menu_courses_management_groups', [
    'admin-course-management-groups',
    'admin-the-group',
  ])

  // Настройка: триггерные рассылки
  routeNamesPerMenuPermission.set('menu_settings_notification_center', [
    'admin-settings-trigger-sendings',
    'admin-settings-create-trigger-sending',
    'admin-settings-the-trigger-sending',
  ])

  // Онлайн-школа
  routeNamesPerMenuPermission.set('menu_online_school_settings', [
    'admin-online-school-settings',
  ])

  routeNamesPerMenuPermission.set('menu_online_school_orders', [
    'admin-online-school-orders',
  ])

  routeNamesPerMenuPermission.set('menu_online_school_products', [
    'admin-online-school-products',
  ])

  routeNamesPerMenuPermission.set('menu_online_school_product_requests', [
    'admin-online-school-product-requests',
  ])

  routeNamesPerMenuPermission.set('menu_online_school_call_requests', [
    'admin-online-school-call-requests',
  ])

  if (routeNamesPerMenuPermission.has(permission)) {
    const routeNames = routeNamesPerMenuPermission.get(permission)

    helpers.allowReadMenuItemForRoutes(routeNames)

    return true
  }

  return false
}
