const routes = [
  {
    path: '/full-time-statement',
    name: 'admin-the-full-time-statement',
    component: () =>
      import('@/pages/fullTimeStatement/TheFullTimeStatement.vue'),
  },
]

export default routes
