/**
 * @typedef {Object} SupportQuestionPreview
 * @property {number} id - Ид вопроса
 * @property {string} number - Номер вопроса
 * @property {string} question - Превью вопроса
 * @property {SupportStatus} status - Статус вопроса
 * @property {number} newCommentsCount - Кол-во новых ответов/комментариев
 */

/**
 * @typedef {Object} QuestionAuthor
 * @property {number} id - Ид вопроса
 * @property {string} firstName - Имя
 * @property {string} lastName - Фамилия
 * @property {string} middleName - Отчество
 * @property {Object|null} photo - Фото профиля
 * @property {('Клиент'|'Ученик')} role - Роль
 */

/**
 * @typedef {Object} SupportQuestionComment
 * @property {number} id - Ид комментария
 * @property {QuestionAuthor} author - Автор вопроса
 * @property {Array} files - Список прикрепленных файлов
 * @property {string} text - Текст вопроса
 */

/**
 * @typedef {Object} SupportCtp
 * @property {number} id - Ид
 * @property {string} name - Имя
 * @property {SupportBaseObject} subject - Предмет
 */

/**
 * @typedef {Object} SupportTest
 * @property {number} id - Ид
 * @property {string} name - Имя
 * @property {SupportBaseObject} topic - Топик
 * @property {SupportBaseObject} section - Раздел
 */

/**
 * @typedef {Object} SupportBaseObject
 * @property {number} id - Ид
 * @property {string} name - Имя
 */

/**
 * @typedef {Object} SupportTestQuestion
 * @property {number} id - Ид
 * @property {string} name - Имя
 * @property {SupportCtp} ctp - КТП
 * @property {SupportTest} test - Тест
 */

/**
 * @typedef {Object} SupportQuestion - Объект обращения в поддержку
 * @property {number} id - Ид обращения
 * @property {string} number - Номер обращения
 * @property {SupportStatus} status - Статус вопроса
 * @property {QuestionAuthor} author - Автор вопроса
 * @property {string} text - Текст вопроса
 * @property {Array} files - Список прикрепленных файлов
 * @property {SupportQuestionComment[]} comments - Комментарии к вопросу
 * @property {string} createdAt - Дата время в формате ISO8601
 * @property {SupportBaseObject} type - Тип вопроса
 * @property {SupportBaseObject} department - Отдел
 * @property {Object|null} departmentUser - Сотрудник отдела
 * @property {SupportBaseObject} school - Школа
 * @property {SupportBaseObject} grade - Класс
 * @property {SupportBaseObject} stream - Поток
 * @property {Object|null} content - Данные по уроку
 * @property {SupportTestQuestion|null} testQuestion - Данные по тесту
 */

/**
 * @typedef {Object} SupportStatus
 * @property {number} id - Ид статуса
 * @property {('Новый'|'В работе'|'Вопрос решен')} name - Название статуса
 */

export default () => ({
  /**
   * @type {SupportQuestionPreview[]}
   */
  questions: [],

  /**
   * @type {SupportQuestion}
   */
  theQuestion: {
    id: null,
    number: '',
    status: {
      id: null,
      name: '',
    },
    author: {
      id: null,
      firstName: '',
      lastName: '',
      middleName: '',
      role: '',
      photo: null,
    },
    text: '',
    files: [],
    comments: [],
    createdAt: '',
    type: null,
    department: null,
    departmentUser: null,
    school: null,
    grade: null,
    stream: null,
    content: null,
    testQuestion: null,
    priority: null,
    onlineLesson: null,
  },

  theQuestionInitialStatus: {},

  theNewQuestion: {
    text: '',
    files: [],
    school: null,
    department: null,
    departmentUser: null,
  },

  options: {
    statuses: [],
    departments: [],
    schools: [],
    departmentUsers: [],
    priorities: [],
  },

  questionsInWorkCount: null,

  logs: [],

  isLoading: {
    content: false,
    question: false,
  },
})
