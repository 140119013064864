import Constants from 'Constants'

export default {
  /**
   * @param {Object} state
   * @returns {Array}
   */
  schools(state) {
    return state.theOnlineLesson.affiliations.map(
      (affiliation) => affiliation.school,
    )
  },

  hasName(state) {
    return state.theOnlineLesson.name.length > 0
  },

  /**
   *
   * @param {Object} state
   * @returns {boolean}
   */
  isBigBlueButtonLesson(state) {
    return (
      state.theOnlineLesson.conferenceType ===
      Constants.conferenceType.BIG_BLUE_BUTTON
    )
  },
}
