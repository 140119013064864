import ApiBase from 'ApiRest/ApiBase'

export default new (class TheCertificationSchedule extends ApiBase {
  /**
   * @param {number} scheduleId
   * @returns {Promise}
   */
  get(scheduleId) {
    return this._GET(`/family-certification-schedule/${scheduleId}`)
  }

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload = {}) {
    return this._POST(`/family-certification-schedule`, payload)
  }

  /**
   * @param {number} scheduleId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(scheduleId, payload = {}) {
    return this._PATCH(`/family-certification-schedule/${scheduleId}`, payload)
  }

  /**
   * @param {number} scheduleId
   * @returns {Promise}
   */
  delete(scheduleId) {
    return this._DELETE(`/family-certification-schedule/${scheduleId}`)
  }
})()
