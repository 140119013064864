import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

const getTestIndex = (tests, test) =>
  tests.findIndex(({ tempId, id }) => {
    if (test.tempId) {
      return tempId === test.tempId
    }

    return id === test.id
  })

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setCertifications(state, payload) {
    state.certifications = payload
  },

  setTheCertification(state, payload) {
    state.certification = payload
  },

  setTheCertificationValue(state, { key, value }) {
    state.certification[key] = value
  },

  addTheCertificationTest(state, payload) {
    state.certification.tests.push(payload)
  },

  updateTheCertificationTest(state, { test, value }) {
    const { tests } = state.certification

    Vue.set(tests, getTestIndex(tests, test), value)
  },

  removeTheCertificationTest(state, test) {
    const { tests } = state.certification

    Vue.delete(tests, getTestIndex(tests, test))
  },

  setFamilyStatement(state, payload) {
    state.familyStatement = payload
  },

  setFamilyCertificationSchools(state, payload) {
    state.familyCertificationSchools = payload
  },

  setFamilyCertificationGrades(state, payload) {
    state.familyCertificationGrades = payload
  },

  setFamilyCertificationStatuses(state, payload) {
    state.familyCertificationStatuses = payload
  },

  setGrades(state, payload) {
    state.grades = payload
  },

  setSubjects(state, payload) {
    state.subjects = payload
  },

  setStatementScheduleList(state, payload) {
    state.statementScheduleList = payload
  },

  setStatementSchedule(state, payload) {
    state.statementSchedule = payload
  },

  setStatementStreamList(state, payload) {
    state.statementStreamList = payload
  },

  setStatementStream(state, payload) {
    state.statementStream = payload
  },

  setStatementSortField(state, payload) {
    state.statementSortField = payload
  },

  setStatementSearchLastName(state, payload) {
    state.statementSearchLastName = payload
  },

  setStatementShowOnlyCompleted(state, payload) {
    state.statementShowOnlyCompleted = payload
  },

  setTests(state, payload) {
    state.tests = payload
  },

  setTestCheckers(state, payload) {
    state.testOptions.checkers = payload
  },

  setTestWhenShowAnswerTypes(state, payload) {
    state.testOptions.whenShowAnswerTypes = payload
  },

  setTestShowAnswerTypes(state, payload) {
    state.testOptions.showAnswerTypes = payload
  },

  setTestExaminationLevels(state, payload) {
    state.testOptions.ctpTestExaminationLevels = payload
  },

  setTestAttemptsMarkTypes(state, payload) {
    state.testOptions.attemptsMarkTypes = payload
  },

  setTestMarkSettings(state, payload) {
    state.testMarkSettings = payload
  },
}
