import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscription = new (class extends ApiBase {
  /**
   * Получаем detail подписки
   * @param {number} subscriptionId
   * @returns {Promise}
   */
  get(subscriptionId) {
    return this._GET(`/subscriptions/${subscriptionId}`)
  }

  /**
   * Создание подписки
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    return this._POST('/subscriptions', payload)
  }

  /**
   * Изменение подписки
   * @param {number} subscriptionId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(subscriptionId, payload) {
    return this._PATCH(`/subscriptions/${subscriptionId}`, payload)
  }

  /**
   * Изменение подписки
   * @param {number} subscriptionId
   * @returns {Promise}
   */
  delete(subscriptionId) {
    return this._DELETE(`/subscriptions/${subscriptionId}`)
  }
})()
