const routes = [
  {
    path: '/events',
    name: 'admin-events',
    meta: {
      accessControlGroup: 'event',
    },
    component: () => import('@/pages/event/Events.vue'),
  },
  {
    path: '/events/create',
    name: 'admin-the-event-create',
    meta: {
      accessControlGroup: 'event',
    },
    component: () => import('@/pages/event/TheEvent.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/events/:eventId(\\d+)',
    name: 'admin-the-event',
    meta: {
      accessControlGroup: 'event',
    },
    component: () => import('@/pages/event/TheEvent.vue'),
  },
]

export default routes
