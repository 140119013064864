import ApiNews from 'ApiRest/Api/News/News'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchNewsArticle({ commit, state }) {
    const { data } = await ApiNews.get(state.newsArticle.id)

    commit('setNewsArticle', data)
  },

  async setNewsArticle({ state, commit }) {
    const { data } = await ApiNews.post(state.newsArticle)

    commit('setNewsArticle', data)
  },

  async updateNewsArticle({ state, commit }) {
    const { data } = await ApiNews.patch(
      state.newsArticle.id,
      state.newsArticle,
    )

    commit('setNewsArticle', data)
  },

  async fetchStreams({ commit }) {
    const { data } = await ApiOption.getStreams()

    commit('setOption', {
      path: 'streams',
      value: data,
    })
  },

  async fetchMaterialTypes({ commit }) {
    const { data } = await ApiOption.getMaterialTypes()

    commit('setOption', {
      path: 'materialTypes',
      value: data,
    })
  },
}
