import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductMaterialTypes = new (class extends ApiBase {
  /**
   * Типы материалов продукта
   * @returns {Promise}
   */
  get() {
    return this._GET(`/online-school/product/options/material-types`)
  }
})()
