import ApiBase from 'ApiRest/ApiBase'

export default new (class FamilyEducationApplicationList extends ApiBase {
  /**
   * @param {number} id - ID школы
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/school/${id}/template/family-education-applications`)
  }
})()
