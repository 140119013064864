import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class List extends ApiBase {
  /**
   * Список Тестов и Демо тестов в одном списке
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(`/tests?${UrlHelper.param(parameters)}`)
  }
})()
