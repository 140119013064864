import ApiQuestionList from 'ApiRest/Api/Library/Section/Topic/Question/List/List'

export default {
  async fetchQuestionList({ commit }, params) {
    const { data, headers } = await ApiQuestionList.get({
      ...params,
    })

    commit('setFilters', params)
    commit('setQuestionList', data)
    commit('setPagination', headers)
  },
}
