export default () => ({
  options: {
    schools: [],
    grades: [],
    streams: [],
    educationTypes: [],
    students: [],
  },

  studentAssignmentData: {
    school: null,
    grade: null,
    stream: null,
    educationType: null,
    students: [],
  },

  streamAssignmentData: {
    school: null,
    grade: null,
    educationType: null,
    streams: [],
  },
})
