import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductOptionsCtps = new (class extends ApiBase {
  /**
   * Список ктп продукта
   * @param {number} productId
   * @returns {Promise}
   */
  get(productId) {
    return this._GET(`/online-school/product/${productId}/options/ctps`)
  }
})()
