import ApiBase from 'ApiRest/ApiBase'

export default new (class FamilyEducationContractList extends ApiBase {
  /**
   * @param {number} schoolId - ID школы
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/school/${schoolId}/template/family-education-contracts`)
  }
})()
