<template>
  <SlickList
    v-model="localValue"
    :use-drag-handle="useDragHandle"
    :lock-axis="lockAxis"
    :class="cssClass"
    @sort-start="sortStart"
    @sort-end="sortEnd"
  >
    <slot />
  </SlickList>
</template>

<script>
import { defineComponent } from 'vue'
import { SlickList } from 'vue-slicksort'

export default defineComponent({
  name: 'UiKitSlickSortList',

  components: {
    SlickList,
  },

  props: {
    value: {
      type: Array,
      default: null,
    },

    lockAxis: {
      type: String,
      default: 'y',
    },

    useDragHandle: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMoved: false,
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },

      set(newValue) {
        this.$emit('input', newValue)
      },
    },

    cssClass() {
      return this.isMoved ? 'user-select-none' : ''
    },
  },

  methods: {
    sortStart(value) {
      this.isMoved = true
      this.$emit('sort-start', value)
    },

    sortEnd(value) {
      this.isMoved = false
      this.$emit('sort-end', value)
    },
  },
})
</script>
