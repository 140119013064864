import ApiBase from 'ApiRest/ApiBase'

export default new (class TreeSort extends ApiBase {
  /**
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH(`/wiki/groups/tree-sort`, data)
  }
})()
