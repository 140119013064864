export default () => ({
  order: {
    id: null,
    date: null,
    customer: {
      id: null,
      firstName: '',
      lastName: '',
      middleName: '',
      phone: '',
      email: '',
      citizenship: null,
      passport: null,
      address: null,
      baseRole: '',
    },
    status: null,
    invoices: [],
    items: [],
    invoicesSum: null,
    paidSum: null,
    signedDocuments: [],
    clientDocuments: [],
  },

  orderId: null,

  options: {
    status: [],
  },
})
