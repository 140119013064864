const routes = [
  {
    path: '/homeworks',
    name: 'admin-homeworks',
    meta: {
      accessControlGroup: 'homework',
    },
    component: () => import('@/pages/homework/Homeworks.vue'),
  },
  {
    path: '/homework/:solutionId(\\d+)',
    name: 'admin-the-homework',
    meta: {
      accessControlGroup: 'homework',
    },
    component: () => import('@/pages/homework/TheHomework.vue'),
  },
]

export default routes
