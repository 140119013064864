const routes = [
  {
    path: 'product-management/products',
    name: 'admin-product-management-products',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () => import('@/pages/productManagement/product/Products.vue'),
  },
  {
    path: 'product-management/products/:productId(\\d+)',
    name: 'admin-the-product-management',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import('@/pages/productManagement/product/TheProductDetail.vue'),
  },
  {
    path: 'product-management/products/create',
    name: 'admin-product-management-create-product',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () =>
      import('@/pages/productManagement/product/CreateProduct.vue'),
  },
  {
    path: 'product-management/analytics',
    name: 'admin-product-management-analytics',
    meta: {
      accessControlGroup: 'product-management-analytics',
    },
    component: () => import('@/pages/productManagement/Analytics.vue'),
  },
  {
    path: 'product-management/orders',
    name: 'admin-product-management-orders',
    meta: {
      accessControlGroup: 'product-management-orders',
    },
    component: () => import('@/pages/productManagement/order/Orders.vue'),
  },
  {
    path: 'product-management/order/:orderId(\\d+)',
    name: 'admin-product-management-the-order',
    meta: {
      accessControlGroup: 'product-management-orders',
    },
    component: () => import('@/pages/productManagement/order/TheOrder.vue'),
  },
  {
    path: 'product-management/banners',
    name: 'admin-product-management-banners',
    meta: {
      accessControlGroup: 'product-management-banners',
    },
    component: () => import('@/pages/productManagement/banner/Banners.vue'),
  },
  {
    path: 'product-management/banner/create',
    name: 'admin-product-management-the-banner-create',
    meta: {
      accessControlGroup: 'product-management-banners',
    },
    component: () => import('@/pages/productManagement/banner/TheBanner.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'product-management/banner/:bannerId(\\d+)',
    name: 'admin-product-management-the-banner',
    meta: {
      accessControlGroup: 'product-management-banners',
    },
    component: () => import('@/pages/productManagement/banner/TheBanner.vue'),
  },
  {
    path: 'product-management/categories',
    name: 'admin-product-management-categories',
    meta: {
      accessControlGroup: 'product-management-categories',
    },
    component: () =>
      import('@/pages/productManagement/category/Categories.vue'),
  },
  {
    path: 'product-management/categories/create',
    name: 'admin-the-product-management-category-create',
    meta: {
      accessControlGroup: 'product-management-categories',
    },
    component: () =>
      import('@/pages/productManagement/category/TheCategory.vue'),
  },
  {
    path: 'product-management/categories/:categoryId(\\d+)',
    name: 'admin-the-product-management-category',
    meta: {
      accessControlGroup: 'product-management-categories',
    },
    component: () =>
      import('@/pages/productManagement/category/TheCategory.vue'),
  },
  {
    path: 'product-management/groups',
    name: 'admin-product-management-groups',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () => import('@/pages/group/Groups.vue'),
  },
  {
    path: 'product-management/navigation',
    name: 'admin-product-management-navigation',
    meta: {
      accessControlGroup: 'product-management',
    },
    component: () => import('@/pages/productManagement/NavigationBanner.vue'),
  },
  {
    path: 'product-management/parameters',
    name: 'admin-product-management-parameters',
    meta: {
      accessControlGroup: 'product-management-parameters',
    },
    component: () =>
      import('@/pages/productManagement/parameters/Parameters.vue'),
  },
  {
    path: 'product-management/parameter/create',
    name: 'admin-product-management-the-parameter-create',
    meta: {
      accessControlGroup: 'product-management-parameters',
    },
    component: () =>
      import('@/pages/productManagement/parameters/TheParameter.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'product-management/parameter/:parameterId(\\d+)',
    name: 'admin-product-management-the-parameter',
    meta: {
      accessControlGroup: 'product-management-parameters',
    },
    component: () =>
      import('@/pages/productManagement/parameters/TheParameter.vue'),
  },
  {
    path: 'product-management/advertisements',
    name: 'admin-product-management-advertisements',
    meta: {
      accessControlGroup: 'product-management-advertisements',
    },
    component: () => import('@/pages/productManagement/ads/Ads.vue'),
  },
  {
    path: 'product-management/advertisement/create',
    name: 'admin-product-management-the-advertisement-create',
    meta: {
      accessControlGroup: 'product-management-advertisements',
    },
    component: () => import('@/pages/productManagement/ads/TheAd.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: 'product-management/advertisement/:advertisementId(\\d+)',
    name: 'admin-product-management-the-advertisement',
    meta: {
      accessControlGroup: 'product-management-advertisements',
    },
    component: () => import('@/pages/productManagement/ads/TheAd.vue'),
  },
  {
    path: 'product-management/requests',
    name: 'admin-product-management-requests',
    meta: {
      accessControlGroup: 'product-management-requests',
    },
    component: () => import('@/pages/productManagement/requests/Requests.vue'),
  },
  {
    path: 'product-management/requests/:requestId(\\d+)',
    name: 'admin-product-management-the-request',
    meta: {
      accessControlGroup: 'product-management-requests',
    },
    component: () =>
      import('@/pages/productManagement/requests/TheRequest.vue'),
  },
  {
    path: 'product-management/questions',
    name: 'admin-product-management-questions',
    meta: {
      accessControlGroup: 'product-management-questions',
    },
    component: () => import('@/pages/productManagement/question/Questions.vue'),
  },
  {
    path: 'product-management/questions/:questionId(\\d+)',
    name: 'admin-product-management-the-question',
    meta: {
      accessControlGroup: 'product-management-questions',
    },
    component: () =>
      import('@/pages/productManagement/question/TheQuestion.vue'),
  },
  {
    path: 'product-management/settings',
    name: 'admin-product-management-settings',
    meta: {
      accessControlGroup: 'product-management-settings',
    },
    component: () => import('@/pages/productManagement/Settings.vue'),
  },
]

export default routes
