import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolRelationshipTypes = new (class extends ApiBase {
  /**
   * Список типов заключения отношений с клиентом
   * @returns {Promise}
   */
  get() {
    return this._GET(
      `/online-school/product/options/customer-relationship-types`,
    )
  }
})()
