export default () => ({
  timetable: {
    id: null,
    school: null,
    educationType: null,
    grade: null,
    stream: null,
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },

  options: {
    educationTypes: [],
    grades: [],
    schools: [],
    streams: [],
  },
})
