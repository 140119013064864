<template>
  <div class="d-flex align-items-center no-wrap">
    <div
      v-for="rating in maxRate"
      :key="rating"
      :class="itemCssClass"
      class="d-flex align-items-center justify-content-center"
      @click="() => onChange(rating)"
    >
      <img :src="getIcon(rating)" />
    </div>
  </div>
</template>

<script>
import StarEmpty from '@/static/images/components/productManagement/content/star-empty.svg'
import StarFilled from '@/static/images/components/productManagement/content/star-filled.svg'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RatingStars',

  model: {
    prop: 'rate',
    event: 'change',
  },

  props: {
    rate: {
      type: Number,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxRate: 5,
    }
  },

  computed: {
    itemCssClass() {
      return this.disabled ? '' : 'cursor-pointer'
    },
  },

  methods: {
    getIcon(rating) {
      return rating > this.rate ? StarEmpty : StarFilled
    },

    onChange(rating) {
      this.$emit('change', rating)
    },
  },
})
</script>
