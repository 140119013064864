import ApiBase from 'ApiRest/ApiBase'

export default new (class Checkers extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/option/schools/${schoolId}/ctp-checkers`)
  }
})()
