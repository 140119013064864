export default () => ({
  studentEnrolled: {
    analytics: {
      students: null,
      schools: null,
      cost: 0,
    },

    filters: null,
    pagination: null,
  },
})
