import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolSettings = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/schools/1/tariffs/3')
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  patch(data) {
    return this._PATCH('/schools/1/tariffs/3', data)
  }
})()
