import { ApiSupportIdeaComment } from 'ApiRest/Api/Support/Idea/Comment'
import { ApiSupportIdea } from 'ApiRest/Api/Support/Idea/Idea'
import { ApiSupportIdeaLogs } from 'ApiRest/Api/Support/Idea/Log'
import { ApiSupportIdeaOptionStatus } from 'ApiRest/Api/Support/Idea/Option/Status'

export default {
  async fetchTheIdea({ commit, getters }) {
    const params = {
      expand: 'school',
    }

    const { data } = await ApiSupportIdea.get(getters.ideaId, params)

    commit('setTheIdea', data)
  },

  async updateTheIdea({ getters }, payload) {
    await ApiSupportIdea.patch(getters.ideaId, payload)
  },

  async addNewComment({ getters }, payload) {
    await ApiSupportIdeaComment.post(getters.ideaId, payload)
  },

  async updateComment({ getters }, { commentId, payload }) {
    await ApiSupportIdeaComment.patch(getters.ideaId, commentId, payload)
  },

  async fetchStatuses({ commit }) {
    const { data } = await ApiSupportIdeaOptionStatus.get()

    commit('setOption', {
      path: 'statuses',
      value: data,
    })
  },

  async fetchLogs({ commit, getters }) {
    const { data } = await ApiSupportIdeaLogs.get(getters.ideaId)

    commit('setLogs', data)
  },
}
