import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/diagnostics/configuration/${schoolId}/subjects`)
  }

  /**
   * @param {number} schoolId
   * @param {Object} data
   * @returns {Promise}
   */
  post(schoolId, data = {}) {
    return this._POST(`/diagnostics/configuration/${schoolId}/subjects`, data)
  }
})()
