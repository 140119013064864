import ApiBase from 'ApiRest/ApiBase'

export const ApiClientsOrders = new (class extends ApiBase {
  /**
   * @param {number} clientId
   * @returns {Promise}
   */
  get(clientId) {
    return this._GET(`/user/client/${clientId}/orders`)
  }
})()
