import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLesson = new (class extends ApiBase {
  /**
   * Получение урока
   * @param {number} onlineLessonId
   * @param {Object} params
   * @returns {Promise}
   */
  get(onlineLessonId, params = {}) {
    const url = `/online-lessons/${onlineLessonId}?${UrlHelper.param(params)}`

    return this._GET(url)
  }

  /**
   * Удаление урока
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  delete(onlineLessonId) {
    return this._DELETE(`/online-lessons/${onlineLessonId}`)
  }

  /**
   * Создание урока
   * @param {Object} payload
   * @param {Object} params
   * @returns {Promise}
   */
  post(payload, params) {
    const url = `/online-lessons?${UrlHelper.param(params)}`

    return this._POST(url, payload)
  }

  /**
   * Редактирование урока
   * @param {number} onlineLessonId
   * @param {Object} payload
   * @param {Object} params
   * @returns {Promise}
   */
  patch(onlineLessonId, payload, params) {
    const url = `/online-lessons/${onlineLessonId}?${UrlHelper.param(params)}`

    return this._PATCH(url, payload)
  }
})()
