import { RESET_STATE } from '@/helpers/ResetState'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setChart(state, payload) {
    state.chart = payload
  },

  setCommon(state, payload) {
    state.common = payload
  },

  setData(state, payload) {
    state.data = payload
  },

  setDetail(state, payload) {
    state.detail = payload
  },

  setUsers(state, payload) {
    state.users = payload
  },

  setDetailPeriod(state, payload) {
    state.detailPeriod = payload
  },

  setDefaultDetailPeriod(state) {
    state.detailPeriod = state.detailPeriods[0]
  },

  setDetailLevel(state, payload) {
    state.detailLevel = payload
  },
}
