<template>
  <div class="mb-4">
    <UiKitFormField :label="t('label')">
      <UiKitSlickSortList v-model="program" :use-drag-handle="true">
        <SlickItem
          v-for="(programBlock, index) in program"
          :key="index"
          :index="index"
          class="mb-3"
        >
          <ProgramBlockItem
            :item="programBlock"
            @edit="() => onEdit(programBlock, index)"
            @remove="() => onRemove(index)"
          />
        </SlickItem>
      </UiKitSlickSortList>
    </UiKitFormField>

    <UiKitButton class="btn-success" @click="onClickBtnAdd">
      <i class="fa fa-plus"></i>
      {{ t('btn_add') }}
    </UiKitButton>

    <UiKitFormFieldErrors :errors="v$.localItem.program.$errors" />

    <ModalAddProgram ref="modalAddProgram" @add="onAddProgram" />

    <ModalEditProgram
      ref="modalEditProgram"
      @change="onChangeProgram"
      @hide="onHideModalEditProgramBlock"
    />
  </div>
</template>

<script>
import ModalAddProgram from '@/components/productManagement/product/content/items/program/ModalAddProgram.vue'
import ModalEditProgram from '@/components/productManagement/product/content/items/program/ModalEditProgram.vue'
import ProgramBlockItem from '@/components/productManagement/product/content/items/program/ProgramBlockItem.vue'
import { required } from '@/helpers/i18n-validators'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitFormFieldErrors from '@/ui/UiKitFormFieldErrors.vue'
import UiKitSlickSortList from '@/ui/UiKitSlickSort/UiKitSlickSortList.vue'
import { useVuelidate } from '@vuelidate/core'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'
import { SlickItem } from 'vue-slicksort'

export default defineComponent({
  name: 'ProductContentItemProgram',

  components: {
    UiKitFormFieldErrors,
    ProgramBlockItem,
    UiKitFormField,
    ModalEditProgram,
    ModalAddProgram,
    UiKitButton,
    UiKitSlickSortList,
    SlickItem,
  },

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      localItem: {
        program: {
          required,
        },
      },
    }
  },

  data() {
    return {
      programBlockIndex: null,
      localItem: null,
    }
  },

  computed: {
    program: {
      get() {
        return this.localItem.program
      },

      set(value) {
        this.setProgram(value)
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = cloneDeep(this.item)
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    onClickBtnAdd() {
      this.$refs.modalAddProgram.show()
    },

    onEdit(programBlock, index) {
      this.programBlockIndex = index
      this.$refs.modalEditProgram.show(programBlock)
    },

    onRemove(index) {
      this.removeProgram(index)
      this.emitChange()
    },

    onAddProgram(programBlock) {
      this.addProgram(programBlock)
      this.emitChange()
    },

    onChangeProgram(programBlock) {
      this.updateProgram({
        index: this.programBlockIndex,
        value: programBlock,
      })
      this.emitChange()
    },

    onHideModalEditProgramBlock() {
      this.programBlockIndex = null
    },

    removeProgram(index) {
      this.localItem.program.splice(index, 1)
    },

    updateProgram({ index, value }) {
      this.$set(this.localItem.program, index, value)
    },

    addProgram(programBlock) {
      this.localItem.program.push(programBlock)
    },

    setProgram(value) {
      this.localItem.program = value
      this.emitChange()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.program.${name}`)
    },
  },
})
</script>
