import { isNull } from 'lodash'

export default {
  libraryTableData(state) {
    return isNull(state.library)
      ? []
      : [
          state.library,
        ]
  },
}
