import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class TheFamilyEducationStatement extends ApiBase {
  /**
   * @param {number} familyCertificationId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(familyCertificationId, parameters = {}) {
    return this._GET(
      `/family-statement/${familyCertificationId}?${UrlHelper.param(
        parameters,
      )}`,
    )
  }
})()
