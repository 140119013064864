export default () => ({
  groups: [],
  group: {
    id: null,
    name: '',
    seatsNumber: null,
    vacantSeats: null,
    products: [],
  },
  pagination: null,
})
