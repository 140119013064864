import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductOptionsCtps =
  new (class extends ApiBase {
    /**
     * Список ктп продукта
     * @param {number} productId
     * @returns {Promise}
     */
    get(productId) {
      return this._GET(`/courses/product/${productId}/options/ctps`)
    }
  })()
