<template>
  <div class="container h-100">
    <div class="row h-100">
      <div
        class="col-12 d-flex flex-column align-items-center justify-content-center text-center"
      >
        <h1 class="mb-4">{{ pageTitle }}</h1>

        <router-link to="/" class="btn btn-primary">
          {{ $t('pages.go_back_to_the_main_page') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  metaInfo() {
    return {
      title: this.errorText,
    }
  },

  props: {
    errorText: {
      type: String,
      default: '',
    },
  },

  computed: {
    pageTitle() {
      return this.errorText ? this.errorText : this.$t('pages.page_not_found')
    },
  },
}
</script>
