const routes = [
  {
    path: '/ctp',
    name: 'admin-ctps',
    meta: {
      accessControlGroup: 'ctp',
    },
    component: () => import('@/pages/ctp/Ctps.vue'),
  },
  {
    path: '/ctp/create',
    name: 'admin-the-ctp-create',
    meta: {
      accessControlGroup: 'ctp',
    },
    component: () => import('@/pages/ctp/TheCtp.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/ctp/:ctpId(\\d+)',
    name: 'admin-the-ctp',
    meta: {
      accessControlGroup: 'ctp',
    },
    component: () => import('@/pages/ctp/TheCtp.vue'),
  },
]

export default routes
