import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolOptionSubscriptions = new (class extends ApiBase {
  /**
   * Список подписок
   * @returns {Promise}
   */
  get() {
    return this._GET('/online-school/product/options/subscriptions')
  }
})()
