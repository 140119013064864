export default [
  {
    path: '/payments/invoices',
    name: 'admin-payment-invoices',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/PaymentInvoices.vue'),
  },
  {
    path: '/payments/invoices/create',
    name: 'admin-the-payment-invoice-create',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/ThePaymentInvoice.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/payments/invoices/:invoiceId(\\d+)',
    name: 'admin-the-payment-invoice',
    meta: {
      accessControlGroup: 'payment',
    },
    component: () => import('@/pages/payment/ThePaymentInvoice.vue'),
  },
]
