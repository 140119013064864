import { ApiProductManagementProductCategories } from 'ApiRest/Api/Products/ProductCategories'
import { ApiProductManagementProductCategory } from 'ApiRest/Api/Products/ProductCategory'
import { ApiProductManagementProductCategorySort } from 'ApiRest/Api/Products/ProductCategorySort'

export default {
  async fetchCategories({ commit }) {
    const { data } = await ApiProductManagementProductCategories.get()

    commit('setCategories', data)
  },

  async fetchCategory({ commit }, categoryId) {
    const { data } = await ApiProductManagementProductCategory.get(categoryId)

    commit('setCategory', data)
  },

  createTheCategory({ state }) {
    return ApiProductManagementProductCategory.post(state.category)
  },

  async updateTheCategory({ state }) {
    await ApiProductManagementProductCategory.patch(
      state.category,
      state.category.id,
    )
  },

  async removeTheCategory({ state }) {
    await ApiProductManagementProductCategory.delete(state.category.id)
  },

  async sortCategories({ state }) {
    await ApiProductManagementProductCategorySort.patch(state.categories)
  },
}
