import ApiBase from 'ApiRest/ApiBase'

export default new (class TheFamilyEducationCertification extends ApiBase {
  /**
   * @param {number} familyCertificationId
   * @returns {Promise}
   */
  get(familyCertificationId) {
    return this._GET(`/family-certifications/${familyCertificationId}`)
  }

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload = {}) {
    return this._POST(`/family-certifications`, payload)
  }

  /**
   * @param {number} familyCertificationId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(familyCertificationId, payload = {}) {
    return this._PATCH(
      `/family-certifications/${familyCertificationId}`,
      payload,
    )
  }

  /**
   * @param {number} familyCertificationId
   * @returns {Promise}
   */
  delete(familyCertificationId) {
    return this._DELETE(`/family-certifications/${familyCertificationId}`)
  }
})()
