import { ApiSubscriptionOptionsDaysBeforeBlock } from 'ApiRest/Api/Subscription/Options/DaysBeforeBlock'
import { ApiSubscriptionOptionsPaymentIntervals } from 'ApiRest/Api/Subscription/Options/PaymentIntervals'
import { ApiSubscriptionSubscribers } from 'ApiRest/Api/Subscription/Subscribers'
import { ApiSubscription } from 'ApiRest/Api/Subscription/Subscription'
import { ApiSubscriptionList } from 'ApiRest/Api/Subscription/Subscriptions'

export default {
  async fetchSubscriptions({ commit }, filters = {}) {
    const { data, headers } = await ApiSubscriptionList.get(filters)

    commit('setFilters', filters)
    commit('setSubscriptions', data)
    commit('setPagination', headers)
  },

  async fetchSubscription({ commit }, subscriptionId) {
    const { data } = await ApiSubscription.get(subscriptionId)

    commit('setSubscription', data)
  },

  async createSubscription({ commit, state }) {
    const { data } = await ApiSubscription.post(state.subscription)

    commit('setSubscription', data)
  },

  async updateSubscription({ commit, state }) {
    const { data } = await ApiSubscription.patch(
      state.subscription.id,
      state.subscription,
    )

    commit('setSubscription', data)
  },

  async removeSubscription({ commit }, subscriptionId) {
    const { data } = await ApiSubscription.delete(subscriptionId)

    commit('setSubscription', data)
  },

  async fetchPaymentIntervalsOptions({ commit }) {
    const { data } = await ApiSubscriptionOptionsPaymentIntervals.get()

    commit('setOptionsList', {
      path: 'paymentIntervals',
      value: data,
    })
  },

  async fetchDaysBeforeBlockOptions({ commit }) {
    const { data } = await ApiSubscriptionOptionsDaysBeforeBlock.get()

    commit('setOptionsList', {
      path: 'daysBeforeBlock',
      value: data,
    })
  },

  async fetchSubscriptionOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchDaysBeforeBlockOptions'),
      dispatch('fetchPaymentIntervalsOptions'),
    ])
  },

  async fetchSubscribers({ commit }, subscriptionId) {
    const { data } = await ApiSubscriptionSubscribers.get(subscriptionId)

    commit('setSubscribers', data)
  },
}
