import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiAnalyticsLibraryDownload = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const url = `/analytics/library/download?${UrlHelper.param(parameters)}`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
