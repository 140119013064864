import ApiBase from 'ApiRest/ApiBase'

export const ApiApplicationsSubjectList = new (class extends ApiBase {
  /**
   * Список предметов
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const url = `/diagnostics/extramural-diagnostics-subject/list?${this.getUrlParams(parameters)}`

    return this._GET(url)
  }
})()
