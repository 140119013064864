import ApiBase from 'ApiRest/ApiBase'

export default new (class FixRequired extends ApiBase {
  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/library/questions/fix-required/${id}`, data)
  }
})()
