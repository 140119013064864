import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolGenerateInvoice = new (class extends ApiBase {
  /**
   * @param {number} orderId
   * @returns {Promise}
   */
  post(orderId) {
    return this._POST(`/online-school/current-period-invoice/${orderId}`)
  }
})()
