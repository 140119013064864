import ApiBase from 'ApiRest/ApiBase'

export const ApiAssignIncomeDiagnostics = new (class extends ApiBase {
  /**
   * @param {number} requestId
   * @param {Object} data
   * @returns {Promise}
   */
  post(requestId, data) {
    const url = `/enrollment/request/${requestId}/assign-income-diagnostics`

    return this._POST(url, data)
  }
})()
