import { userActions } from '@/accessControl/userActions'

/**
 * Разрешения по группам роутов
 * @param {Object} data
 * @param {string} data.permission
 * @param {Object} data.helpers
 * @param {Map<string, string[]>} data.routes
 * @returns {boolean}
 */
export const setAbilityByPermissionPerRouteGroup = ({
  helpers,
  permission,
  routes,
}) => {
  let isRuleSet = false

  routes.forEach((routeNames, routeGroupName) => {
    if (!isRuleSet) {
      switch (routeGroupName) {
        // Спецведомость
        case 'special-statement':
          if (
            [
              'special_statement',
            ].includes(permission)
          ) {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Аттестации
        case 'certification':
          if (
            [
              'certification',
              'certification_create_edit',
              'certification_edit_special',
            ].includes(permission)
          ) {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Клиенты
        case 'client':
          if (permission === 'clients_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'clients_write') {
            helpers.allowModifyRoutes(routeNames)
            helpers.allowActionOnRoutes(
              userActions.UPDATE_CLIENT_TEST_FLAG,
              routeNames,
            )
            isRuleSet = true
          }

          break

        // Клиенты: демо
        case 'client-demo':
          if (permission === 'clients_demo_lead_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'clients_demo_lead_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // КТП
        case 'ctp':
          if (permission === 'ctp_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'ctp_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // CRM Договоров
        case 'enrollment-contract':
          if (permission === 'enrollment_contracts_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'enrollment_contracts_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'enrollment_contract_export') {
            helpers.allowActionOnRoutes(
              userActions.UPDATE_CONTRACT_SEND_1C,
              routeNames,
            )
            isRuleSet = true
          }

          break

        // CRM Заявок
        case 'enrollment-request':
          if (permission === 'enrollment_requests_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'enrollment_requests_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // События
        case 'event':
          if (permission === 'simple_events_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'simple_events_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Новости
        case 'news':
          if (permission === 'news') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Online-уроки
        case 'online-room':
          if (permission === 'online_rooms_view') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'online_rooms_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Платежи
        case 'payment':
          if (permission === 'payments') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'invoices_excel_export') {
            helpers.allowActionOnRoutes(
              userActions.INVOICES_EXCEL_EXPORT,
              routeNames,
            )
            isRuleSet = true
          }

          if (permission === 'invoices_change') {
            helpers.allowActionOnRoutes(userActions.INVOICES_CHANGE, routeNames)
            isRuleSet = true
          }

          if (permission === 'invoices_view_auto_change_status_checkbox') {
            helpers.allowActionOnRoutes(
              userActions.INVOICES_CHANGE_STATUS_CHECKBOX,
              routeNames,
            )
            isRuleSet = true
          }

          break

        // Цены
        case 'price':
          if (permission === 'prices') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: учебный год
        case 'settings-academic-year':
          if (permission === 'academic_year_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'academic_year_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: расписания
        case 'settings-timetables':
          if (permission === 'fulltime_timetable') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: праздники
        case 'settings-holiday':
          if (permission === 'settings_holidays') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: партнеры
        case 'settings-partner':
          if (permission === 'partners_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'partners_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: роли
        case 'settings-role':
          if (permission === 'roles_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'roles_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: школы
        case 'settings-school':
          if (permission === 'school_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'school_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: сотрудники
        case 'settings-user':
          if (permission === 'managers_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'managers_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'managers_unblock') {
            helpers.allowActionOnRoutes(userActions.UNBLOCK_USER, routeNames)
            isRuleSet = true
          }

          break

        // Настройки: справка
        case 'settings-wiki':
          if (
            [
              'wiki_settings',
              'wiki_write_own',
            ].includes(permission)
          ) {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: СО
        case 'settings-family-education':
          if (permission === 'settings_family_education') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Настройки: Триггерные рассылки
        case 'settings-trigger-sendings':
          if (permission === 'settings_notification_center') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Ученики
        case 'student':
          if (permission === 'students_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'students_write') {
            helpers.allowModifyRoutes(routeNames)
            helpers.allowActionOnRoutes(
              userActions.UPDATE_STUDENT_TEST_FLAG,
              routeNames,
            )
            isRuleSet = true
          }

          if (permission === 'students_physicon') {
            helpers.allowActionOnRoutes(
              userActions.UPDATE_ACCESS_PHYSICON,
              routeNames,
            )
            isRuleSet = true
          }

          if (permission === 'students_allow_upload_fe') {
            helpers.allowActionOnRoutes(
              userActions.STUDENTS_ACCESS_UPLOAD_FE,
              routeNames,
            )
            isRuleSet = true
          }

          if (permission === 'students_allow_upload_extramural') {
            helpers.allowActionOnRoutes(
              userActions.STUDENTS_ACCESS_UPLOAD_EXTRAMURAL,
              routeNames,
            )
            isRuleSet = true
          }

          break

        // Ученики: демо
        case 'student-demo':
          if (permission === 'students_demo_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'students_demo_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Тесты
        case 'test':
          if (permission === 'tests_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'tests_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Тесты: проверка
        case 'test-check':
          if (
            [
              'tests_check',
              'tests_check_all',
            ].includes(permission)
          ) {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Тесты: проверка домашних заданий
        // TODO: чем отличается "Домашние задания" (homeworks) от
        //       Просмотр всех домашек на проверке (all_homeworks)
        case 'homework':
          if (
            [
              'homeworks',
              'all_homeworks',
            ].includes(permission)
          ) {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Тесты: оплата
        case 'test-payment':
          if (permission === 'tests_payment') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Аттестация СО
        case 'family-certification':
          if (permission === 'certification_family_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'certification_family_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Онлайн-школа
        case 'online-school':
          if (permission === 'menu_online_school') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Онлайн-школа: заявки на звонки
        case 'online-school-call-requests':
          if (permission === 'online_school_call_requests') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Онлайн-школа: заявки на продукты
        case 'online-school-product-requests':
          if (permission === 'online_school_product_requests') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Онлайн-школа: продукты
        case 'online-school-products':
          if (permission === 'online_school_products') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Онлайн-школа: заказы
        case 'online-school-orders':
          if (permission === 'online_school_orders') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Онлайн-школа: настройки
        case 'online-school-settings':
          if (permission === 'online_school_settings') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Аттестация СО: заявки
        case 'family-certification-request':
          if (permission === 'certification_family_requests_read') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          if (permission === 'certification_family_requests_write') {
            helpers.allowModifyRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Справка
        case 'wiki':
          if (permission === 'wiki_read_own') {
            helpers.allowReadRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Продукты
        case 'product-management':
          if (permission === 'product_management_index') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Заказы
        case 'product-management-orders':
          if (permission === 'product_management_orders') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Аналитика
        case 'product-management-analytics':
          if (permission === 'product_management_analytics') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Настройка баннеров
        case 'product-management-banners':
          if (permission === 'product_management_banners') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Категории
        case 'product-management-categories':
          if (permission === 'product_management_categories') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Настройка параметров
        case 'product-management-parameters':
          if (permission === 'product_management_parameters') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Заявки
        case 'product-management-requests':
          if (permission === 'product_management_requests') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Настройки
        case 'product-management-settings':
          if (permission === 'product_management_settings') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Реклама
        case 'product-management-advertisements':
          if (permission === 'product_management_ads') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Управление продуктами: Вопросы
        case 'product-management-questions':
          if (permission === 'product_management_questions') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        // Администрирование: Zoom
        case 'administrating-zoom':
          if (permission === 'zoom_read') {
            helpers.allowCrudRoutes(routeNames)
            isRuleSet = true
          }

          break

        default:
          break
      }
    }
  })

  return isRuleSet
}
