import { RESET_STATE } from '@/helpers/ResetState'
import { set } from 'lodash'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setTheBanner(state, payload) {
    state.banner = payload
  },

  setOptions(state, { path, value }) {
    set(state.options, path, value)
  },

  setBannerValue(state, { path, value }) {
    set(state.banner, path, value)
  },

  setBannerId(state, payload) {
    state.bannerId = payload
  },
}
