import actions from './actions'
import content from './content/store'
import mutations from './mutations'
import questions from './questions/store'
import state from './state'

export default {
  namespaced: true,
  state,
  mutations,
  actions,

  modules: {
    content,
    questions,
  },
}
