import ApiBase from 'ApiRest/ApiBase'

export const ApiProductGroup = new (class extends ApiBase {
  /**
   * Получение данных об определённой группе
   * @param {number} groupId
   * @returns {Promise}
   */
  get(groupId) {
    return this._GET(`/product/groups/${groupId}`)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/product/groups', data)
  }

  /**
   * @param {number} groupId
   * @param {Object} data
   * @returns {Promise}
   */
  put(groupId, data) {
    return this._PUT(`/product/groups/${groupId}`, data)
  }
})()
