import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementOrderClientDocument =
  new (class extends ApiBase {
    /**
     * Удаление документа клиента
     * @param {Object} params
     * @param {number} params.orderId
     * @param {number} params.fileId
     * @returns {Promise}
     */
    delete({ orderId, fileId }) {
      return this._DELETE(`/courses/order/${orderId}/client-document/${fileId}`)
    }
  })()
