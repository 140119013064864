import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSchools(state, payload) {
    state.schools = payload
  },

  setSettings(state, payload) {
    state.settings = payload
  },

  updateTheSetting(state, { name, value }) {
    state.settings[name] = value || null
  },

  updateTheSettingStream(state, { id, value }) {
    for (const grade of state.settings.grades) {
      if (grade.id === id) {
        grade.stream = value
      }
    }
  },

  setGrades(state, payload) {
    state.grades = payload
  },

  setServices(state, payload) {
    state.services = payload
  },

  setStreams(state, payload) {
    if (payload.length) {
      state.streams.push(...payload)
    }
  },

  setTemplateContracts(state, payload) {
    state.templates.contracts = payload
  },

  setTemplateOffers(state, payload) {
    state.templates.offers = payload
  },

  setTemplateApplications(state, payload) {
    state.templates.applications = payload
  },
}
