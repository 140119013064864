import ApiBase from 'ApiRest/ApiBase'

export const ApiProductCertificateRevoke = new (class extends ApiBase {
  /**
   * Отзыв сертификата
   * @param {Object} parameters
   * @returns {Promise}
   */
  delete(parameters) {
    const url = `/product/certificate-issue/revoke?${this.getUrlParams(
      parameters,
    )}`

    return this._DELETE(url)
  }
})()
