import { ApiDashboardItemList } from 'ApiRest/Api/Dashboard/ItemList'
import { ApiDashboardTypeList } from 'ApiRest/Api/Dashboard/TypeList'
import ApiUserSettings from 'ApiRest/Api/UserSettings'

export default {
  async fetchUserSettings({ commit }) {
    const { data } = await ApiUserSettings.get({
      name: 'admin-dashboard',
    })

    commit('setUserSettings', data?.data)
  },

  async saveUserSettings({ state, commit }) {
    const { data } = await ApiUserSettings.put({
      name: 'admin-dashboard',
      data: state.userSettings,
    })

    commit('setUserSettings', data?.data)
  },

  async fetchTypeList({ commit }) {
    const { data } = await ApiDashboardTypeList.get()

    commit('setTypeList', data)
  },

  /**
   * @param {Object} context
   * @param {Function} context.commit
   * @param {number} typeId
   * @returns {Promise<void>}
   */
  async fetchItemList({ commit }, typeId) {
    const { data } = await ApiDashboardItemList.get(typeId)

    commit('setItemList', {
      typeId,
      data,
    })
  },

  reset({ commit }) {
    commit('resetTypeList')
    commit('resetItemListMap')
    commit('resetUserSettings')
  },
}
