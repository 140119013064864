import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementBannerPositionOptions =
  new (class extends ApiBase {
    /**
     * Список статусов баннера
     * @returns {Promise}
     */
    get() {
      return this._GET(`/courses/options/banner-positions`)
    }
  })()
