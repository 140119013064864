import { Pagination } from '@/helpers/CompositePagination'
import { RESET_STATE } from '@/helpers/ResetState'
import { isNil } from 'lodash'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setSendings(state, payload) {
    state.sendings = payload
  },

  setSending(state, payload) {
    state.sending = payload
  },

  setAnalytics(state, payload) {
    state.analytics = payload
  },

  updateTemplate(state) {
    if (!isNil(state.sending.template)) {
      if (
        !state.options.templates.some(
          (template) => template.id === state.sending.template.id,
        )
      ) {
        state.sending.template = null
      }
    }
  },

  applyTemplateToSending(state) {
    const { id, name, ...templateProps } = state.sending.template

    state.sending = {
      ...state.sending,
      ...templateProps,
    }
  },

  setValue(state, { key, value }) {
    Vue.set(state.sending, key, value)
  },

  setSendingFilters(state, payload = {}) {
    if (!state.sending.filters) {
      state.sending.filters = {}
    }

    state.sending.filters = {
      ...state.sending.filters,
      ...payload,
    }
  },

  setFinalUsers(state, payload) {
    state.finalUsers = payload
  },

  addFinalUsers(state, users) {
    const result = {}
    const allUsers = [
      ...state.finalUsers,
      ...users,
    ]

    allUsers.forEach((user) => {
      result[user.id] = user
    })

    state.finalUsers = Object.values(result)
  },

  addFinalUser(state, user) {
    const result = {}

    state.finalUsers.forEach((finalUser) => {
      result[finalUser.id] = finalUser
    })

    result[user.id] = user

    state.finalUsers = Object.values(result)
  },

  removeFinalUser(state, userId) {
    state.finalUsers = state.finalUsers.filter(
      (user) => Number(user.id) !== userId,
    )
  },

  setFormListValue(state, value) {
    state.isFormListNow = value
  },

  saveUsers(state) {
    state.sending.users = state.finalUsers
  },

  clearTempUsers(state) {
    state.finalUsers = []
    state.currentUsers = []
  },

  setOptionsList(state, { path, value }) {
    Vue.set(state.options, path, value)
  },

  setPagination(state, headers) {
    const compositePagination = new Pagination()

    compositePagination.updateFromHeaders(headers)

    const { pagination } = state

    if (pagination !== null) {
      compositePagination.setRowsPerPage(pagination.rowsPerPage)
    }

    state.pagination = compositePagination
  },

  setPaginationRowsPerPage(state, { value }) {
    state.pagination.setRowsPerPage(value)
  },

  setFilters(state, filters) {
    state.filters = filters
  },
}
