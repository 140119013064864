import ApiBase from 'ApiRest/ApiBase'

export const ApiSubscriptionOptionsPaymentIntervals =
  new (class extends ApiBase {
    /**
     * Получаем список интервалов платежей
     * @returns {Promise}
     */
    get() {
      return this._GET('/subscription/options/payment-intervals')
    }
  })()
