import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setRequests(state, payload) {
    state.requests = payload
  },

  setTheRequest(state, payload) {
    state.theRequest = payload
  },

  setTheRequestValue(state, { key, value }) {
    Vue.set(state.theRequest, key, value)
  },

  setClientId(state, payload) {
    state.clientId = payload
  },

  setStudentId(state, payload) {
    state.studentId = payload
  },

  setOption(state, { key, value }) {
    Vue.set(state.option, key, value)
  },

  unsetOption(state, key) {
    Vue.set(state.option, key, [])
  },

  /**
   * @param {Object|undefined} state
   * @param {Object} payload
   */
  addTheCertificationRequestDraftTest(state, payload) {
    state.theRequest.certification.tests.push(payload)
  },
}
