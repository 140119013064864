export default () => ({
  studentId: null,
  student: null,
  statementPeriod: {},
  statementFullTimeEducation: null,
  statementExtramuralEducation: null,
  currentGroupsOnly: true,
  analytics: null,
  statementShowcase: {
    intramural: [],
    extramural: [],
  },
  statementOnlineSchool: {
    intramural: [],
    extramural: [],
  },
  options: {
    streamAcademicYearPreset: [],
  },
})
