import ApiBase from 'ApiRest/ApiBase'

export const ApiApplicationMassStartWork = new (class extends ApiBase {
  /**
   * @param {Record} data
   * @returns {Promise<?>}
   */
  post(data) {
    const url = `/diagnostics/extramural-diagnostics-subject/set-tests-available`

    return this._POST(url, data)
  }
})()
