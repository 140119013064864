<template>
  <div class="uiKit-contentGroup">
    <h3 v-if="title" class="title">
      {{ title }}

      <span v-if="errorsContentGroup" class="errorsContentGroup">
        {{ errorsContentGroup }}
      </span>

      <slot name="title-after"></slot>
    </h3>

    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiKitContentGroup',

  props: {
    errorsContentGroup: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    },
  },
})
</script>

<style lang="scss">
@import '~@/scss/functions';
@import '~@/scss/bootstrap/variables';

.uiKit-contentGroup {
  padding: 40px;
  margin-bottom: $inputHeight;
  background: $gray-900;
  border-radius: 12px;
  box-shadow:
    0 6px 40px var(--uiKit-contentGroup-boxShadow-color),
    inset 0 1px 2px var(--uiKit-contentGroup-boxShadow-inset-color);

  &:hover {
    box-shadow: 0 6px 40px var(--uiKit-contentGroup-boxShadowHover-color);
  }

  > .title {
    margin-bottom: 25px;
  }

  .errorsContentGroup {
    position: absolute;
    display: block;
    margin-top: 3px;
    line-height: 16px;
    color: $bright-red;
    letter-spacing: 0.01em;
    opacity: 0.7;

    @include font-size(11px);
  }
}
</style>
