<template>
  <label :class="labelCssClass">
    <input
      v-model="model"
      :class="inputCssClass"
      :disabled="disabled"
      :value="value"
      type="checkbox"
      v-bind="$attrs"
    />

    <span :class="$style.toggle"></span>

    <slot name="label">
      <span v-if="label" :class="$style.text">
        {{ label }}
      </span>
    </slot>

    <i
      v-if="hint"
      v-tippy="{ placement: 'right', arrow: true }"
      :content="hint"
      class="form-hint ml-2 fas fa-question-circle"
    ></i>
  </label>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiKitCheckbox',

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    checked: {
      type: [
        Boolean,
        Array,
      ],

      default: false,
    },

    value: {
      type: null,
      default: null,
    },

    label: {
      type: String,
      default: '',
    },

    hint: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isRadio: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    model: {
      get() {
        return this.checked
      },

      set(value) {
        this.$emit('change', value)
        // TODO: убрать input-event
        // input-event временно оставлен, чтобы не сломать код в других ветках.
        this.$emit('input', value)
      },
    },

    labelCssClass() {
      return [
        this.$style.label,
        {
          [this.$style.disabled]: this.disabled,
        },
      ]
    },

    inputCssClass() {
      return [
        this.$style.input,
        {
          [this.$style.radio]: this.isRadio,
        },
      ]
    },
  },
})
</script>

<style lang="scss" module>
@import '~@/scss/functions';
@import '~@/scss/bootstrap/variables';

$iconActive: '~Static/images/components/uiKit/checkbox/iconActive.svg';
$colorActive: $green;
$colorInactive: $gray-100;
$backgroundColorInactive: $gray-700;
$colorNotChecked: var(--uiKit-checkbox-colorNotChecked);
$borderWidth: 0.5px;
$borderRadius: 2px;
$size: $fontSize;

.label {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;

  &.disabled {
    color: $colorNotChecked;
    cursor: default;

    .toggle {
      background-color: $backgroundColorInactive;
      border-color: $backgroundColorInactive;
    }

    .input:checked ~ .toggle {
      background: $backgroundColorInactive url('#{$iconActive}') center center
        no-repeat;
      border-color: $backgroundColorInactive;
    }
  }
}

.input {
  display: none;

  &.radio ~ .toggle {
    border-radius: 9999px;
  }

  &:checked ~ .toggle {
    background: $colorActive url('#{$iconActive}') center center no-repeat;
    border-color: $colorActive;
  }

  &:not(:checked) ~ .text {
    color: $colorNotChecked;
  }
}

.toggle {
  width: $size;
  min-width: $size;
  height: $size;
  border: solid $borderWidth $colorInactive;
  border-radius: $borderRadius;
}

.text {
  padding-left: 8px;
  user-select: none;

  @include font-size($size);
}
</style>
