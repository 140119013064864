import ApiQuestion from 'ApiRest/Api/Library/Section/Topic/Question'
import ApiQuestionApproved from 'ApiRest/Api/Library/Section/Topic/Question/Approved'
import ApiQuestionFixRequired from 'ApiRest/Api/Library/Section/Topic/Question/FixRequired'
import ApiQuestionModeration from 'ApiRest/Api/Library/Section/Topic/Question/Moderation'
import ApiQuestionProofreading from 'ApiRest/Api/Library/Section/Topic/Question/Proofreading'
import ApiQuestionRestore from 'ApiRest/Api/Library/Section/Topic/Question/Restore'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchTheLibraryQuestion({ commit }, questionId) {
    const { data } = await ApiQuestion.get(questionId)

    commit('setTheLibraryQuestion', data)
  },

  async createTheLibraryQuestion({ commit }, { topicId, payload }) {
    const { data } = await ApiQuestion.post(topicId, payload)

    commit('setTheLibraryQuestion', data)
  },

  async updateTheLibraryQuestion({ commit }, { questionId, payload }) {
    const { data } = await ApiQuestion.patch(questionId, payload)

    commit('setTheLibraryQuestion', data)
  },

  async deleteTheLibraryQuestion(context, questionId) {
    await ApiQuestion.delete(questionId)
  },

  restoreTheLibraryQuestion(context, { questionId, payload }) {
    return ApiQuestionRestore.put(questionId, payload)
  },

  async copyTheLibraryQuestion({ commit }, { questionId, payload }) {
    const { data } = await ApiQuestion.put(questionId, payload)

    commit('setTheLibraryQuestion', data)
  },

  async changeTheLibraryQuestionStatusToModeration(
    { commit },
    { questionId, payload },
  ) {
    const { data } = await ApiQuestionModeration.put(questionId, payload)

    commit('setTheLibraryQuestion', data)
  },

  async changeTheLibraryQuestionStatusToApproved(
    { commit },
    { questionId, payload },
  ) {
    const { data } = await ApiQuestionApproved.put(questionId, payload)

    commit('setTheLibraryQuestion', data)
  },

  async changeTheLibraryQuestionStatusToProofreading(
    { commit },
    { questionId, payload },
  ) {
    const { data } = await ApiQuestionProofreading.put(questionId, payload)

    commit('setTheLibraryQuestion', data)
  },

  async changeTheLibraryQuestionStatusToFixRequired(
    { commit },
    { questionId, payload },
  ) {
    const { data } = await ApiQuestionFixRequired.put(questionId, payload)

    commit('setTheLibraryQuestion', data)
  },
}
