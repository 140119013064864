const routes = [
  {
    path: '/family-certifications',
    name: 'admin-family-certifications',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import('@/pages/familyCertification/FamilyCertifications.vue'),
  },
  {
    path: '/family-certification/create',
    name: 'admin-the-family-certification-create',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import('@/pages/familyCertification/TheFamilyCertification.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/family-certification/:certificationId(\\d+)',
    component: () =>
      import('@/pages/familyCertification/TheFamilyCertification.vue'),
    children: [
      {
        path: 'statement/:studentId(\\d+)',
        name: 'admin-the-family-statement-personal',
        meta: {
          accessControlGroup: 'family-certification',
        },
        component: () =>
          import('@/pages/familyCertification/TheFamilyPersonalStatement.vue'),
      },
      {
        path: '',
        name: 'admin-the-family-certification',
        meta: {
          accessControlGroup: 'family-certification',
        },
        component: () =>
          import('@/pages/familyCertification/TheFamilyStatement.vue'),
      },
    ],
  },
  {
    path: '/family-certifications-schedule',
    name: 'admin-family-certifications-schedule',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import('@/pages/familyCertification/FamilyCertificationsSchedule.vue'),
  },
  {
    path: '/family-certification-schedule/create',
    name: 'admin-the-family-certification-schedule-create',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import('@/pages/familyCertification/TheFamilyCertificationSchedule.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/family-certification-schedule/:scheduleId(\\d+)',
    name: 'admin-the-family-certification-schedule',
    meta: {
      accessControlGroup: 'family-certification',
    },
    component: () =>
      import('@/pages/familyCertification/TheFamilyCertificationSchedule.vue'),
  },
  {
    path: '/family-certification-requests',
    name: 'admin-family-certification-requests',
    meta: {
      accessControlGroup: 'family-certification-request',
    },
    component: () =>
      import('@/pages/familyCertification/FamilyCertificationRequests.vue'),
  },
  {
    path: '/family-certification-request/:requestId(\\d+)',
    name: 'admin-the-family-certification-request',
    meta: {
      accessControlGroup: 'family-certification-request',
    },
    component: () =>
      import('@/pages/familyCertification/TheFamilyCertificationRequest.vue'),
  },
  {
    path: '/family-certification-request-draft',
    name: 'admin-the-family-certification-request-draft',
    meta: {
      accessControlGroup: 'family-certification-request',
    },
    component: () =>
      import(
        '@/pages/familyCertification/TheFamilyCertificationRequestDraft.vue'
      ),
  },
  {
    path: '/family-certification-statement',
    name: 'admin-family-certification-statement',
    component: () =>
      import('@/pages/familyCertification/FamilyCertificationStatement.vue'),
  },
]

export default routes
