<template>
  <div :class="$style.block">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BlockEdit',
})
</script>

<style module lang="scss">
.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px 10px 10px;
  background: var(--app-gray-700);
}
</style>
