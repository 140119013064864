import ApiBase from 'ApiRest/ApiBase'

export default new (class ExaminationLevel extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/schools/${id}/examination-levels`)
  }
})()
