import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiProductCertificateUserList = new (class extends ApiBase {
  /**
   * Список пользователей с сертификатами
   * @param {Object} params
   * @returns {Promise}
   */
  get(params) {
    const urlParams = UrlHelper.param(params)
    const url = `/product/certificate-issue/users?${urlParams}`

    return this._GET(url)
  }
})()
