import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolOrderStatusOptions = new (class extends ApiBase {
  /**
   * Список статусов заказа
   * @returns {Promise}
   */
  get() {
    return this._GET(`/online-school/order/option/statuses`)
  }
})()
