import { ApiProductManagementNavigationBanner } from 'ApiRest/Api/NavigationBanner'
import { ApiProductManagementNavigationBannerBlock } from 'ApiRest/Api/NavigationBanner/Block'
import { ApiProductManagementNavigationBannerFormatOptions } from 'ApiRest/Api/NavigationBanner/FormatOptions'

export default {
  async fetchNavigationBannerBlock({ commit }) {
    const { data } = await ApiProductManagementNavigationBannerBlock.get()

    commit('setBlock', data)
  },

  async updateNavigationBannerBlock({ commit, state }) {
    const { data } = await ApiProductManagementNavigationBannerBlock.patch(
      state.block,
    )

    commit('setBlock', data)
  },

  async createNavigationBanner({ commit }, payload) {
    const { data } = await ApiProductManagementNavigationBanner.post(payload)

    commit('addNewBanner', data)
  },

  async fetchNavigationBannerFormatOptions({ commit }) {
    const { data } =
      await ApiProductManagementNavigationBannerFormatOptions.get()

    commit('setOptionsList', {
      path: 'formats',
      value: data,
    })
  },
}
