import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class List extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    return this._GET(
      `/price/extramural-education-individual-paid-prices?${UrlHelper.param(
        parameters,
      )}`,
    )
  }
})()
