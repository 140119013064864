export default () => ({
  schools: [],
  applications: [],
  application: {
    grade: null,
    school: null,
    enrollmentContractId: null,
    id: null,
    client: null,
    student: null,
    invoice: null,
    passingSubjects: [],
    retakes: [],
  },

  applicationId: null,

  applicationStatuses: [],
  gradeSubjectSettings: [],
  options: {
    subjects: [],
    services: [],
    grades: [],
  },

  configuration: {
    grades: [],
    timingSystemLink: null,
  },

  schoolId: null,
  grade: null,

  managers: [],
  subjectsExtramuralDiagnostics: {},
  subjectsFullTimeDiagnostics: {},
  tests: [],
  subjects: [],

  isStudentEducationalProgramBlocked: null,
  subjectsMassStartWork: [],
})
