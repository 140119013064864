export default () => ({
  schools: [],
  settings: {},
  grades: [],
  services: [],
  streams: [],
  templates: {
    contracts: [],
    applications: [],
    offers: [],
  },
})
