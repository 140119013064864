import ApiBase from 'ApiRest/ApiBase'

export default new (class TheCertificationRequest extends ApiBase {
  /**
   * @param {number} requestId
   * @returns {Promise}
   */
  get(requestId) {
    return this._GET(`/family-certification-requests/${requestId}`)
  }

  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    return this._POST(`/family-certification-requests`, payload)
  }

  /**
   * @param {number} requestId
   * @param {Object} payload
   * @returns {Promise}
   */
  patch(requestId, payload = {}) {
    return this._PATCH(`/family-certification-requests/${requestId}`, payload)
  }
})()
