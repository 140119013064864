import { cutTags } from '@/helpers/cutTags'
import ApiHomework from 'ApiRest/Api/Homework'
import ApiHomeworkList from 'ApiRest/Api/Homework/List'
import ApiOption from 'ApiRest/Api/Option'
import { ApiTestingCheckCommentHelper } from 'ApiRest/Api/Testing/Check/CommentHelper'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async homeworksFetch({ commit }, filters) {
    const response = await ApiHomeworkList.get(filters)

    commit('setHomeworks', response.data)

    return response
  },

  async theHomeworkFetch({ commit }, homeworkId) {
    const { data } = await ApiHomework.get(homeworkId)

    commit('setTheHomework', data)
  },

  async theHomeworkCheck(context, { homeworkId, payload }) {
    await ApiHomework.put(homeworkId, payload)
  },

  async changeCheckedByManager({ state, commit }) {
    const homeworkId = state.homework.id
    const params = {
      checkedByManager: {
        id: state.homework.checkedByManager.id,
      },
    }

    const { data } = await ApiHomework.patch(homeworkId, params)

    commit('theHomeworkSet', data)
  },

  async optionsCheckersFetch({ commit, state }) {
    const schoolId = state.homework.school?.id

    if (schoolId) {
      const { data } = await ApiOption.getCtpCheckers(schoolId)

      commit('setOptions', {
        key: 'checkers',
        value: data,
      })
    }
  },

  async fetchCommentSuggestionsList({ state }) {
    return ApiTestingCheckCommentHelper.get(state.homework.managerComment).then(
      ({ data }) =>
        data.map((item) => ({
          ...item,
          comment: cutTags(item.comment),
        })),
    )
  },

  async removeSuggestion(context, suggestionId) {
    await ApiTestingCheckCommentHelper.delete(suggestionId)
  },
}
