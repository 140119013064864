import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductOptionsGroups =
  new (class extends ApiBase {
    /**
     * @returns {Promise}
     */
    get() {
      return this._GET('/product/options/groups')
    }
  })()
