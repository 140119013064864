import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProductList = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    return this._GET(
      `/online-school/product/list?${this.getUrlParams(parameters)}`,
    )
  }
})()
