import ApiBase from 'ApiRest/ApiBase'

export const ApiSendingOptionsDetailStatuses = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET('/sending/option/detail-statuses')
  }
})()
