import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOption(state, { key, value }) {
    Vue.set(state.option, key, value)
  },

  setSourceValue(state, { key, value }) {
    Vue.set(state.source, key, value)
  },

  setTargetValue(state, { key, value }) {
    Vue.set(state.target, key, value)
  },
}
