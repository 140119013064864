import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductOptionsSubscriptions =
  new (class extends ApiBase {
    /**
     * Список подписок
     * @returns {Promise}
     */
    get() {
      return this._GET('/courses/product/options/subscriptions')
    }
  })()
