import ApiBase from 'ApiRest/ApiBase'

export default new (class AddCustomTest extends ApiBase {
  /**
   * @param {number} requestId
   * @param {Object} payload
   * @returns {Promise}
   */
  post(requestId, payload = {}) {
    return this._POST(
      `/family-certification-requests/${requestId}/add-custom-test`,
      payload,
    )
  }
})()
