import { ApiCourseManagementBanner } from 'ApiRest/Api/CourseManagement/Banners/Banner'
import { ApiCourseManagementBannerList } from 'ApiRest/Api/CourseManagement/Banners/BannerList'
import { ApiCourseManagementBannerPositionOptions } from 'ApiRest/Api/CourseManagement/Banners/BannerPositionOptions'
import { ApiCourseManagementBannerStatusOptions } from 'ApiRest/Api/CourseManagement/Banners/BannerStatusOptions'

export default {
  async fetchBanners({ commit }, filters = {}) {
    const { data, headers } = await ApiCourseManagementBannerList.get(filters)

    commit('setFilters', filters)
    commit('setBanners', data)
    commit('setPagination', headers)
  },

  async fetchTheBanner({ commit, state }) {
    const { data } = await ApiCourseManagementBanner.get(state.bannerId)

    commit('setTheBanner', data)
  },

  async updateTheBanner({ state, commit }) {
    const { data } = await ApiCourseManagementBanner.patch(
      state.bannerId,
      state.banner,
    )

    commit('setTheBanner', data)
  },

  removeBanner(context, bannerId) {
    return ApiCourseManagementBanner.delete(bannerId)
  },

  async createBanner({ state, commit }) {
    const { data } = await ApiCourseManagementBanner.post(state.banner)

    commit('setTheBanner', data)
  },

  async fetchPositionOptions({ commit }) {
    const { data } = await ApiCourseManagementBannerPositionOptions.get()

    commit('setOption', {
      path: 'position',
      value: data,
    })
  },

  async fetchStatusOptions({ commit }) {
    const { data } = await ApiCourseManagementBannerStatusOptions.get()

    commit('setOption', {
      path: 'status',
      value: data,
    })
  },
}
