import HttpHeaders from 'Api/const/HttpHeaders'
import ApiOption from 'ApiRest/Api/Option'
import ApiTransferStudents from 'ApiRest/Api/Stream/TransferStudents'
import ApiStudents from 'ApiRest/Api/User/Student/List'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchOptionSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      key: 'schools',
      value: data,
    })
  },

  async fetchOptionEducationTypes({ commit }, params) {
    const { data } = await ApiOption.getEducationTypes(params)

    commit('setOption', {
      key: 'educationTypes',
      value: data,
    })
  },

  async fetchOptionSourceGrades({ commit }, params) {
    const { data } = await ApiOption.getGrades(params)

    commit('setOption', {
      key: 'sourceGrades',
      value: data,
    })
  },

  async fetchOptionTargetGrades({ commit }, params) {
    const { data } = await ApiOption.getGrades(params)

    commit('setOption', {
      key: 'targetGrades',
      value: data,
    })
  },

  async fetchOptionSourceStreams({ commit }, params) {
    const { data } = await ApiOption.getStreams(params)

    commit('setOption', {
      key: 'sourceStreams',
      value: data,
    })
  },

  async fetchOptionTargetStreams({ commit }, params) {
    const { data } = await ApiOption.getStreams(params)

    commit('setOption', {
      key: 'targetStreams',
      value: data,
    })
  },

  async fetchSourceStudentsCount({ commit }, params) {
    const { headers } = await ApiStudents.get(params)
    const studentsCount = Number(headers[HttpHeaders.PAGINATION_ROWS_TOTAL])

    commit('setSourceValue', {
      key: 'studentsCount',
      value: studentsCount,
    })
  },

  async fetchTargetStudentsCount({ commit }, params) {
    const { headers } = await ApiStudents.get(params)
    const studentsCount = Number(headers[HttpHeaders.PAGINATION_ROWS_TOTAL])

    commit('setTargetValue', {
      key: 'studentsCount',
      value: studentsCount,
    })
  },

  async transferStudents({ commit }, payload) {
    const { data } = await ApiTransferStudents.post(payload)

    commit('setTargetValue', {
      key: 'newStudentsCount',
      value: data,
    })
  },
}
