import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerSendingAnalytics = new (class extends ApiBase {
  /**
   * Получаем аналитику по расслыке
   * @param {number} triggerSendingId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(triggerSendingId, parameters = {}) {
    return this._GET(
      `/trigger-sending/${triggerSendingId}/analytics?${this.getUrlParams(parameters)}`,
    )
  }
})()
