import ApiBase from 'ApiRest/ApiBase'

export const ApiAnalyticsOnlineLessonDetail = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params = {}) {
    const url = `/analytics/online-lesson/data/detail?${this.getUrlParams(
      params,
    )}`

    return this._GET(url)
  }
})()
