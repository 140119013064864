import { RESET_STATE } from '@/helpers/ResetState'

import createState from './state'

export default {
  /**
   * @param {Object} state
   */
  resetState(state) {
    RESET_STATE(state, createState)
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setRecording(state, payload) {
    state.recording = payload
  },

  /**
   * @param {Object} state
   * @param {Array} payload
   */
  setRecordings(state, payload) {
    state.recordings = payload
  },

  removeRecording(state, recordingId) {
    state.recordings = state.recordings.filter(
      (recording) => recording.id !== recordingId,
    )
  },

  addRecording(state, recording) {
    state.recordings = [
      ...state.recordings,
      recording,
    ]
  },

  changeRecordingName(state, payload) {
    state.recordings.forEach((recording) => {
      if (recording.id === payload.recordingId) {
        recording.name = payload.recordingName
      }
    })
  },
}
