import ApiOption from 'ApiRest/Api/Option'
import { ApiProductPromocodeGroup } from 'ApiRest/Api/ProductPromocodeGroup'
import { ApiProductPromocodeGroupList } from 'ApiRest/Api/ProductPromocodeGroup/List'
import { ApiProductPromocodeGroupProductOptions } from 'ApiRest/Api/ProductPromocodeGroup/ProductOptions'

export default {
  async fetchPromocodeGroups({ commit }, filters = {}) {
    const { data, headers } = await ApiProductPromocodeGroupList.get(filters)

    commit('setFilters', filters)
    commit('setPromocodeGroups', data)
    commit('setPagination', headers)
  },

  async fetchPromocodeGroup({ commit, state }) {
    const { data } = await ApiProductPromocodeGroup.get(state.promocodeGroupId)

    commit('setPromocodeGroup', data)
  },

  async createPromocodeGroup({ commit, state }) {
    const { data } = await ApiProductPromocodeGroup.post(state.promocodeGroup)

    commit('setPromocodeGroupId', data.id)
  },

  async updatePromocodeGroup({ commit, state }) {
    const { data } = await ApiProductPromocodeGroup.patch(
      state.promocodeGroupId,
      state.promocodeGroup,
    )

    commit('setPromocodeGroup', data)
  },

  removePromocodeGroup(context, id) {
    return ApiProductPromocodeGroup.delete(id)
  },

  async fetchOptionDiscountTypes({ commit }) {
    const { data } = await ApiOption.getDiscountTypes()

    commit('setOptions', {
      path: 'discountTypes',
      value: data,
    })
  },

  async fetchOptionProducts({ commit }) {
    const { data } = await ApiProductPromocodeGroupProductOptions.get()

    commit('setOptions', {
      path: 'saleProducts',
      value: data,
    })
  },

  async fetchOptionServices({ commit }) {
    const { data } = await ApiOption.getInvoiceServices()

    commit('setOptions', {
      path: 'saleServices',
      value: data,
    })
  },
}
