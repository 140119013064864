import ApiBase from 'ApiRest/ApiBase'

export default new (class FamilyEducationSetting extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/family-education-setting/${id}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  put(id, data) {
    return this._PUT(`/family-education-setting/${id}`, data)
  }
})()
