<template>
  <UiKitModal ref="modal" :hide-on-body-click="false" @hide="onHide">
    <template #header> {{ t('header') }} </template>

    <template #body>
      <div class="mb-2">
        <UiKitButton class="btn-primary square" label="+" @click="addIcon" />
      </div>
      <div class="mb-4">
        <IconItem
          v-for="(icon, iconIndex) in icons"
          :key="iconIndex"
          :item="icon"
          @change="($event) => onChange(iconIndex, $event)"
          @remove="() => onRemove(iconIndex)"
        />
      </div>
      <div class="d-flex justify-content-between">
        <UiKitButton
          class="btn-lg btn-success"
          :label="t('btn_add')"
          @click="onClickBtnAdd"
        />

        <UiKitButton
          class="btn-lg btn-primary"
          :label="t('btn_cancel')"
          @click="onClickBtnCancel"
        />
      </div>
    </template>
  </UiKitModal>
</template>

<script>
import IconItem from '@/components/productManagement/product/content/items/text/IconItem.vue'
import UiKitButton from '@/ui/UiKitButton.vue'
import UiKitModal from '@/ui/UiKitModal.vue'
import { cloneDeep } from 'lodash'

import { defineComponent } from 'vue'

const createModel = () => ({
  icons: [],
})

const createIcon = () => ({
  icon: null,
  text: '',
})

export default defineComponent({
  name: 'ModalAddIcons',

  components: {
    IconItem,
    UiKitModal,
    UiKitButton,
  },

  data() {
    return {
      model: createModel(),
    }
  },

  computed: {
    icons: {
      get() {
        return this.model.icons
      },

      set(value) {
        this.model.icons = value
      },
    },
  },

  methods: {
    addIcon() {
      const icon = createIcon()

      this.model.icons.push(icon)
    },

    onRemove(index) {
      this.model.icons.splice(index, 1)
    },

    onChange(index, icon) {
      this.$set(this.model.icons, index, icon)
    },

    onClickBtnAdd() {
      this.$emit('add', cloneDeep(this.model))
      this.hide()
    },

    onClickBtnCancel() {
      this.hide()
    },

    /**
     * @public
     */
    show() {
      this.$refs.modal.show()
    },

    hide() {
      this.resetData()
      this.$refs.modal.hide()
    },

    onHide() {
      this.resetData()
    },

    resetData() {
      this.model = createModel()
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(
        `components.product_management.content.text.modal_add_icons.${name}`,
      )
    },
  },
})
</script>
