export default () => ({
  wiki: [],
  theWiki: {
    id: null,
    status: null,
    name: '',
    parent: null,
    children: [],
    school: [],
    wikiContent: [],
  },
  option: {
    schools: [],
  },
})
