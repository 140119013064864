import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolProducts = new (class extends ApiBase {
  /**
   * @param {number} orderId
   * @returns {Promise}
   */
  get(orderId) {
    return this._GET(`/online-school/orders/${orderId}/products`)
  }
})()
