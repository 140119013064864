import ApiEnrollmentContractLastRequestToConfirmLog from 'ApiRest/Api/Enrollment/Contract/LastRequestToConfirmLog'
import ApiEnrollmentContractLogs from 'ApiRest/Api/Enrollment/Contract/Logs'

export default {
  async fetchLastRequestConfirmLog({ commit }, enrollmentContractId) {
    const { data } =
      await ApiEnrollmentContractLastRequestToConfirmLog.get(
        enrollmentContractId,
      )

    commit('setLastRequestConfirmLog', data)
  },

  async fetchLogs({ commit }, enrollmentContractId) {
    const { data } = await ApiEnrollmentContractLogs.get(enrollmentContractId)

    commit('setLogs', data)
  },
}
