import DownloadHelper from '@/helpers/DownloadHelper'
import { ApiTemplateDiagnosticsAcceptanceAct } from 'ApiRest/Api/School/Template/Diagnostics/AcceptanceAct'
import { ApiTemplateDiagnostics } from 'ApiRest/Api/School/Template/Diagnostics/Diagnostics'
import { ApiTemplateDiagnosticsOffer } from 'ApiRest/Api/School/Template/Diagnostics/Offer'
import { ApiTemplateDiagnosticsSample } from 'ApiRest/Api/School/Template/Diagnostics/Sample'

export default {
  async fetchTemplateOffer({ commit, state }) {
    const { data } = await ApiTemplateDiagnosticsOffer.get(state.schoolId)

    commit('setTemplate', {
      key: 'offer',
      payload: data,
    })
  },

  async fetchTemplateAcceptanceAct({ commit, state }) {
    const { data } = await ApiTemplateDiagnosticsAcceptanceAct.get(
      state.schoolId,
    )

    commit('setTemplate', {
      key: 'acceptanceAct',
      payload: data,
    })
  },

  async uploadTemplateOffer({ commit, state }, file) {
    const params = {
      documentContentB64: file,
      schoolId: state.schoolId,
    }

    const { data } = await ApiTemplateDiagnosticsOffer.post(
      state.schoolId,
      params,
    )

    commit('setTemplate', {
      key: 'offer',
      payload: data,
    })
  },

  async uploadTemplateAcceptanceAct({ commit, state }, payload) {
    const { data } = await ApiTemplateDiagnosticsAcceptanceAct.post(
      state.schoolId,
      payload,
    )

    commit('setTemplate', {
      key: 'acceptanceAct',
      payload: data,
    })
  },

  async downloadEmptyTemplate({ state }, documentId) {
    const response = await ApiTemplateDiagnostics.get(
      state.schoolId,
      documentId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async downloadFilledTemplate({ state }, documentId) {
    const response = await ApiTemplateDiagnosticsSample.get(
      state.schoolId,
      documentId,
    )

    DownloadHelper.downloadBlob(response)
  },

  async removeTemplate({ commit, state }, { key, documentId }) {
    await ApiTemplateDiagnostics.delete(state.schoolId, documentId)

    commit('unsetTemplate', key)
  },
}
