import ApiBase from 'ApiRest/ApiBase'

export const ApiProductsListShowcase = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/product/list?withLimit=0`

    return this._GET(url)
  }
})()
