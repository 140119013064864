import ApiBase from 'ApiRest/ApiBase'

export const ApiDashboardTypeList = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/widget/user-types`

    return this._GET(url)
  }
})()
