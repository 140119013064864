import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementNavigationBannerBlock =
  new (class extends ApiBase {
    /**
     * @returns {Promise}
     */
    get() {
      return this._GET(`/navigation-banner/block`)
    }

    /**
     * @param {Object} payload
     * @returns {Promise}
     */
    patch(payload) {
      return this._PATCH(`/navigation-banner/block`, payload)
    }
  })()
