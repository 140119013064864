import ApiBase from 'ApiRest/ApiBase'

export const ApiTrigger = new (class extends ApiBase {
  /**
   * @param {number} triggerId
   * @returns {Promise}
   */
  get(triggerId) {
    return this._GET(`/trigger/${triggerId}`)
  }
})()
