import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductList = new (class extends ApiBase {
  /**
   * @param {Object} params
   * @returns {Promise}
   */
  get(params) {
    const url = `/product/list?${this.getUrlParams(params)}`

    return this._GET(url)
  }
})()
