import { RESET_STATE } from '@/helpers/ResetState'
import Vue from 'vue'

import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setWiki(state, payload) {
    state.wiki = payload
  },

  setTheWiki(state, payload) {
    state.theWiki = payload
  },

  setOption(state, { key, value }) {
    Vue.set(state.option, key, value)
  },
}
