import ApiBase from 'ApiRest/ApiBase'

export const ApiStudentLearningAnalyticsMarksCount =
  new (class extends ApiBase {
    /**
     * @param {Object} params
     * @returns {Promise}
     */
    get(params = {}) {
      const url = `/student/learning-analytics/marks-count?${this.getUrlParams(
        params,
      )}`

      return this._GET(url)
    }
  })()
