import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @returns {Promise}
   */
  post(payload) {
    return this._POST(`/streams/transfer-students`, payload)
  }
})()
