import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementOrderStatusOptions =
  new (class extends ApiBase {
    /**
     * Список статусов заказа
     * @returns {Promise}
     */
    get() {
      return this._GET(`/courses/order/option/statuses`)
    }
  })()
