import ApiOption from 'ApiRest/Api/Option'
import { ApiTrigger } from 'ApiRest/Api/Trigger'
import { ApiTriggerSending } from 'ApiRest/Api/TriggerSending'
import { ApiTriggerSendingList } from 'ApiRest/Api/TriggerSending/List'
import { ApiTriggerSendingAvailableRoles } from 'ApiRest/Api/TriggerSending/Options/AvailableRoles'
import { ApiTriggerSendingChanels } from 'ApiRest/Api/TriggerSending/Options/Channels'
import { ApiTriggerSendingStatusOptions } from 'ApiRest/Api/TriggerSending/Options/Statuses'
import { ApiTriggerSendingTriggerOptions } from 'ApiRest/Api/TriggerSending/Options/Triggers'
import { ApiTriggerSendingSendManual } from 'ApiRest/Api/TriggerSending/SendManual'
import Constants from 'Constants'

export default {
  async fetchTriggerSendings({ commit }, filters = {}) {
    const { data, headers } = await ApiTriggerSendingList.get(filters)

    commit('setFilters', filters)
    commit('setTriggerSendings', data)
    commit('setPagination', headers)
  },

  async fetchTriggerSending({ commit }, triggerSendingId) {
    const { data } = await ApiTriggerSending.get(triggerSendingId)

    commit('setTriggerSending', data)
  },

  async createTriggerSending({ commit, state }) {
    const { data } = await ApiTriggerSending.post(state.triggerSending)

    commit('setTriggerSending', data)
  },

  async updateTriggerSending({ commit, state }) {
    const { data } = await ApiTriggerSending.put(
      state.triggerSending.id,
      state.triggerSending,
    )

    commit('setTriggerSending', data)
  },

  async sendTriggerSendingManual({ state }, users) {
    const payload = {
      triggerSending: {
        id: state.triggerSending.id,
        name: state.triggerSending.name,
      },

      users,
    }

    await ApiTriggerSendingSendManual.post(payload)
  },

  async fetchChannels({ commit }) {
    const { data } = await ApiTriggerSendingChanels.get()

    commit('setOptionsList', {
      path: 'channels',
      value: data,
    })
  },

  async fetchTriggers({ commit }, filters) {
    const { data } = await ApiTriggerSendingTriggerOptions.get(filters)

    commit('setOptionsList', {
      path: 'triggers',
      value: data,
    })
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schools',
      value: data,
    })
  },

  async fetchRecipientRoles({ commit }, filters) {
    const { data } = await ApiTriggerSendingAvailableRoles.get(filters)

    commit('setOptionsList', {
      path: 'recipientRoles',
      value: data,
    })
  },

  async fetchTriggerStatuses({ commit }) {
    const { data } = await ApiTriggerSendingStatusOptions.get()

    commit('setOptionsList', {
      path: 'triggerStatuses',
      value: data,
    })

    const statusInactive = data.find(
      (status) => status.id === Constants.triggerSendingStatuses.INACTIVE,
    )

    commit('setTriggerSendingValue', {
      path: 'status',
      value: statusInactive,
    })
  },

  async fetchTrigger({ commit, state }) {
    const { data } = await ApiTrigger.get(state.triggerSending.trigger.id)

    commit('setTriggerDetail', data)
  },
}
