import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonOption = new (class extends ApiBase {
  /**
   * Предметы
   * @param {Object} params
   * @returns {Promise}
   */
  getSubjects(params = {}) {
    return this._GET(
      `/online-lessons/option/subjects?${UrlHelper.param(params)}`,
    )
  }

  /**
   * Школы
   * @returns {Promise}
   */
  getSchools() {
    return this._GET(`/online-lessons/option/schools`)
  }

  /**
   * Потоки
   * @param {Object} params
   * @returns {Promise}
   */
  getStreams(params = {}) {
    return this._GET(
      `/online-lessons/option/streams?${UrlHelper.param(params)}`,
    )
  }

  /**
   * Группы
   * @param {Object} params
   * @returns {Promise}
   */
  getGroups(params = {}) {
    return this._GET(`/online-lessons/option/groups?${UrlHelper.param(params)}`)
  }

  /**
   * Классы
   * @param {Object} params
   * @returns {Promise}
   */
  getGrades(params = {}) {
    return this._GET(`/online-lessons/option/grades?${UrlHelper.param(params)}`)
  }

  /**
   * Сотрудники
   * @param {Object} params
   * @returns {Promise}
   */
  getManagers(params) {
    return this._GET(
      `/online-lessons/option/managers?${UrlHelper.param(params)}`,
    )
  }

  /**
   * Типы обучения
   * @param {Object} params
   * @returns {Promise}
   */
  getEducationTypes(params = {}) {
    return this._GET(
      `/online-lessons/option/education-types?${UrlHelper.param(params)}`,
    )
  }

  /**
   * Типы повторяемости
   * @returns {Promise}
   */
  getOnlineLessonRepeatTypes() {
    return this._GET(`/online-lessons/option/lesson-repeat-types`)
  }

  /**
   * Ученики
   * @param {Object} params
   * @returns {Promise}
   */
  getStudents(params = {}) {
    return this._GET(
      `/online-lessons/option/students?${UrlHelper.param(params)}`,
    )
  }

  /**
   * Все онлайн уроки входящие в группу
   * @param {number} lessonId
   * @returns {Promise}
   */
  getGroupOnlineLessons(lessonId) {
    return this._GET(`/online-lesson/${lessonId}/group`)
  }
})()
