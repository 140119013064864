import ApiBase from 'ApiRest/ApiBase'

export const ApiApplicationMassForceEnd = new (class extends ApiBase {
  /**
   * @param {Record} data
   * @returns {Promise<?>}
   */
  patch(data) {
    const url = `/diagnostics/applications/completed-forcibly`

    return this._PATCH(url, data)
  }
})()
