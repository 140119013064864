import ApiService from 'ApiRest/Api/Service'
import ApiServiceList from 'ApiRest/Api/Service/List'

export default {
  resetState({ commit }) {
    commit('resetState')
  },

  async fetchPaymentServices({ commit }, params) {
    const response = await ApiServiceList.get(params)

    commit('setPaymentServices', response.data)

    return response
  },

  async fetchTheService({ commit }, serviceId) {
    const { data } = await ApiService.get(serviceId)

    commit('setTheService', data)
  },
}
