import ApiBase from 'ApiRest/ApiBase'

export const ApiDiagnosticsExtramural = new (class extends ApiBase {
  /**
   * @param {number} schoolId
   * @param {number} gradeId
   * @returns {Promise}
   */
  get(schoolId, gradeId) {
    const url = `/diagnostics/configuration/${schoolId}/tests/${gradeId}`

    return this._GET(url)
  }

  /**
   * @param {number} schoolId
   * @param {number} gradeId
   * @param {Object} data
   * @returns {Promise}
   */
  post(schoolId, gradeId, data) {
    const url = `/diagnostics/configuration/${schoolId}/tests/${gradeId}`

    return this._POST(url, data)
  }
})()
