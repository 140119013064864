import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiDiagnosticsCtpTestList = new (class extends ApiBase {
  /**
   * Список ктп тестов входящей диагностики
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters = {}) {
    const url = `/ctps/ctp-tests/list?${UrlHelper.param(parameters)}`

    return this._GET(url)
  }
})()
