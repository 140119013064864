import { ApiEducontLog } from 'ApiRest/Api/Educont/Log'

export default {
  /**
   * @param {Object} args
   * @param {Function} args.commit
   */
  resetState({ commit }) {
    commit('resetState')
  },

  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @param {Object} filters
   * @returns {Promise}
   */
  async fetchLog({ commit }, filters = {}) {
    const { data, headers } = await ApiEducontLog.get(filters)

    commit('setLog', data)
    commit('setLogPagination', headers)
  },
}
