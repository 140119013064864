import ApiBase from 'ApiRest/ApiBase'

export const ApiProductCustomerRelationshipTypes = new (class extends ApiBase {
  /**
   * Список типов заключения отношений с клиентом
   * @returns {Promise}
   */
  get() {
    return this._GET(`/product/options/customer-relationship-types`)
  }
})()
