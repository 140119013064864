const colors = {
  // bootstrap vars

  'app-gray-100': '#2f383f',
  'app-gray-300': '#a6a6a6',
  'app-gray-500': '#f6f8fa',
  'app-gray-600': '#d5d7d9',
  'app-gray-700': '#dfe7ec',
  'app-gray-800': '#ecf1f4',
  'app-gray-900': '#ffffff',
  'app-gray-900-darken': '#ffffff',

  'app-pink': '#e71b7d',
  'app-black': '#ecf1f4',
  'app-white': '#2f383f',
  'app-blue': '#0077d7',
  'app-red': '#f6522e',
  'app-green': '#4fad2d',
  'app-bright-red': '#ff1f1f',

  'app-table-wrap-background-color': '#fff',
  'app-table-wrap-box-shadow': '#e6e6e6',
  'app-table-bg': '#f6f8fa',
  'app-table-border-bottom-color': '#c1c6cc',
  'app-table-captionBg': '#ffffff',
  'app-table-hover-bg': '#0077d7',
  'app-table-hover-color': '#ffffff',
  'app-table-caption-hover-color': '#ffffff',
  'app-table-commitPen-color': '#717579',
  'app-table-commitCheck-color': '#3d444b',
  'app-modal-content-color': '#2f383f',

  'app-modal-content-box-shadow-color': '#e6e6e6',

  // custom vars

  'arrowDown-color': '#8a9299',
  'hr-bright': '#8a9299',
  'input-placeholder-color': '#83929c',
  'input-readonly-color': '#2f383f',

  'question-content-input-group-text-color': '#2f383f',

  'question-content-breakWordsBySyllable-background': '#ecf1f4',
  'question-content-breakWordsBySyllable-active': '#004a99',

  'question-content-chooseWord-background': '#ecf1f4',
  'question-content-chooseWord-active': '#d5d7d9',

  'question-content-chooseLetter-background': '#ecf1f4',
  'question-content-chooseLetter-active': '#d5d7d9',

  'question-content-insertLetterFromList-background': '#ecf1f4',
  'question-content-insertLetterFromList-active': '#d5d7d9',

  'question-content-insertWordFromList-background': '#ecf1f4',
  'question-content-insertWordFromList-active': '#d5d7d9',

  'question-content-sentencePartsParsing-background': '#ecf1f4',
  'question-content-sentencePartsParsing-selectionColor': '#2f383f',

  'question-content-morphemicParsing-background': '#ecf1f4',

  'question-content-morphemicParsing-sign-ending-color': '#2f383f',
  'question-content-morphemicParsing-sign-wordstem-color': '#2f383f',

  'question-content-dragAndDrop-block-text-color': '#2f383f',

  'question-content-dragAndDropColumns-background': '#d6d8da',
  'question-content-dragAndDropColumns-boxShadow-color': '#e6e6e6',
  'question-content-dragAndDropColumns-columnBorderColor': '#3a4148',

  'question-content-dragAndDropSequence-background': '#d5d7d9',
  'question-content-dragAndDropSequence-boxShadow-color': '#e6e6e6',

  'question-content-insertFromList-background': '#ecf1f4',
  'question-content-insertFromList-active': '#d5d7d9',

  'uiKit-checkbox-colorNotChecked': '#8d939e',

  'uiKit-navigation-background': '#ffffff',
  'uiKit-navigation-titleColor': '#8e8e9f',
  'uiKit-navigation-headerColorInactive': '#8a9299',
  'uiKit-navigation-labelColorActive': '#2f383f',
  'uiKit-navigation-iconColorInactive': '#8a9299',
  'uiKit-navigation-box-shadow': '#e6e6e6',

  'uiKit-dropWindow-bodyBoxShadow-color': '#e6e6e6',
  'uiKit-dropWindow-titleColor': '#2f383f',

  'uiKit-menu-bodyBoxShadow-color': '#e6e6e6',
  'uiKit-menu-color': '#2f383f',
  'uiKit-menu-bodyBackground': '#dfe7ec',

  'uiKit-formField-labelColor': '#83929c',

  'uiKit-upload-big-image-backgroundActive': '#e5e5e5',
  'uiKit-upload-big-toolbar-background': '#e5e5e5',

  'uiKit-upload-small-image-background': '#e5e5e5',
  'uiKit-upload-small-image-backgroundActive': '#d5d7d9',
  'uiKit-upload-small-image-color': '#2f383f',

  'uiKit-upload-small-audio-background': '#ecf1f4',
  'uiKit-upload-small-audio-backgroundActive': '#d5d7d9',
  'uiKit-upload-small-audio-color': '#2f383f',

  'uiKit-dropzone-background': '#ecf1f4',
  'uiKit-dropzone-borderColor': 'rgba(255, 255, 255, .05)',

  'uiKit-tabs-titlesBorderColor': '#dfe7ec',
  'uiKit-tabs-activeBorderColor': '#0077d7',
  'uiKit-tabs-activeColor': '#2f383f',
  'uiKit-tabs-inactiveColor': '#83929c',

  'uiKit-contentGroup-boxShadow-color': '#e6e6e6',
  'uiKit-contentGroup-boxShadow-inset-color': '#e6e6e6',
  'uiKit-contentGroup-boxShadowHover-color': '#e6e6e6',

  // plugins

  'multiselectBackground-color': '#dfe7ec',
  'multiselectMenuShadow-color': '#e6e6e6',
  'multiselect-menu-placeholder-color': '#83929C',
  'multiselect-arrow-color': '#8a9299',

  'draggable-tree-cellBackground': '#ecf1f4',
  'draggable-tree-cellHover': '#ecf1f4',
  'draggable-tree-cellColor': '#ecf1f4',
  'draggable-inputIcon-color': '#8a9299',
  'draggable-tree-placeHolderBorder': '#ecf1f4',
  'draggable-tree-placeHolderBackground': 'rgba(0, 136, 249, .09)',
  'draggable-tree-placeHolderColor': '#83929c',
  'draggable-tree-placeHolderInnerBackground': '#ddd',
  'draggable-tree-placeHolderInner2Background': '#ccc',

  'datepickerCalendarBg-color': '#dfe7ec',
  'datepickerCalendarInputBackground-color': '#dfe7ec',
  'datepickerCalendarInputIcons-color': '#8a9299',
  'datepickerCalendarButtonBg-color': '#8a9299',
  'datepickerCalendarSelected-color': '#8a9299',
  'datepickerCalendarHeaderButtonBg-color': '#717579',

  'wysiwyg-backgroundColor': '#ecf1f4',
  'wysiwyg-color': '#2f383f',

  'tree-select-valueBackground': '#41b883',
  'tree-select-valueRemoveColor': '#266d4d',

  'toasted-error-border-color': '#fff',
  'toasted-error-color': '#fff',

  // components

  'components-pagination-linkColor': '#83929c',
  'components-pagination-borderColor': '#dee2e6',
  'components-pagination-hover-color': '#0077d7',
  'components-pagination-active-linkColor': '#ffffff',

  'components-compositeBootstrapTable-hoverColor': '#3b91d8',

  'components-compositeTable-chosenBackground': '#3b91d8',

  'box-title-color': '#f5f5f5',
  'box-element-white-text': '#ffffff',
  'box-element-black-text': '#282e33',

  'compositeFilters-background-color': '#ffffff',
  'compositeFilters-box-shadow-color': '#e6e6e6',
  'compositeFilters-titleColor': '#2f383f',
  'compositeFilters-labelColor': '#83929c',
  'compositeSearch-placeholder-color': '#83929c',
  'compositeSearch-iconSearch-color': '#0077d7',

  'cellTooltip-labelColor': '#83929c',
  'cellTooltip-tooltipColor': '#2f383f',
  'cellTooltip-countBoxShadowColor': '#e6e6e6',
  'cellTooltip-tooltipBackground-color': '#d5d7d9',

  'warning-text': '#665000',
  'warning-background': '#fff4cc',
  'warning-border': '#ffeeb3',

  // ui

  'ui-uiKitMenu-selectedBorder': '#5f6075',

  'ui-uiKitNavigation-notifyColor': '#fff',

  // page

  'page-certificationStatement-border': '#8d939e',

  'page-ctpExtramuralDetail-topicDateColor': '#fff',
  'page-ctpExtramuralDetail-topicDateErrorBackground': '#ac1d04',

  'page-onlineLessonIndex-inputBackground': '#ecf1f4',

  'page-onlineLessonDetail-ldsEllipsis': '#fff',

  'page-roleIndex-ldsEllipsis': '#fff',

  'page-roleDetail-ldsEllipsis': '#fff',

  'page-userDetail-treeSelectBackground': '#f2f0eb',
  'page-userDetail-treeSelectArrow-color': '#7b7b7b',
  'page-userDetail-ldsEllipsis': '#fff',

  'page-userIndex-ldsEllipsis': '#fff',

  'page-statementDetail-tableDisabledBackground': '#fff',
  'page-statementDetail-tableTrHoverLinkColor': '#adff2f',
  'page-statementDetail-tableTrLinkColor': '#2f383f',
  'page-statementDetail-tableFirstTdBackground': '#ecf1f4',
  'page-statementDetail-tableTrHoverBackground': '#3b91d8',
  'page-statementDetail-tableTrHoverPeriodBackground': '#3387cc',
  'page-statementDetail-tableTrHoverYearBackground': '#2874b3',
  'page-statementDetail-tableTdBorder': '#5f6075',
  'page-statementDetail-attemptBorder': '#7b7b7b',

  'page-groupDistribution-cellBackground': '#ecf1f4',
  'page-groupDistribution-borderColor': '#ecf1f4',
  'page-groupDistribution-color': '#ecf1f4',
  'page-groupDistribution-captionColor': '#2f383f',
  'page-groupDistribution-labelColor': '#83929c',
  'page-groupDistribution-nameColor': '#2f383f',
  'page-groupDistribution-searchColor': '#8e949f',
  'page-groupDistribution-searchPlaceholderColor': '#565656',

  // ag-grid

  'ag-grid-border-color': '#bdc3c7',

  // full-time-statement

  'fts-table-th-field-active-color': '#000',
  'fts-table-th-select-option-bg-color': '#f5f7f7',
  'fts-btn-color': 'rgba(0, 0, 0, 0.54)',
  'fts-btn-bg-color': '#f5f7f7',
  'fts-info-bg-color': '#f5f7f7',
  'fts-info-table-caption-color': '#332f3e',
  'fts-sidebar-wrapper-bg-color': '#f5f7f7',

  'is-test': '#dbe0e7',
  'has-price-error': '#d44e57',

  // learning-analytics
  'calendar-light-blue': '#3182ce',
}

export default colors
