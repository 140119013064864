export default () => ({
  block: {
    title: {
      id: null,
      name: '',
    },

    banners: [],
  },

  options: {
    formats: [],
  },
})
