import { ApiStudentCredit } from 'ApiRest/Api/User/Student/Credit'

export default {
  async sendCredit({ state, commit }) {
    const payload = {
      fileId: state.file.id,
    }

    const { data } = await ApiStudentCredit.post(payload)

    commit('setRows', data)
  },
}
