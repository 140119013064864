import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * @param {number} userId
   * @returns {Promise}
   */
  get(userId) {
    return this._GET(`/keycloak/user/${userId}`)
  }

  /**
   * @param {number} userId
   * @returns {Promise}
   */
  post(userId) {
    return this._POST(`/keycloak/user/${userId}`)
  }

  /**
   * @param {number} userId
   * @returns {Promise}
   */
  patch(userId) {
    return this._PATCH(`/keycloak/user/${userId}`)
  }
})()
