const routes = [
  {
    path: '/certifications',
    name: 'admin-certifications',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () => import('@/pages/certification/Certifications.vue'),
  },
  {
    path: '/certification/create',
    name: 'admin-the-certification-create',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () => import('@/pages/certification/TheCertification.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/certification/:certificationId(\\d+)',
    name: 'admin-the-certification',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () => import('@/pages/certification/TheCertification.vue'),
  },
  {
    path: '/certification/statement/:statementId(\\d+)',
    name: 'admin-the-certification-statement',
    meta: {
      accessControlGroup: 'certification',
    },
    component: () =>
      import('@/pages/familyCertification/TheCertificationStatement.vue'),
  },
]

export default routes
