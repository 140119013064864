import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export default new (class extends ApiBase {
  /**
   * Скачивание Аттестационной справки
   * @param {number} statementId
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(statementId, parameters = {}) {
    const url = `/family-statement/${statementId}/download-cert?${UrlHelper.param(
      parameters,
    )}`

    const config = {
      responseType: 'blob',
    }

    return this._GET(url, config)
  }
})()
