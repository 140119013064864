import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductCategory = new (class extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/product/category/${id}`)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST('/product/category/', data)
  }

  /**
   * @param {Object} data
   * @param {number} id
   * @returns {Promise}
   */
  patch(data, id) {
    return this._PATCH(`/product/category/${id}`, data)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  delete(id) {
    return this._DELETE(`/product/category/${id}`)
  }
})()
