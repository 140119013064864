const routes = [
  {
    path: '/news',
    name: 'admin-news',
    meta: {
      accessControlGroup: 'news',
    },
    component: () => import('@/pages/news/News.vue'),
  },
  {
    path: '/news/create',
    name: 'admin-news-article-create',
    meta: {
      accessControlGroup: 'news',
    },
    component: () => import('@/pages/news/NewsArticle.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/news/:newsId(\\d+)',
    name: 'admin-news-article',
    meta: {
      accessControlGroup: 'news',
    },
    component: () => import('@/pages/news/NewsArticle.vue'),
  },
]

export default routes
