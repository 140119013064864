import ApiBase from 'ApiRest/ApiBase'
import { toString } from 'lodash'

export const ApiTestingCheckCommentHelper = new (class extends ApiBase {
  /**
   * @param {string|null} searchText
   * @returns {Promise}
   */
  get(searchText) {
    return this._GET(
      `/testing/check/comment-helper?query=${encodeURI(toString(searchText))}`,
    )
  }

  /**
   * @param {number} commentSuggestionId
   * @returns {Promise}
   */
  delete(commentSuggestionId) {
    return this._DELETE(`/testing/check/comment-helper/${commentSuggestionId}`)
  }
})()
