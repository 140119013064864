import ApiBase from 'ApiRest/ApiBase'

export default new (class Paid extends ApiBase {
  /**
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/price/extramural-education-paid-price/${id}`)
  }

  /**
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/price/extramural-education-paid-price/${id}`, data)
  }

  /**
   * @param {Object} data
   * @returns {Promise}
   */
  post(data) {
    return this._POST(`/price/extramural-education-paid-price`, data)
  }
})()
