import { ApiProductGroupList } from 'ApiRest/Api/Products/Group/List'
import { ApiProductGroup } from 'ApiRest/Api/Products/ProductGroup'
import { omit } from 'lodash'

export default {
  async fetchGroups({ commit }, filters) {
    const { data, headers } = await ApiProductGroupList.get(filters)

    commit('setGroups', data)
    commit('setPagination', headers)
  },

  async fetchGroup({ commit }, groupId) {
    const { data } = await ApiProductGroup.get(groupId)

    commit('setGroup', data)
  },

  async createGroup({ state }) {
    const payload = state.group

    const { data } = await ApiProductGroup.post(payload)

    return data
  },

  async saveGroup({ state, commit }) {
    const payload = omit(state.group, 'vacantSeats')
    const groupId = state.group.id

    const { data } = await ApiProductGroup.put(groupId, payload)

    commit('setGroup', data)
  },
}
