import actions from './actions'
import assignment from './assignment/store'
import getters from './getters'
import mutations from './mutations'
import recordings from './recordings/store'
import state from './state'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,

  modules: {
    recordings,
    assignment,
  },
}
