import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineSchoolOrders = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  get(parameters) {
    return this._GET(`/online-school/orders?${this.getUrlParams(parameters)}`)
  }
})()
