import admin from '@/store/store'
import Vuex from 'vuex'

export const StoreAdmin = new (class {
  constructor() {
    this.store = null
  }

  init() {
    this.store = new Vuex.Store({
      strict: process.iblsEnv.IS_DEVELOPMENT,

      modules: {
        admin,
      },
    })

    return this.store
  }
})()
