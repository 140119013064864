import ApiBase from 'ApiRest/ApiBase'

export const ApiCourseManagementProductOptionsOnlineLessons =
  new (class extends ApiBase {
    /**
     * Список онлайн уроков продукта
     * @param {number} productId
     * @returns {Promise}
     */
    get(productId) {
      return this._GET(`/courses/product/${productId}/options/online-lessons`)
    }
  })()
