import { ApiCourseManagementParameter } from 'ApiRest/Api/CourseManagement/Products/Parameters/Parameter'

export default {
  async fetchParameter({ commit, state }) {
    const { data } = await ApiCourseManagementParameter.get(state.parameterId)

    commit('setParameter', data)
  },

  async createParameter({ commit, state }) {
    const { data } = await ApiCourseManagementParameter.post(state.parameter)

    commit('setParameter', data)
    commit('setParameterId', data.id)
  },

  async updateParameter({ commit, state }) {
    const { data } = await ApiCourseManagementParameter.patch(
      state.parameter.id,
      state.parameter,
    )

    commit('setParameter', data)
  },

  removeParameter({ state }) {
    return ApiCourseManagementParameter.delete(state.parameterId)
  },
}
