import ApiBase from 'ApiRest/ApiBase'

export const ApiProductsListCourses = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    const url = `/courses/product/list?withLimit=0`

    return this._GET(url)
  }
})()
