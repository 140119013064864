<template>
  <modal
    :class="$style.modal"
    v-bind="$attrs"
    :transition="transition"
    :shift-y="shiftY"
    :height="height"
    v-on="$listeners"
  >
    <slot></slot>
  </modal>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UiKitModal2',

  inheritAttrs: false,

  props: {
    height: {
      type: [
        String,
        Number,
      ],

      default: 'auto',
    },

    transition: {
      type: String,
      default: '',
    },

    shiftY: {
      type: Number,
      default: 0.3,
    },
  },
})
</script>

<style module lang="scss">
.modal {
  [class='vm--modal'] {
    overflow: visible;
    color: var(--app-modal-content-color);
    background-color: var(--app-black) !important;
    box-shadow:
      0 1px 1px rgb(0 0 0 / 20%),
      0 2px 2px rgb(0 0 0 / 20%),
      0 4px 4px rgb(0 0 0 / 20%),
      0 8px 8px rgb(0 0 0 / 20%),
      0 16px 16px rgb(0 0 0 / 20%);
  }
}
</style>
