export default () => ({
  advertisement: {
    name: '',
    id: null,
    link: '',
    type: null,
    schools: [],
    grades: [],
    educationTypes: [],
    typeInfo: null,
    isActive: false,
  },

  options: {
    schools: [],
    grades: [],
    educationTypes: [],
    products: [],

    // TODO: i18n
    types: [
      {
        id: 1,
        name: 'Баннер',
      },
      {
        id: 2,
        name: 'Модальное окно',
      },
    ],

    // TODO: i18n
    statuses: [
      {
        id: 1,
        name: 'Активно',
        value: true,
      },
      {
        id: 2,
        name: 'Неактивно',
        value: false,
      },
    ],

    // TODO: i18n
    sections: [
      {
        id: 1,
        name: 'Академия',
        value: true,
      },
      {
        id: 2,
        name: 'Мои курсы',
        value: false,
      },
    ],
  },

  advertisements: [],
  pagination: null,
  filters: null,
})
