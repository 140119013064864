import { RESET_STATE } from '@/helpers/ResetState'
import createState from '@/store/onlineSchool/order/state'
import { set } from 'lodash'
import Vue from 'vue'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setOrder(state, payload) {
    state.order = payload
  },

  setInvoices(state, payload) {
    state.invoices = payload
  },

  setLogs(state, payload) {
    state.logs = payload
  },

  setOrderId(state, payload) {
    state.orderId = payload
  },

  setOrderValue(state, { path, value }) {
    set(state.order, path, value)
  },

  setOptionsList(state, { path, value }) {
    set(state.options, path, value)
  },

  updateClientDocuments(state, file) {
    state.order.clientDocuments = state.order.clientDocuments.filter(
      (c) => c.id !== file.id,
    )
  },

  setOptionsItems(state, { id, key, value }) {
    const index = state.order.items.findIndex((item) => item.id === id)

    Vue.set(state.order.items[index], key, value)
  },

  setProducts(state, payload) {
    state.products = payload
  },

  setProductsValue(state, value) {
    state.products.push(value)
  },

  removeProduct(state, index) {
    state.products.splice(index, 1)
  },

  hideGenerateInvoiceButton(state) {
    state.order.viewGenerateInvoiceButton = false
  },
}
