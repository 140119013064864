export default () => ({
  option: {
    schools: [],
    developmentPeriods: [],
    grades: [],
  },

  subsidyPrices: [],
  theSubsidyPrice: {},

  paidPrices: [],
  thePaidPrice: {},

  individualSubsidyPrices: [],
  theIndividualSubsidyPrice: {},

  individualPaidPrices: [],
  theIndividualPaidPrice: {},
})
