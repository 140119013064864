export default {
  canAssignIntramuralDiagnostics(state) {
    return state.request?.canAssignIntramuralDiagnostics
  },

  canAssignExtramuralDiagnostics(state) {
    return state.request?.canAssignExtramuralDiagnostics
  },

  settingsFullTimeDiagnostics(state) {
    return state.fullTimeDiagnostics.subjects ?? []
  },

  settingsExtramuralDiagnostics(state) {
    return state.extramuralDiagnostics.subjects ?? []
  },

  extramuralDiagnosticsFiltered(state, getters) {
    return getters.settingsExtramuralDiagnostics
      .filter((setting) => setting.isChecked)
      .map(({ extramuralTest }) => ({
        id: extramuralTest.id,
      }))
  },

  fullTimeDiagnosticsFiltered(state, getters) {
    return getters.settingsFullTimeDiagnostics
      .filter((setting) => setting.isChecked)
      .map(({ subject }) => ({
        id: subject.id,
      }))
  },

  isEmptyExtramuralDiagnosticsFiltered(state, getters) {
    return getters.extramuralDiagnosticsFiltered.length === 0
  },

  isSelectedExtramuralDiagnostics(state, getters) {
    return (
      getters.extramuralDiagnosticsFiltered.length > 0 &&
      state.extramuralDiagnostics.service !== null &&
      state.testAvailabilityTime !== ''
    )
  },

  isSelectedFullTimeDiagnostics(state, getters) {
    return (
      getters.fullTimeDiagnosticsFiltered.length > 0 &&
      state.fullTimeDiagnostics.service !== null
    )
  },

  incomeDiagnosticsSettings(state, getters) {
    const incomeDiagnostics = {}

    if (getters.isSelectedFullTimeDiagnostics) {
      incomeDiagnostics.intramuralDiagnostics = {
        subjects: getters.fullTimeDiagnosticsFiltered,
        service: state.fullTimeDiagnostics.service,
      }
    }

    if (getters.isSelectedExtramuralDiagnostics) {
      incomeDiagnostics.extramuralDiagnostics = {
        tests: getters.extramuralDiagnosticsFiltered,
        service: state.extramuralDiagnostics.service,
        testAvailabilityTime: state.testAvailabilityTime,
      }
    }

    return incomeDiagnostics
  },
}
