const routes = [
  {
    path: '/enrollment-requests',
    name: 'admin-enrollment-requests',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () => import('@/pages/request/Requests.vue'),
  },

  {
    path: '/enrollment-request/detail/:enrollmentRequestId(\\d+)',
    name: 'admin-the-enrollment-request',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () => import('@/pages/request/TheRequest.vue'),
  },

  {
    path: '/enrollment-requests/trash',
    name: 'admin-enrollment-requests-trash',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () => import('@/pages/request/RequestsTrash.vue'),
  },

  {
    path: '/enrollment-requests/draft',
    name: 'admin-enrollment-requests-draft',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () => import('@/pages/request/RequestsDraft.vue'),
  },

  {
    path: '/enrollment-request-draft/detail/:requestDraftId(\\d+)',
    name: 'admin-the-enrollment-request-draft',
    meta: {
      accessControlGroup: 'enrollment-request',
    },
    component: () => import('@/pages/request/TheRequestDraft.vue'),
  },
]

export default routes
