export default {
  channelsFiltered(state) {
    const templatesFiltered = state.triggerSending.templates.filter(
      ({ channel }) => channel,
    )

    if (templatesFiltered.length === 0) {
      return state.options.channels
    }

    return state.options.channels.filter(
      ({ id }) => !templatesFiltered.some(({ channel }) => channel.id === id),
    )
  },
}
