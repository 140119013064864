import ApiBase from 'ApiRest/ApiBase'

export const ApiSending = new (class extends ApiBase {
  /**
   * @param {number} sendingId
   * @returns {Promise}
   */
  get(sendingId) {
    return this._GET(`/sending/${sendingId}`)
  }

  /**
   * @param {Object} body
   * @returns {Promise}
   */
  post(body) {
    return this._POST('/sending/', body)
  }

  /**
   * @param {number} sendingId
   * @param {Object} body
   * @returns {Promise}
   */
  put(sendingId, body) {
    return this._PUT(`/sending/${sendingId}`, body)
  }
})()
