import ApiOption from 'ApiRest/Api/Option'
import { ApiSupportDepartment } from 'ApiRest/Api/Support/Department/Department'
import { ApiSupportDepartments } from 'ApiRest/Api/Support/Department/List'
import { ApiSupportQuestion } from 'ApiRest/Api/Support/Question'
import { ApiSupportQuestionComment } from 'ApiRest/Api/Support/Question/Comment'
import { ApiSupportQuestionInWorkCount } from 'ApiRest/Api/Support/Question/InWorkCount'
import { ApiSupportQuestionList } from 'ApiRest/Api/Support/Question/List'
import { ApiQuestionLogs } from 'ApiRest/Api/Support/Question/Log'
import { ApiSupportOptionList } from 'ApiRest/Api/Support/Question/Option/List'

export default {
  async fetchQuestions({ commit }, params) {
    const expandParams = {
      expand:
        'school,educationType,grade,stream,content,testQuestion,type,department,author',
    }

    const response = await ApiSupportQuestionList.get({
      ...expandParams,
      ...params,
    })

    commit('setQuestions', response.data)

    return response
  },

  async fetchTheQuestion({ commit, getters }) {
    const params = {
      expand:
        'school,grade,stream,content,testQuestion,type,department,departmentUser,onlineLesson',
    }

    const { data } = await ApiSupportQuestion.get(getters.questionId, params)

    commit('setTheQuestion', data)
    commit('setTheQuestionInitialStatus', data.status)
  },

  async updateTheQuestion({ getters }, payload) {
    await ApiSupportQuestion.patch(getters.questionId, payload)
  },

  async createQuestion({ commit }, payload) {
    const { data } = await ApiSupportQuestion.post(payload)

    commit('setTheNewQuestion', data)
  },

  async addNewComment({ getters }, payload) {
    await ApiSupportQuestionComment.post(getters.questionId, payload)
  },

  async updateComment({ getters }, { commentId, payload }) {
    await ApiSupportQuestionComment.patch(
      getters.questionId,
      commentId,
      payload,
    )
  },

  async fetchStatuses({ commit }) {
    const { data } = await ApiSupportOptionList.getStatuses()

    commit('setOption', {
      path: 'statuses',
      value: data,
    })
  },

  async fetchDepartments({ commit }, schoolId) {
    const parameters = {
      school: {
        id: schoolId,
      },
    }

    const { data } = await ApiSupportDepartments.get(parameters)

    commit('setOption', {
      path: 'departments',
      value: data,
    })
  },

  async fetchSchools({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOption', {
      path: 'schools',
      value: data,
    })
  },

  async fetchLogs({ commit, getters }) {
    const { data } = await ApiQuestionLogs.get(getters.questionId)

    commit('setLogs', data)
  },

  async fetchQuestionsInWorkCount({ commit }) {
    const { data } = await ApiSupportQuestionInWorkCount.get()

    commit('setQuestionsInWorkCount', data)
  },

  async fetchDepartmentMembers({ commit }, { schoolId, departmentId }) {
    const params = {
      school: {
        id: schoolId,
      },
      expand: 'members',
    }

    const { data } = await ApiSupportDepartment.get(departmentId, params)

    commit('setOption', {
      path: 'departmentUsers',
      value: data.members,
    })
  },

  async fetchPriorities({ commit }) {
    const { data } = await ApiSupportOptionList.getPriorities()

    commit('setOption', {
      path: 'priorities',
      value: data,
    })
  },
}
