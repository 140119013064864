import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementProductLeaders = new (class extends ApiBase {
  /**
   * Список ведущих курсов
   * @returns {Promise}
   */
  get() {
    return this._GET(`/product/options/leaders`)
  }
})()
