import { ApiStudentLearningAnalyticsAverageMarks } from 'ApiRest/Api/Student/LearningAnalytics/AverageMarks'
import { ApiStudentLearningAnalyticsCtpList } from 'ApiRest/Api/Student/LearningAnalytics/CtpList'
import { ApiStudentLearningAnalyticsDiscipline } from 'ApiRest/Api/Student/LearningAnalytics/Discipline'
import { ApiStudentLearningAnalyticsMarksCount } from 'ApiRest/Api/Student/LearningAnalytics/MarksCount'
import { ApiStudentLearningAnalyticsScienceAverageMarks } from 'ApiRest/Api/Student/LearningAnalytics/ScienceAverageMarks'
import { ApiStudentLearningAnalyticsAttempts } from 'ApiRest/Api/Student/LearningAnalytics/TestingAttempts'
import { ApiStudentLearningAnalyticsTestingDynamics } from 'ApiRest/Api/Student/LearningAnalytics/TestingDynamics'
import { ApiStudentLearningAnalyticsVisitingAggregated } from 'ApiRest/Api/Student/LearningAnalytics/VisitingAggregated'

export default {
  async fetchCtpList({ commit }, params) {
    const { data } = await ApiStudentLearningAnalyticsCtpList.get(params)

    commit('setSubjects', data)
  },

  async fetchMarksCount({ commit }, params) {
    const { data } = await ApiStudentLearningAnalyticsMarksCount.get(params)

    commit('setSubjectsWithMarks', data)
  },

  async fetchDiscipline({ commit }, params) {
    const { data } = await ApiStudentLearningAnalyticsDiscipline.get(params)

    commit('setDisciplineList', data)
  },

  async fetchAverageMarks({ commit }, params) {
    const { data } = await ApiStudentLearningAnalyticsAverageMarks.get(params)

    commit('setSubjectsWithAverageMarks', data)
  },

  async fetchTestingAttempts({ commit }, params) {
    const { data } = await ApiStudentLearningAnalyticsAttempts.get(params)

    commit('setTestingAttempts', data)
  },

  async fetchTestingDynamics({ commit }, params) {
    const { data } =
      await ApiStudentLearningAnalyticsTestingDynamics.get(params)

    commit('setTestingDynamics', data)
  },

  async fetchVisitingAggregated({ commit }, params) {
    const { data } =
      await ApiStudentLearningAnalyticsVisitingAggregated.get(params)

    commit('setPlatformVisit', data.platformVisit)
    commit('setCtpsData', data.ctpsData)
    commit('setSpentTimeOnPlatform', data.spentTimeOnPlatform)
    commit('setMaterialViews', data.materialViews)
  },

  async fetchScienceAverageMarks({ commit }, params) {
    const { data } =
      await ApiStudentLearningAnalyticsScienceAverageMarks.get(params)

    commit('setScienceAverageMarks', data)
  },
}
