<template>
  <div>
    <UiKitFormField class="mb-4" :label="t('label')" required>
      <UiKitSelect
        v-model="tags"
        :create-option="(name) => ({ name })"
        :placeholder="t('placeholder')"
        multiple
        no-drop
        taggable
      />

      <UiKitFormFieldErrors :errors="v$.localItem.tags.$errors" />
    </UiKitFormField>
  </div>
</template>

<script>
import { required } from '@/helpers/i18n-validators'
import UiKitFormField from '@/ui/UiKitFormField.vue'
import UiKitFormFieldErrors from '@/ui/UiKitFormFieldErrors.vue'
import UiKitSelect from '@/ui/UiKitSelect.vue'
import { useVuelidate } from '@vuelidate/core'
import { cloneDeep } from 'lodash'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductContentItemTags',

  components: {
    UiKitFormFieldErrors,
    UiKitFormField,
    UiKitSelect,
  },

  model: {
    prop: 'item',
    event: 'change',
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  validations() {
    return {
      localItem: {
        tags: {
          required,
        },
      },
    }
  },

  data() {
    return {
      localItem: null,
    }
  },

  computed: {
    tags: {
      get() {
        return this.localItem.tags
      },

      set(value) {
        this.localItem.tags = value

        this.emitChange()
      },
    },
  },

  watch: {
    item: {
      handler() {
        this.localItem = this.item
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    emitChange() {
      this.$emit('change', cloneDeep(this.localItem))
    },

    /**
     * @param {string} name
     * @returns {string}
     */
    t(name) {
      return this.$t(`components.product_management.content.tags.${name}`)
    },
  },
})
</script>
