import ApiBase from 'ApiRest/ApiBase'

export default new (class Configuration extends ApiBase {
  /**
   * @param {number} schoolId
   * @returns {Promise}
   */
  get(schoolId) {
    return this._GET(`/diagnostics/configuration/${schoolId}`)
  }

  patch(schoolId, data) {
    return this._PATCH(`/diagnostics/configuration/${schoolId}`, data)
  }
})()
