import { ApiLibraryAdminItems } from 'ApiRest/Api/User/LibraryItems'

export default {
  /**
   * @param {Object|undefined} args
   * @param {Function} args.commit
   * @param {Object} payload
   * @param {number} payload.studentId
   * @param {Object} payload.params
   * @returns {Promise}
   */
  async fetchLibraryList({ commit }, { studentId, params = {} }) {
    const { data, headers } = await ApiLibraryAdminItems.get({
      studentId,
      params,
    })

    commit('setFilters', params)
    commit('setLibraryList', data)
    commit('setPagination', headers)
  },
}
