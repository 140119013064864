import Constants from 'Constants'

export default {
  bannersSingle(state) {
    return state.block.banners.filter(
      (banner) => banner.format.id === Constants.navigationBannerFormat.SINGLE,
    )
  },

  bannersDouble(state) {
    return state.block.banners.filter(
      (banner) => banner.format.id === Constants.navigationBannerFormat.DOUBLE,
    )
  },
}
