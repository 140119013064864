import ApiBase from 'ApiRest/ApiBase'

export const ApiOrder = new (class extends ApiBase {
  /**
   * Получение данных об определённом заказе
   * @param {number} id
   * @returns {Promise}
   */
  get(id) {
    return this._GET(`/order/${id}`)
  }

  /**
   * Изменение заказа
   * @param {number} id
   * @param {Object} data
   * @returns {Promise}
   */
  patch(id, data) {
    return this._PATCH(`/order/${id}`, data)
  }
})()
