import ApiBase from 'ApiRest/ApiBase'

export const ApiOrderEditableStatusOptions = new (class extends ApiBase {
  /**
   * Список статусов заказа для редактирования
   * @returns {Promise}
   */
  get() {
    return this._GET(`/order/option/editable-statuses`)
  }
})()
