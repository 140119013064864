import { RESET_STATE } from '@/helpers/ResetState'
import { set } from 'lodash'
import Vue from 'vue'
import createState from './state'

export default {
  resetState(state) {
    RESET_STATE(state, createState)
  },

  setBlock(state, payload) {
    state.block = payload
  },

  setBlockValue(state, { path, value }) {
    set(state.block, path, value)
  },

  setBlockBannerValue(state, { id, key, value }) {
    const index = state.block.banners.findIndex((banner) => banner.id === id)

    Vue.set(state.block.banners[index], key, value)
  },

  removeBlockBanner(state, id) {
    state.block.banners = state.block.banners.filter(
      (banner) => banner.id !== id,
    )
  },

  setOptionsList(state, { path, value }) {
    set(state.options, path, value)
  },

  addNewBanner(state, payload) {
    state.block.banners.push(payload)
  },
}
