import ApiBase from 'ApiRest/ApiBase'

export const ApiTriggerSendingChanels = new (class extends ApiBase {
  /**
   * @returns {Promise}
   */
  get() {
    return this._GET(`/trigger-sending/option/channels`)
  }
})()
