import ApiBase from 'ApiRest/ApiBase'

export default new (class Restore extends ApiBase {
  /**
   * @param {number} questionId
   * @param {Object} data
   * @returns {Promise}
   */
  put(questionId, data) {
    return this._PUT(`/library/questions/${questionId}/restore`, data)
  }
})()
