import TheStudentDemo from '@/pages/student/TheStudentDemo.vue'

const routes = [
  {
    path: '/students',
    name: 'admin-students',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/Students.vue'),
  },
  {
    path: '/students-demo',
    name: 'admin-students-demo',
    meta: {
      accessControlGroup: 'student-demo',
    },
    component: () => import('@/pages/student/StudentsDemo.vue'),
  },
  {
    path: '/students-educont',
    name: 'admin-students-educont',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentsEducont.vue'),
  },
  {
    path: '/student/create',
    name: 'admin-the-student-create',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/TheStudent.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student/:studentId(\\d+)',
    component: () => import('@/pages/student/TheStudent.vue'),
    children: [
      {
        path: 'statement/:groupId(\\d+)',
        name: 'admin-student-the-statement',
        meta: {
          accessControlGroup: 'student',
        },
        component: () => import('@/pages/student/StudentTheStatement.vue'),
      },
      {
        path: '',
        name: 'admin-the-student',
        meta: {
          accessControlGroup: 'student',
        },
        component: () => import('@/pages/student/StudentStatements.vue'),
      },
    ],
  },
  {
    path: '/student-demo-create',
    name: 'admin-the-student-demo-create',
    meta: {
      accessControlGroup: 'student-demo',
    },
    component: TheStudentDemo,
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student-demo/:studentDemoId(\\d+)',
    name: 'admin-the-student-demo',
    meta: {
      accessControlGroup: 'student-demo',
    },
    component: TheStudentDemo,
  },
  {
    path: '/student-educont/:studentEducontId(\\d+)',
    name: 'admin-the-student-educont',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/TheStudentEducont.vue'),
  },
  {
    path: '/student/group-distribution',
    name: 'admin-student-group-distribution',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentGroupDistribution.vue'),
  },
  {
    path: '/student/transfer',
    name: 'admin-student-transfer',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentTransfer.vue'),
  },
  {
    path: '/student/groups',
    name: 'admin-student-groups',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentGroups.vue'),
  },
  {
    path: '/student/group/create',
    name: 'admin-student-the-group-create',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentTheGroup.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student/group/:groupId(\\d+)',
    name: 'admin-student-the-group',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentTheGroup.vue'),
  },
  {
    path: '/student/streams',
    name: 'admin-student-streams',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentStreams.vue'),
  },
  {
    path: '/student/stream/create',
    name: 'admin-student-the-stream-create',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentTheStream.vue'),
    props: {
      isCreate: true,
    },
  },
  {
    path: '/student/stream/:streamId(\\d+)',
    name: 'admin-student-the-stream',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentTheStream.vue'),
  },
  {
    path: '/students/trash',
    name: 'admin-students-trash',
    meta: {
      accessControlGroup: 'student',
    },
    component: () => import('@/pages/student/StudentsTrash.vue'),
  },
]

export default routes
