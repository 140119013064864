export const supportRoutes = [
  {
    path: '/support',
    name: 'admin-support',
    meta: {
      accessControlGroup: 'admin-support-questions',
    },
    component: () => import('@/pages/support/Support.vue'),
    children: [
      {
        path: 'questions',
        name: 'admin-support-questions',
        meta: {
          accessControlGroup: 'admin-support-questions',
        },
        component: () => import('@/pages/support/Questions.vue'),
      },
      {
        path: 'questions/:questionId(\\d+)',
        name: 'admin-support-the-question',
        meta: {
          accessControlGroup: 'admin-support-questions',
        },
        component: () => import('@/pages/support/TheQuestion.vue'),
      },
      {
        path: 'questions/create',
        name: 'admin-support-the-question-create',
        meta: {
          accessControlGroup: 'admin-support-questions',
        },
        component: () => import('@/pages/support/TheQuestionCreate.vue'),
      },

      {
        path: 'ideas',
        name: 'admin-support-ideas',
        component: () => import('@/pages/support/Ideas.vue'),
      },
      {
        path: 'ideas/:ideaId(\\d+)',
        name: 'admin-support-the-idea',
        component: () => import('@/pages/support/TheIdea.vue'),
      },

      {
        path: 'faq',
        name: 'admin-support-faq',
        component: () => import('@/pages/support/FAQ.vue'),
      },
      {
        path: 'faq/topic/create',
        name: 'admin-support-the-faq-topic-create',
        component: () => import('@/pages/support/TheFAQTopic.vue'),
        props: {
          isCreate: true,
        },
      },
      {
        path: 'faq/topic/:topicId(\\d+)',
        name: 'admin-support-the-faq-topic',
        component: () => import('@/pages/support/TheFAQTopic.vue'),
      },
      {
        path: 'faq/topic/:topicId(\\d+)/item/create',
        name: 'admin-support-the-faq-question-create',
        component: () => import('@/pages/support/TheFAQQuestion.vue'),
        props: {
          isCreate: true,
        },
      },
      {
        path: 'faq/topic/:topicId(\\d+)/item/:itemId(\\d+)',
        name: 'admin-support-the-faq-question',
        component: () => import('@/pages/support/TheFAQQuestion.vue'),
      },

      {
        path: 'settings',
        name: 'admin-support-question-settings',
        component: () => import('@/pages/support/QuestionSettings.vue'),
      },
    ],
  },
]
