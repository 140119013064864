import { ApiOnlineSchoolProductRequests } from 'ApiRest/Api/OnlineSchool/ProductRequests/Requests'

export default {
  async fetchProductRequests({ commit }, filters = {}) {
    const { data, headers } = await ApiOnlineSchoolProductRequests.get(filters)

    commit('setFilters', filters)
    commit('setRequests', data)
    commit('setPagination', headers)
  },

  removeRequests(context, requests) {
    return ApiOnlineSchoolProductRequests.put(requests)
  },
}
