import ApiBase from 'ApiRest/ApiBase'

export const ApiAuthAny = new (class extends ApiBase {
  /**
   * @returns {Promise<Object>}
   */
  post() {
    const url = `/auth/any/7538/init`

    return this._POST(url)
  }
})()
