import ApiOption from 'ApiRest/Api/Option'
import { ApiClientsExport } from 'ApiRest/Api/User/Client/Export'
import { ApiClientsInvoices } from 'ApiRest/Api/User/Client/Invoices'
import { ApiClientsOrders } from 'ApiRest/Api/User/Client/Orders'

export default {
  async fetchOrders({ state, commit }) {
    const { data } = await ApiClientsOrders.get(state.clientId)

    commit('setOrders', data)
  },

  async fetchInvoices({ state, commit }) {
    const { data } = await ApiClientsInvoices.get(state.clientId)

    commit('setInvoices', data)
  },

  async fetchSchoolsOptions({ commit }) {
    const { data } = await ApiOption.getSchools()

    commit('setOptionsList', {
      path: 'schools',
      value: data,
    })
  },

  async fetchGradesOptions({ commit }) {
    const { data } = await ApiOption.getGrades()

    commit('setOptionsList', {
      path: 'grades',
      value: data,
    })
  },

  async fetchStreamsOptions(context, parameters = {}) {
    const { data } = await ApiOption.getStreams(parameters)

    return data
  },

  async fetchEducationTypesOptions({ commit }) {
    const { data } = await ApiOption.getEducationTypes()

    commit('setOptionsList', {
      path: 'educationTypes',
      value: data,
    })
  },

  async fetchContractTypesOptions({ commit }) {
    const { data } = await ApiOption.getEnrollmentContractTypes()

    commit('setOptionsList', {
      path: 'contractTypes',
      value: data,
    })
  },

  async fetchContractStatusesOptions({ commit }) {
    const { data } = await ApiOption.getEnrollmentContractStatuses()

    commit('setOptionsList', {
      path: 'contractStatuses',
      value: data,
    })
  },

  async fetchAcademicYearsOptions({ commit }) {
    const { data } = await ApiOption.getAcademicYearPreset()

    commit('setOptionsList', {
      path: 'academicYears',
      value: data,
    })
  },

  async fetchAccountStatusesOptions({ commit }) {
    const { data } = await ApiOption.getStudentAccountStatuses()

    commit('setOptionsList', {
      path: 'accountStatuses',
      value: data,
    })
  },

  async fetchEnrollmentStatusesOptions({ commit }) {
    const { data } = await ApiOption.getStudentEnrollmentStatuses()

    commit('setOptionsList', {
      path: 'enrollmentStatuses',
      value: data,
    })
  },

  fetchClientsExport(context, parameters) {
    return ApiClientsExport.get(parameters)
  },

  async fetchOptions({ dispatch }) {
    await Promise.all([
      dispatch('fetchSchoolsOptions'),
      dispatch('fetchGradesOptions'),
      dispatch('fetchEducationTypesOptions'),
      dispatch('fetchContractTypesOptions'),
      dispatch('fetchContractStatusesOptions'),
      dispatch('fetchAcademicYearsOptions'),
      dispatch('fetchAccountStatusesOptions'),
      dispatch('fetchEnrollmentStatusesOptions'),
    ])
  },
}
