import ApiBase from 'ApiRest/ApiBase'

export const ApiProductManagementLinkAccessSaveSettings =
  new (class extends ApiBase {
    /**
     * Сохранение настроек доступа к продукту по ссылке
     * @param {number} id
     * @param {Object} data
     * @returns {Promise}
     */
    post(id, data) {
      return this._POST(`/product/${id}/link-access/save-setting`, data)
    }
  })()
