<template>
  <div :class="$style.imageEditorContainer">
    <TuiImageEditor
      ref="imageEditor"
      :class="$style.imageEditor"
      :options="options"
    />
  </div>
</template>

<script>
import { ImageEditor as TuiImageEditor } from 'ibls-vue-image-editor'
import 'tui-color-picker/dist/tui-color-picker.css'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImageEditor',

  components: {
    TuiImageEditor,
  },

  props: {
    image: {
      type: Object,
      default: null,
    },

    originalUrl: {
      type: String,
      default: null,
    },
  },

  emits: [
    'save-edited-image',
  ],

  data() {
    return {
      isSending: false,
    }
  },

  computed: {
    options() {
      return {
        includeUI: {
          loadImage: {
            path: this.originalUrl,
            name: this.image.name,
          },

          // TODO: i18n
          locale: {
            // Дополнительное меню
            ZoomIn: 'Увеличить',
            ZoomOut: 'Уменьшить',
            Hand: 'Рука',
            History: 'История',
            Undo: 'Отменить',
            Redo: 'Повторить',
            Reset: 'Сбросить',

            // Основное меню

            // Основное меню - Crop
            Crop: 'Обрезать',
            Custom: 'Произвольно',
            Square: 'Квадрат',

            Rotate: 'Повернуть',

            // Основное меню - Draw
            Draw: 'Рисовать',
            Free: 'Свободно',
            Straight: 'Линия',

            // Основное меню - Фигура
            Icon: 'Фигура',
            Arrow: 'Стрелка 1',
            'Arrow-2': 'Стрелка 2',
            'Arrow-3': 'Стрелка 3',
            'Star-1': 'Звезда 1',
            'Star-2': 'Звезда 2',
            Polygon: 'Многоугольник',
            Location: 'Местоположение',
            Heart: 'Сердце',
            Bubble: 'Облако',

            // Основное меню - Text
            Text: 'Текст',
            Bold: 'Жирный',
            Italic: 'Косой',
            Underline: 'Подчерк',
            Left: 'Слева',
            Center: 'По центру',
            Right: 'Справа',

            'Text size': 'Размер текста',

            // Основное меню - Filter
            Filter: 'Фильтр',
            Brightness: 'Яркость',

            // Основное меню - Общее
            Download: 'Сохранить',
            Apply: 'Применить',
            Cancel: 'Отменить',
            Range: 'Размер',
            Color: 'Цвет',
          },

          menuBarPosition: 'bottom',
          draw: {
            color: '#ff4040',
            opacity: 1.0,
            range: {
              value: 8,
            },
          },

          menu: [
            'crop',
            'rotate',
            'draw',
            'icon',
            'text',
            'filter',
          ],

          uiSize: {
            width: '100%',
            height: '100%',
          },
        },

        cssMaxWidth: Math.ceil(0.75 * document.body.clientWidth),
        cssMaxHeight: Math.ceil(0.5 * document.body.clientHeight),
      }
    },
  },

  mounted() {
    this.addBtnSave()
    this.eventClickBtnSave()
    this.setBrightness()
  },

  methods: {
    addBtnSave() {
      document.querySelector('.tui-image-editor-header-buttons').innerHTML =
        '<button class="tui-image-editor-save-btn">Сохранить</button>'
    },

    setBrightness() {
      document.querySelector('.tie-brightness').checked = true
    },

    eventClickBtnSave() {
      document
        .querySelector('.tui-image-editor-save-btn')
        .addEventListener('click', () => {
          if (!this.isSending) {
            this.isSending = true
            const dataUrl = this.$refs.imageEditor.invoke('toDataURL')

            fetch(dataUrl)
              .then((res) => res.blob())
              .then((blob) => {
                const file = new File(
                  [
                    blob,
                  ],
                  this.image.name,
                  {
                    type: this.image.mime,
                  },
                )

                this.$emit('save-edited-image', file)
              })
              .finally(() => {
                this.isSending = false
              })
          }
        })
    },
  },
})
</script>

<style lang="scss" module>
.imageEditorContainer {
  width: 100%;
  height: 100%;
}

.imageEditor {
  [class~='tui-image-editor-help-menu'] {
    display: flex;

    &[class~='top'] {
      width: unset;
    }

    & > li {
      &:nth-of-type(9),
      &:nth-of-type(10),
      &:nth-of-type(11) {
        display: none;
      }
    }

    [class~='tui-image-editor-item'] {
      padding: 8px !important;

      &:not([class~='enabled']) {
        pointer-events: none;
      }
    }
  }

  [class~='tui-image-editor-button'] {
    &[class~='apply'],
    &[class~='cancel'] {
      display: inline-flex !important;
      align-items: center !important;

      & > label {
        cursor: pointer !important;
      }
    }

    &[class~='preset-3-2'],
    &[class~='preset-4-3'],
    &[class~='preset-5-4'],
    &[class~='preset-7-5'],
    &[class~='preset-16-9'] {
      display: none !important;
    }
  }

  [class~='tui-image-editor-header-logo'] {
    display: none !important;
  }

  [class~='tui-image-editor-header-buttons'] {
    [class~='tui-image-editor-save-btn'] {
      background-color: #fdba3b;
      border: 1px solid #fdba3b;
      color: #fff;
      font-family: 'Noto Sans', sans-serif;
      font-size: 12px;
    }
  }

  [class~='tui-image-editor-menu-rotate'] {
    [class~='tui-image-editor-range-wrap'] {
      display: none !important;
    }
  }

  [class~='tui-image-editor-menu-icon'] {
    [class~='tui-image-editor-submenu-item'] {
      & > li {
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none !important;
        }
      }
    }

    [class~='tie-icon-add-button'],
    [class~='tui-image-editor-partition'] {
      &:nth-of-type(3),
      &:nth-of-type(4) {
        display: none !important;
      }
    }
  }

  [class~='tui-image-editor-menu-filter'] {
    [class~='tui-image-editor-submenu-item'] {
      & > li {
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7) {
          display: none;
        }

        &:nth-of-type(3) {
          margin-right: 0;

          [class~='tui-image-editor-checkbox-group'] {
            label > span:before {
              display: none;
            }

            &:nth-of-type(1),
            &:nth-of-type(3) {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
