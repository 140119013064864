import ApiBase from 'ApiRest/ApiBase'

export const ApiOnlineLessonSignups = new (class extends ApiBase {
  /**
   * Записавшиеся на урок
   * @param {number} onlineLessonId
   * @returns {Promise}
   */
  get(onlineLessonId) {
    return this._GET(`/online-lessons/${onlineLessonId}/signups`)
  }
})()
