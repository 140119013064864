<template>
  <VirtualStyle>
    {{ colorsDark }}

    {{ colorsLight }}
  </VirtualStyle>
</template>

<script>
import VirtualStyle from '@/components/VirtualStyle.vue'
import colorsDark from '@/themes/colorsDark'
import colorsLight from '@/themes/colorsLight'
import { colorToHsl, printCss } from '@/themes/tools'
import { map } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'Theme',

  components: {
    VirtualStyle,
  },

  computed: {
    ...mapState('admin/theme', [
      'variants',
    ]),

    colorsDark() {
      return this.getColors(this.variants.dark)
    },

    colorsLight() {
      return this.getColors(this.variants.light)
    },
  },

  methods: {
    /**
     * @param {string} className
     * @returns {string}
     */
    getColors(className) {
      let colors

      switch (className) {
        case this.variants.dark:
          colors = colorsDark
          break

        case this.variants.light:
          colors = colorsLight
          break

        default:
      }

      let styles = `:root.${className} {`

      if (colors) {
        styles += `
          ${map(
            colors,
            printCss('', (color) => {
              const hsl = colorToHsl(color)

              return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
            }),
          ).join(' ')}
          ${map(
            colors,
            printCss('h', (color) => {
              const hsl = colorToHsl(color)

              return hsl[0]
            }),
          ).join(' ')}
          ${map(
            colors,
            printCss('s', (color) => {
              const hsl = colorToHsl(color)

              return `${hsl[1]}%`
            }),
          ).join(' ')}
          ${map(
            colors,
            printCss('l', (color) => {
              const hsl = colorToHsl(color)

              return `${hsl[2]}%`
            }),
          ).join(' ')}
        `
      }

      styles += `}`

      return styles
    },
  },
}
</script>
