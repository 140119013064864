import ApiIncomeDiagnostics from 'ApiRest/Api/Enrollment/Request'
import { ApiAssignIncomeDiagnostics } from 'ApiRest/Api/Enrollment/Request/IncomeDiagnostics'
import { ApiAssingDiagnosticsExtramural } from 'ApiRest/Api/IncomeDiagnostics/AssignExtramuralDiagnostics'
import { ApiDiagnosticsExtramural } from 'ApiRest/Api/IncomeDiagnostics/ExtramuralDiagnostics'
import { ApiDiagnosticsFullTime } from 'ApiRest/Api/IncomeDiagnostics/FullTimeDiagnostics'
import ApiOption from 'ApiRest/Api/Option'

export default {
  async fetchServices({ commit }) {
    const { data } = await ApiOption.getInvoiceServices()

    commit('setServices', data)
  },

  async fetchExtramuralDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsExtramural.get(
      state.request.school.id,
      state.request.grade.id,
    )

    commit('setSubjectsSettings', {
      key: 'extramuralDiagnostics',
      settings: data,
    })
  },

  async fetchFullTimeDiagnostics({ commit, state }) {
    const { data } = await ApiDiagnosticsFullTime.get(
      state.request.school.id,
      state.request.grade.id,
    )

    commit('setSubjectsSettings', {
      key: 'fullTimeDiagnostics',
      settings: data,
    })
  },

  async saveIncomeDiagnostics({ commit, state, getters }) {
    const { data } = await ApiAssignIncomeDiagnostics.post(
      state.request.id,
      getters.incomeDiagnosticsSettings,
    )

    commit('setRequest', data)
  },

  async fetchRequest({ commit, state, dispatch }) {
    const expandParams = {
      expand: 'canAssignIntramuralDiagnostics, canAssignExtramuralDiagnostics',
    }

    const { data } = await ApiIncomeDiagnostics.get(
      state.requestId,
      expandParams,
    )

    commit('setRequest', data)
    await dispatch('fetchDiagnosticsSettings')
  },

  async fetchDiagnosticsSettings({ dispatch }) {
    await Promise.all([
      // TODO: redmine.ibls.tech/issues/6129 скрыт блок очной диагностики
      // dispatch('fetchFullTimeDiagnostics'),
      dispatch('fetchExtramuralDiagnostics'),
    ])
  },

  assignExtramuralDiagnostics({ getters }, applicationId) {
    return ApiAssingDiagnosticsExtramural.post(
      applicationId,
      getters.incomeDiagnosticsSettings.extramuralDiagnostics,
    )
  },
}
