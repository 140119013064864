<template>
  <div
    class="uiKit-modal modal"
    :class="{ show: !localHidden }"
    @click.capture="processClick"
  >
    <div :class="size" class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="header"></slot>
          </h5>

          <button v-if="showCloseBtn" class="close" @click="hide">
            &times;
          </button>
        </div>

        <div class="modal-body">
          <slot name="body"></slot>
        </div>

        <div v-if="$slots.footer" class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>

    <div :class="{ show: !localHidden }" class="fade modal-backdrop"></div>
  </div>
</template>

<script>
const $body = document.querySelector('body')

export default {
  name: 'UiKitModal',

  props: {
    /**
     * Show or hide modal on mount
     */
    hidden: {
      type: Boolean,
      default: true,
    },

    hideOnBodyClick: {
      type: Boolean,
      default: true,
    },

    showCloseBtn: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      localHidden: this.hidden,
    }
  },

  methods: {
    /**
     * Show modal
     * @public
     */
    show() {
      if ($body) {
        $body.classList.add('modal-open')
        $body.style.paddingRight = '15px'
      }

      this.localHidden = false
      this.$emit('show')
    },

    /**
     * Hide modal
     */
    hide() {
      if ($body) {
        $body.classList.remove('modal-open')
        $body.style.paddingRight = ''
      }

      this.localHidden = true
      this.$emit('hide')
    },

    /**
     * Toggle modal window visibility on click anywhere in the page
     * @param {Event} e
     */
    processClick(e) {
      if (this.hideOnBodyClick) {
        const { target } = e
        const $modalDialog = target.closest('.modal-dialog')

        if (!$modalDialog) {
          this.hide()
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@/scss/functions';
@import '~@/scss/bootstrap/variables';

.uiKit-modal {
  .modal-content {
    // stylelint-disable color-function-notation, alpha-value-notation
    box-shadow:
      0 1px 1px rgba(0, 0, 0, 0.2),
      0 2px 2px rgba(0, 0, 0, 0.2),
      0 4px 4px rgba(0, 0, 0, 0.2),
      0 8px 8px rgba(0, 0, 0, 0.2),
      0 16px 16px rgba(0, 0, 0, 0.2);
    // stylelint-enable color-function-notation, alpha-value-notation
  }

  .modal-footer {
    padding-top: 0;

    > * {
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }

  &.show {
    display: block;
    padding-right: 15px;
    overflow: hidden auto;
  }

  .close {
    outline: none;
  }
}
</style>
