const routes = [
  {
    path: '/enrollment-contracts',
    name: 'admin-enrollment-contracts',
    meta: {
      accessControlGroup: 'enrollment-contract',
    },
    component: () => import('@/pages/contract/Contracts.vue'),
  },

  {
    path: '/enrollment-contract/detail/:enrollmentContractId(\\d+)',
    name: 'admin-the-enrollment-contract',
    meta: {
      accessControlGroup: 'enrollment-contract',
    },
    component: () => import('@/pages/contract/TheContract.vue'),
  },
]

export default routes
