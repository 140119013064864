import { composeRouteGroups, conditions } from '@/accessControl/helpers'
import { setAbilityByModuleAnalyticsOnlineLesson } from '@/accessControl/modules/analytics/onlineLesson'
import { setAbilityByModuleChat } from '@/accessControl/modules/chat'
import { setPermissionByModuleCourseManagement } from '@/accessControl/modules/courseManagement'
import { setAbilityByModuleIncomeDiagnostics } from '@/accessControl/modules/incomeDiagnostics'
import { setAbilityByModuleLibrary } from '@/accessControl/modules/library'
import { setAbilityByModuleOnlineLesson } from '@/accessControl/modules/onlineLesson'
import { setAbilityByModuleSchoolLibrary } from '@/accessControl/modules/schoolLibrary'
import { setPermissionByModuleSupport } from '@/accessControl/modules/support'
import { setAbilityByExtraData } from '@/accessControl/setAbilityByExtraData'
import { setAbilityByMenuPermission } from '@/accessControl/setAbilityByMenuPermission'
import { setAbilityByPermissionForRoutesWithoutGroup } from '@/accessControl/setAbilityByPermissionForRoutesWithoutGroup'
import { setAbilityByPermissionPerRouteGroup } from '@/accessControl/setAbilityByPermissionPerRouteGroup'

const routes = composeRouteGroups()

/**
 * Конвертация пермишена в CASL-правило или набор правил
 * @param {Object} data
 * @param {Object[]} data.activeModules - Массив модулей, доступных для юзера
 * @param {string} data.permission - Пермишен, который проверяем
 * @param {Object} data.extraData - Любые доп. данные для более сложных условий
 * @param {Object} utils - Вспомогательные методы CASL
 * @param {Function} utils.can
 * @param {Function} utils.cannot
 * @returns {boolean}
 */
export const permissionToRules = (
  { activeModules, extraData, permission },
  { can, cannot },
) => {
  const helpers = conditions({
    can,
    cannot,
  })

  if (
    setAbilityByModuleAnalyticsOnlineLesson({
      activeModules,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setAbilityByPermissionPerRouteGroup({
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setAbilityByPermissionForRoutesWithoutGroup({
      helpers,
      permission,
    })
  ) {
    return true
  }

  if (
    setAbilityByMenuPermission({
      extraData,
      helpers,
      permission,
    })
  ) {
    return true
  }

  if (
    setPermissionByModuleSupport({
      activeModules,
      extraData,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setPermissionByModuleCourseManagement({
      activeModules,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setAbilityByModuleOnlineLesson({
      activeModules,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setAbilityByModuleIncomeDiagnostics({
      activeModules,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setAbilityByModuleLibrary({
      activeModules,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  if (
    setAbilityByModuleChat({
      activeModules,
      helpers,
    })
  ) {
    return true
  }

  if (
    setAbilityByModuleSchoolLibrary({
      activeModules,
      extraData,
      helpers,
      permission,
      routes,
    })
  ) {
    return true
  }

  return true
}

/**
 * Задание CASL-правил по произвольному набору "дополнительных данных"
 * @param {Object} data
 * @param {Object} data.extraData
 * @param {Array} data.permissions
 * @param {Object} utils
 * @param {Function} utils.can
 * @param {Function} utils.cannot
 */
export const extraDataToRules = (
  { extraData, permissions },
  { can, cannot },
) => {
  setAbilityByExtraData(
    {
      extraData,
      permissions,
    },
    {
      can,
      cannot,
    },
  )
}
