export default () => ({
  option: {
    schools: [],
    educationTypes: [],
    sourceGrades: [],
    targetGrades: [],
    sourceStreams: [],
    targetStreams: [],
  },
  source: {
    school: null,
    educationType: null,
    grade: null,
    stream: null,
    studentsCount: null,
    unblock: false,
  },
  target: {
    educationType: null,
    grade: null,
    stream: null,
    studentsCount: null,
    newStudentsCount: null,
  },
})
