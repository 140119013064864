export default () => ({
  promocodeGroups: [],
  discountTypes: [],
  promocodeGroupTypes: [],
  promocodeGroupId: null,
  promocodeGroup: {
    id: null,
    name: '',
    bestBefore: '',
    effectiveDate: '',
    initialGeneratedCount: null,
    discount: null,
    newPromocodesCount: null,
    usedPromocodesCount: null,
    expiredPromocodesCount: null,
    school: null,
    partner: null,
    promocodeGroupType: null,
    discountType: null,
    invoices: [],
    promocodes: [],
    code: null,
    applicableServices: [],
    isApplicableToAnyService: false,
    hideOffer: false,
  },
  schools: [],
  isPromocodeExists: false,
  services: [],
  partners: [],
})
