import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportFaqTreeSort = new (class extends ApiBase {
  /**
   * @param {Object} payload
   * @param {Object} parameters
   * @returns {Promise}
   */
  post(payload, parameters) {
    const url = `/support/faq/sort?${UrlHelper.param(parameters)}`

    return this._POST(url, payload)
  }
})()
