import UrlHelper from '@/helpers/UrlHelper'
import ApiBase from 'ApiRest/ApiBase'

export const ApiSupportOptionList = new (class extends ApiBase {
  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  getStatuses(parameters) {
    const url = `/support/questions/options/statuses?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  getDepartments(parameters) {
    const url = `/support/questions/options/departments?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  getTypes(parameters) {
    const url = `/support/questions/options/types?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }

  /**
   * @param {Object} parameters
   * @returns {Promise}
   */
  getPriorities(parameters) {
    const url = `/support/questions/options/priorities?${UrlHelper.param(
      parameters,
    )}`

    return this._GET(url)
  }
})()
