const createSeo = () => ({
  prettyLink: '',
  title: '',
  description: '',
  keywords: '',
  alt: '',
})

export default () => ({
  options: {
    categoryOptionList: [],
    customerRelationshipTypeList: [],
    gradeOptionList: [],
    kindOptionList: [],
    leaderOptionList: [],
    schoolOptionList: [],
    parameterOptionList: [],
    materialTypeOptionList: [],
    ctpList: [],
    onlineLessonList: [],
    agreementOptionList: [],
    statuses: [],
    streamOptionList: [],
    promocodeList: [],
    educationTypeOptionList: [],
    ctpProductList: [],
    onlineLessonProductList: [],
    subscriptionList: [],
    groupList: [],
    productList: [],
    managers: [],
  },

  visibility: {
    schoolsOptions: [],
  },

  offers: {
    parametersSelected: [],
  },

  product: {
    agreements: [],
    author: '',
    availableViaLink: false,
    customerRelationshipType: null,
    description: '',
    documents: [],
    documentTemplates: [],
    extraCategories: [],
    holding: {
      dateStart: null,
      timeStart: null,
      dateEnd: null,
      timeEnd: null,
      location: '',
      displayOnlyMonth: false,
    },
    id: null,
    images: [],
    isActive: false,
    isDocumentsUploadRequired: false,
    collectChildData: false,
    isExtracurricular: false,
    canBePaidWithMaternityCapital: false,
    leaders: [],
    limitByVacantSeats: false,
    mainCategory: null,
    materials: [],
    name: '',
    subtitle: '',
    promoLabel: '',
    offers: [],
    program: [],
    recommendedGrades: [],
    requiredDocumentsList: null,
    seatsCountType: null,
    seats: null,
    group: null,
    status: null,
    vacantSeats: null,
    seo: createSeo(),
    contents: [],
    template: null,
    type: null,
    visibility: {
      isPublic: false,
      schools: [],
    },
    users: [],
    promoCampaigns: [],
    zIndex: null,
    responsibleManagers: [],
  },

  productList: [],
  pagination: null,
  filters: null,

  // Вкладка "Сертификат"

  certificateUserList: [],

  // Ссылка на продукт

  accessCode: {
    key: '',
  },
})
