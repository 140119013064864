import { ApiSupportQuestionAssignedCount } from 'ApiRest/Api/Support/Question/AssignedCount'
import { ApiSupportQuestionInWorkCount } from 'ApiRest/Api/Support/Question/InWorkCount'
import { ApiSupportQuestionNewCount } from 'ApiRest/Api/Support/Question/NewCount'

export default {
  async fetchQuestionsInWorkCount({ commit }) {
    const { data } = await ApiSupportQuestionInWorkCount.get()

    commit('setQuestionsCounter', {
      path: 'inWorkCount',
      value: data,
    })
  },

  async fetchQuestionsNewCount({ commit }) {
    const { data } = await ApiSupportQuestionNewCount.get()

    commit('setQuestionsCounter', {
      path: 'newCount',
      value: data,
    })
  },

  async fetchQuestionsAssignedCount({ commit }) {
    const { data } = await ApiSupportQuestionAssignedCount.get()

    commit('setQuestionsCounter', {
      path: 'assignedCount',
      value: data,
    })
  },
}
